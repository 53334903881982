import React from 'react';
import './App.css';
import Router from "./router/Router";
import {RecoilRoot} from "recoil";
import '../src/locale/i18n';


function App() {
  return (
      <React.Fragment>
        <RecoilRoot>
          <Router/>
        </RecoilRoot>
      </React.Fragment>
  );
}

export default App;
