import React from 'react';
import styled from "styled-components";
import Buttons from "../buttons/Buttons";
import {useMediaQuery} from "react-responsive";
import {ContainerProps} from "../../types/Fundamental";
import {useTranslation} from "react-i18next";

interface ProductSliderProps{
    productType?:string
    title?:string
    sub?:string
    image?:string
    compType?:string
    clickEvent?:()=>void
    activeIndex?:number
    selected?:boolean

}

const ProductSlideComp:React.FC<ProductSliderProps> = ({productType,title,sub,image,compType,clickEvent,activeIndex,selected
}) => {

    const {t}:any =useTranslation();
    const selectLanguage = localStorage.getItem('i18nextLng');

    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });


    const isMobile = useMediaQuery({
        query: "(max-width: 1024px)",
    });

    const nameTranslate = (name:any) => {
        if (name === 'misfeeder') {
            return '미스피더'
        } else if (name === '1ch') {
            return '하사점 검출장치\n1채널'
        } else if (name === '2ch') {
            return '하사점 검출장치\n2채널'
        } else if (name === 'intergration') {
            return '통합 하사점\n검출장치'
        } else if (name ==='pressure') {
            return '압전압식\n로드모니터(별치형)'
        } else if (name ==='safety') {
            return 'AI\n안전 솔루션'
        } else {
            return name
        }
    }


    const renderDesktop = () => (

        <Container onClick={clickEvent}>
            <React.Fragment>
                <div className={'text_wrapper'}>
                    <Buttons clickEvent={() => { }}
                             width={'clamp(230px,6vw,272px)'}
                             height={'clamp(32px,6vw,48px)'}
                             text={productType} fontSize={'20px'}/>
                    <div className={'text'}>
                        <div className={'text_title'}
                             style={{ fontSize: 'clamp(24px, 2vw, 32px)'}}>{nameTranslate(t(title))}</div>
                        <div className={'text_sub'}
                             style={{fontSize: 'clamp(18px, 1.2vw, 20px)' }}>{t(sub)}</div>
                    </div>
                    <div className={'text-sub'} style={{ fontSize: '18px', color: '#006DFF', marginTop: '40px'}}>
                        {t('더보기 >')}
                    </div>
                </div>
                <div className={'image-wrapper'} >
                    <img src={image} alt="product"/>
                </div>
            </React.Fragment>
        </Container>
    );

    const renderMobile = () => (
        <Container onClick={clickEvent}>
        <div style={{ display: 'flex', flexDirection:'column',position:'relative',border:selected?'1px solid #006DFF':'1px solid #1E222D',borderRadius:'24px',boxSizing:'border-box'}}>
            <div className={'m-image-wrapper'}>
                <img src={image} alt="product" style={{
                    width: '296px', height: '176px',
                }} />
            </div>
            <div style={{ position: 'absolute', top: '220px',left:'0px' ,zIndex:1}}>
                <Buttons clickEvent={() => { }} width={'140px'} height={'48px'}
                         fontSize={selectLanguage === 'ko' ? '16px' : '12px'}
                         text={productType} />
            </div>
            <div className={'m-text_wrapper'}>
                <div className={'text'}>
                    <div className={'text_title'} style={{ fontSize: '24px' }}>{nameTranslate(t(title))}</div>
                    <div className={'text_sub'} style={{ fontSize: '14px' }}>{t(sub)}</div>
                </div>
                <div className={'text-sub'} style={{
                    fontSize: '14px', color: '#006DFF',
                    marginBottom: '20px',
                    textAlign: 'right',
                }}>
                    {t('더보기 >')}
                </div>
            </div>
        </div>
        </Container>
    );

    return (
          <>
               {compType === 'desktop' ? renderDesktop() : renderMobile()}
            </>
        );
    }


export default ProductSlideComp;

const Container = styled.div<ContainerProps>`
  display: flex;
  z-index: 0;
  align-items: center;
  justify-content: center;
  min-height: ${props => props.height};
  box-sizing: border-box;
  border-radius: 24px;
  background-color: transparent;
  cursor: pointer;

    //margin-bottom: 30px;

    width: 1024px;

    //@media only screen and (max-width: 1440px) {
    //    width: 750px;
    //    height: 100%;
    //}




  .text_wrapper {
    display: flex;
    text-align: left;
    align-items: baseline;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 30px 80px 30px;
    width:  400px;
    height: 544px;
    box-sizing: border-box;
    background-color: #1E222D;
    border-radius: 24px 0 0 24px;



    //
    //@media only screen and (max-width: 1024px) {
    //  width: calc(60% - 624px);
    //  height: auto;
    //}
    //
    //@media only screen and (max-width: 768px) {
    //  width: calc(60% - 264px);
    //  height: auto;
    //}
  }

  .m-text_wrapper {
    display: flex;
    padding: 0 24px;
    flex-direction: column;
    box-sizing: border-box;
    width: 264px;
    height: 176px;
    background-color: #1E222D;
    border-radius: 0 0 24px 24px;
   justify-content: space-between;
  }

  .text {
    color: #ffffff;

    &_title {
      font-weight: 800;
      margin-bottom: 14px;
      margin-top: 50px;
      //width: 220px;
      white-space: break-spaces;
      text-overflow: ellipsis;
      overflow: hidden;



    }

    &_sub {
      font-size: 20px;
      white-space: pre-line;
    }
  }

  .image-wrapper {
    display: flex;
    width: 624px;
    height: 544px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: #EDF5FF;
    padding:0 40px;
    border-radius: 0 24px 24px 0;
    //transition: all 0.5s ease-in-out;

    img {
      transition: all 0.5s ease-in-out;
        width: 525px;
        height:421px;
        max-width: 100%;
        max-height: 100%;
        min-width: 525px;
        object-fit: contain;
        object-position: center;
        //width: '525px' ,height: '421px',
        //maxWidth: '100%', maxHeight: '100%', minWidth: '525px',
        //objectFit: 'contain', objectPosition: 'center'
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

  }

  .m-image-wrapper {
    display: flex;
    width: 264px;
    height: 240px;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    background: #EDF5FF;
    align-items: center;
    justify-content: center;
    border-radius: 24px 24px 0 0;
    transition: all 0.5s ease-in-out;

    img {
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }


  @media only screen and (max-width: 1440px) {
    width: 750px;
    height: 398px;

    .text_wrapper {
      width: 300px;
        height: 398px;
    }

    .image-wrapper {
      width: 450px;
        height: 398px;

      img {
        width: 280px;
          height: 280px;
        max-width: 70%;
        max-height: 70%;
          object-fit: contain;
          object-position: center;

      }
    }
  }

  //@media only screen and (max-width: 768px) {
  //  width: 264px;
  //
  //  height: auto;
  //}



`
