import React from 'react';
import {ProductType} from "../../../types/Fundamental";
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";


interface Props {
    productList?: ProductType |any;
}
const SLMSSpecSection:React.FC<Props> = ({productList}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1441px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    return (
        <MainContainer
            style={{
                background: '#EDF5FF',
                padding: isDesktop ? '200px 20% 100px 20%' : '80px 5%',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height: isDesktop ? '100%' : isMobile ? '100%' : '100%',
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div style={{
                    display: 'grid', gridTemplateColumns: !isDesktop ? '1fr' : '1fr 1fr',
                    gap: '20px', width: '100%', alignItems: 'baseline'
                }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {productList?.productSpec.map((v: any, index: number) =>
                            <div style={{
                                display: 'flex', gap: isMobile ? '10px' : '20px',
                                flexDirection: !isDesktop ? 'column' : 'row',
                                marginTop: !isDesktop ? '60px' : '120px', width: '100%',
                                alignItems: 'start'
                            }}>
                                <SpecContainer>
                                    {v.specName}
                                </SpecContainer>
                                <div style={{
                                    fontSize: 'clamp(18px, 2.5vw, 20px)',
                                    color: '#1E222D', whiteSpace: isMobile ? 'nowrap' : 'pre-line', lineHeight: '1.8'
                                }}>
                                    {v.value}
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{
                            fontSize: 'clamp(18px, 2.5vw, 24px)',
                            color: '#006DFF', fontWeight: 'bold', marginTop: '100px', marginBottom: '40px'
                        }}>
                            {'규격사양'}
                        </div>
                        {productList?.productOption?.map((v: any, index: number) =>
                            <div style={{
                                display: 'grid', gridTemplateColumns: '1fr auto', width: isMobile ? '95%' : '100%',
                                gap: isMobile ? '8px' : '10px', marginBottom: '20px'
                            }}>
                                <div style={{
                                    fontSize: 'clamp(14px, 2.5vw, 24px)',
                                    color: '#1E222D',
                                    lineHeight: isMobile ? '1' : '1.8'
                                }}>
                                    {v.optionName}
                                </div>
                                <div style={{
                                    fontSize: 'clamp(14px, 2.5vw, 24px)',
                                    color: '#1E222D',
                                    lineHeight: isMobile ? '1' : '1.8',
                                    textAlign: 'right'
                                }}>
                                    {v.value}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </MainContainer>
);
};

export default SLMSSpecSection;

const TitleContainer = styled.div`
display: flex;
box-sizing: border-box;
background-color: #484848;
color: #fff;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 71px;
    max-width: 976px;
    min-width: 200px;
    font-weight: 700;
    font-size: 25px;
    `

const FlowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    max-width: 154px;
    min-width: 154px;
    background-color:#fff;
    border:1px solid #006DFF;
    color: #006DFF;
    font-size:20px;
    z-index: 1;
    `


const SpecContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width:196px;
    max-width: 100%;
    min-width:196px;
    height:44px;
    background-color:#006DFF;
    color : #fff;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;

    @media (max-width: 768px) {
        height: 56px;
        border-radius: 28px;
        width: 312px;
`
