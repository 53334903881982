import React from 'react';
import styled from "styled-components";
import {ContainerProps} from "../types/Fundamental";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import CSchat from "../components/chat/CSchat";
import DownButton from "../components/buttons/DownButton";
import FinancialStatements from "../components/pop/IRModal";


interface Props {
    children: React.ReactNode
}

const SectionContainer: React.FC<Props> = ({children}) => {
    const [modalOpen, setModalOpen] = React.useState(false);


    return (
        <React.Fragment>
            {modalOpen &&
                    <FinancialStatements closeModal={() => setModalOpen(!modalOpen)}/>
                 }
            <Header openModal={() => setModalOpen(!modalOpen)}/>
            {/*<Styles padding={isDesktop?'0 20% 0 20%':'0 11% 0 11%'}>*/}
            <Container>
                {children}
            </Container>
            <CSchat/>
            <Footer/>
            <DownButton/>
        </React.Fragment>
    );
};

export default SectionContainer;


const Container = styled.div<ContainerProps>`

    justify-content: center;
    box-sizing: border-box;
    min-width: 300px;
    padding: ${props => props.padding};
    overflow: hidden;
    background-color: transparent;
    display: flex;


    //
    //&::-webkit-scrollbar {
    //  display: none;
    //}
    //
    //&::-webkit-scrollbar-track {
    //  background: transparent; /* 스크롤바 트랙 배경을 투명으로 설정 */
    //}
    //
    //&::-webkit-scrollbar-thumb {
    //  background: transparent; /* 스크롤바 썸 배경을 투명으로 설정 */
    //}


`
