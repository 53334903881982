import React from 'react';
import {ContentFrame} from "../../styles/Styles";

import styled from "styled-components";
import SolutionDetailHeroSection from "./common/SolutionDetailHeroSection";
import SolutionDetailSubSection from "./common/SolutionDetailSubSection";



interface Props {
    solutionList?: any;
}
const SolutionDetailScrollSection:React.FC<Props> = ({solutionList}) => {
    return (
        <ContentFrame>
            <ScrollSnapContainer>
                <SolutionDetailHeroSection solutionList={solutionList}/>
                <SolutionDetailSubSection solutionList={solutionList}/>


            </ScrollSnapContainer>
        </ContentFrame>
    );
};

export default SolutionDetailScrollSection;


const ScrollSnapContainer = styled.div`
  //scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  //scroll-snap-stop: always;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & > div {
    scroll-snap-align: start;
  }
`;