import React, {useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import {createHeroMainText, createHeroSubText, MainContainer} from "../../../styles/Styles";
import bg from "../../../asset/images/bg/solutionMain.webp";
import Aos from "aos";

const SolutionHeroSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 1023px)",
    }
    );


    const isMobile = useMediaQuery({
        query: "(max-width:430px)"
    });

    useEffect(() => {
            Aos.init( {duration: 1500}
            );
        }
        ,[])

    const backgroundStyle = {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        backgroundSize: 'cover',
        width: '100%',
        height: isMobile ? '100vh' : '1080px',
        backgroundPosition: isDesktop ? 'center' : '66%',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
    };


    return (
        <MainContainer backgroundImage={bg}
                       justifyContent={'center'} alignItems={'flex-start'}
                       height={isMobile?'680px':'100%'}>

            <div className={'picture'} style={backgroundStyle}>
                <div style={{
                    maxWidth: '1920px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div className={'hero-main'} style={{padding: isDesktop ? '0 18%' : '0 6%'}}>
                        {/*<div data-aos={'fade-right'} className={'menu_title'}*/}
                        {/*     style={{fontSize:'18px',color:'#fff',padding:'0',margin:'0 0 40px 0px'}}>*/}
                        {/*    {'스마트 솔루션'}*/}
                        {/*</div>*/}
                        <div data-aos={'fade-down'}>
                            <div className={'hero_text'}
                                 style={{
                                     display: '-webkit-box',
                                     WebkitLineClamp: 2,
                                     WebkitBoxOrient: 'horizontal',
                                     fontSize: 'clamp(32px, 5vw, 64px)',
                                     // maxWidth: isDesktop?'800px':'400px',
                                     fontWeight: 'bold',
                                     overflow: 'visible',
                                     whiteSpace: 'pre-line',
                                     letterSpacing: ' 0.0em',
                                 }}>
                                {isMobile ?
                                    '현장 전문가의 스마트한 실시간 전사적 관리시스템'
                                    :
                                    '현장 전문가의 스마트한\n실시간 전사적 관리시스템'}
                            </div>

                        </div>
                        <div data-aos={'fade-up'}>
                            <div className={'hero_text'}
                                 style={{
                                     marginTop: isDesktop ? '40px' : '20px',
                                     color: '#fff',
                                     fontSize: isDesktop ? '32px' : '16px',
                                 }}>
                                <div style={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'horizontal',
                                    maxWidth: isDesktop ? '820px' : '312px',
                                    maxHeight: isDesktop ? '122px' : '96px',
                                    overflow: 'visible',
                                    whiteSpace: 'pre-line',
                                    lineHeight: 1.3,
                                }}>
                                    {'우리 업체에서 사용하고 있는 설비,주변장치에서부터 스마트공장 고도화를 위한 단계별 업그레이드까지 현장 전문가의 실시간 모니터링으로 관리 받아보세요!'}
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                </div>

        </MainContainer>
);
};

export default SolutionHeroSection;
