import React from 'react';
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";

interface Props {
    productList?: any;

}

const InterGuideSection: React.FC<Props> = ({productList}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1440px)",
    });

    // const isTablet = useMediaQuery({
    //     query: "(max-width: 768px)",
    // });
    //
    // const isMobile = useMediaQuery({
    //     query: "(max-width:440px)"
    // });

    const nameTranslate = (name: any) => {
        if (name === 'intergration') {
            return '통합 검출 장치'
        } else {
            return name
        }
    }

    const cardArr = [
        {
            name: '전원카드',
            notice: '※ 전원카드 단자대 핀 맵',
            detail: [{
                title: '출력신호 단자',
                content: '이상 발생시 활성화되는 출력단자 2CH 작동은 동시에 하지만,각 채널은 전기적으로 분리되어있음'
            }, {title: 'RST', content: 'RST 리셋입력, N24와 연결되면 에러리셋'}, {title: '전원단자', content: 'DC24V/1A 전원입력'}]
        },
        {
            name: '캠입력/통신 카드',
            notice: ['※ 캠입력/통신카드 단자대 핀 맵', '※ COM 단자는 기계 본체 또는\nN24에 연결하십시오',],
            detail:
                [{title: 'RS485', content: 'RS485 통신 핀타입 단자'},
                    {title: '캠 입력 단자', content: 'COM:공통단자\nCAM1~4: 캠입력 1~4'}
                ]
        }
    ]
    const misfeederArr = ['재료말단', '재료휨', '미스피드 CH1', '미스피드 CH2', '미스피드 CH3', '미스피드 CH4']

    const renderDesktop = () => {
        return (
            <MainContainer
                style={{
                    background: '#fff',
                    padding: '200px 16% 100px 16%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    WebkitBoxSizing: 'border-box',
                }}>
                <div style={{
                    maxWidth: '1232px', alignSelf: 'center', margin: isDesktop ? '0 auto' : '0 6%',
                    justifyContent: 'center', width: '100%'
                }}>

                    {/*메인타이틀*/}
                    {productList?.productInstall?.map((v: any, index: number) =>
                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            marginBottom: !isDesktop ? '100px' : '200px'
                        }}>
                            <div style={{
                                display: 'flex', fontSize: 'clamp(18px, 2.5vw, 24px)',
                                color: '#006DFF', fontWeight: '600',
                                marginBottom: !isDesktop ? '30px' : '40px'
                            }}>
                                {v.mainTitle}
                            </div>
                            {/*서브타이틀*/}
                            <div style={{display: 'flex', gap: '120px', width: '100%'}}>
                                {v.detail.map((v: any, index: number) =>
                                    <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                        {v.title === '전원카드' &&
                                            <div style={{
                                                display: 'flex',
                                                minWidth: '600px',
                                                flexDirection: 'column',
                                                gap: '20px'
                                            }}>
                                                <SpecContainer style={{width: '200px'}}>
                                                    {v.title}
                                                </SpecContainer>
                                                <div style={{display: 'flex', width: '100%'}}>
                                                    <img src={v.mImg} alt="" style={{
                                                        width: '100%',
                                                        maxWidth: '296px',
                                                        minWidth: '210px',
                                                        height: '100%',
                                                        objectFit: 'contain'
                                                    }}/>
                                                    <DetailContainer>
                                                        {cardArr[0].detail.map((v: any, index: number) =>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '10px'
                                                            }}>
                                                                <div className={'title'}>
                                                                    {v.title}
                                                                </div>
                                                                <div className={'content'}>
                                                                    {v.content}
                                                                </div>

                                                            </div>
                                                        )}
                                                    </DetailContainer>
                                                </div>
                                                <div style={{
                                                    color: '#006DFF',
                                                    fontWeight: 600,
                                                    fontSize: 'clamp(12px, 1vw, 15px)'
                                                }}>
                                                    {cardArr[0].notice}
                                                </div>
                                            </div>}

                                        {v.title === '캠입력/통신 카드' &&
                                            <div style={{
                                                display: 'flex',
                                                minWidth: '400px',
                                                flexDirection: 'column',
                                                gap: '20px'
                                            }}>
                                                <SpecContainer style={{width: '200px'}}>
                                                    {v.title}
                                                </SpecContainer>
                                                <div style={{display: 'flex', width: '100%'}}>
                                                    <img src={v.img} alt="" style={{
                                                        width: '100%',
                                                        maxWidth: '296px',
                                                        minWidth: '210px',
                                                        height: '100%',
                                                        objectFit: 'contain'
                                                    }}/>
                                                    <DetailContainer>
                                                        {cardArr[1].detail.map((v: any, index: number) =>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: '10px'
                                                            }}>
                                                                <div className={'title'}>
                                                                    {v.title}
                                                                </div>
                                                                <div className={'content'}>
                                                                    {v.content}
                                                                </div>

                                                            </div>
                                                        )}
                                                        <div className={'notice'}>
                                                            {`${cardArr[1].notice[1]}`}
                                                        </div>

                                                    </DetailContainer>
                                                </div>
                                                <div style={{
                                                    color: '#006DFF',
                                                    fontWeight: 600,
                                                    fontSize: 'clamp(12px, 1vw, 15px)'
                                                }}>
                                                    {` ${cardArr[1].notice[0]}`}
                                                </div>
                                            </div>}

                                        {v.title === '미스피드 입력접점 정보' &&
                                            <div style={{
                                                display: 'flex',
                                                minWidth: '600px',
                                                flexDirection: 'column',
                                                gap: '20px'
                                            }}>
                                                <SpecContainer style={{width: '384px'}}>
                                                    {v.title}
                                                </SpecContainer>
                                                <div style={{display: 'flex', width: '100%'}}>
                                                    <img src={v.mImg} alt="" style={{
                                                        width: '100%',
                                                        maxWidth: '504px',
                                                        minWidth: '296px',
                                                        height: '100%',
                                                        objectFit: 'contain'
                                                    }}/>
                                                    <DetailContainer>
                                                        {misfeederArr.map((v: any, index: number) =>
                                                            <div style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: '10px'
                                                            }}>
                                                                <div className={'title'}>
                                                                    {`${index + 1}`}
                                                                </div>
                                                                <div className={'content'} style={{color: '#707070'}}>
                                                                    {v}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </DetailContainer>
                                                </div>
                                            </div>}
                                        {v.title === '미스피드 세부 설정내용' &&
                                            <div style={{
                                                display: 'flex',
                                                minWidth: '400px',
                                                flexDirection: 'column',
                                                gap: '20px',
                                                position: 'relative'
                                            }}>
                                                <div style={{
                                                    display: 'flex', fontSize: 'clamp(18px, 2.5vw, 24px)',
                                                    color: '#006DFF', fontWeight: '600',
                                                    position: 'absolute',
                                                    top: '-70px',
                                                }}>
                                                    {v.title}
                                                </div>
                                                <div style={{display: 'flex', width: '100%'}}>
                                                    <img src={v.img} alt="" style={{
                                                        width: '80%',
                                                        maxWidth: '504px',
                                                        minWidth: '296px',
                                                        height: '100%',
                                                        objectFit: 'contain'
                                                    }}/>
                                                </div>
                                            </div>}
                                        {v.title === '하사점 검출센서 입력접점 정보' &&
                                            <div style={{
                                                display: 'flex',
                                                minWidth: '600px',
                                                flexDirection: 'column',
                                                gap: '20px'
                                            }}>
                                                <SpecContainer style={{width: '384px'}}>
                                                    {v.title}
                                                </SpecContainer>
                                                <div style={{display: 'flex', width: '100%'}}>
                                                    <img src={v.img} alt="" style={{
                                                        maxWidth: '504px',
                                                        minWidth: '296px',
                                                        height: '100%',
                                                        objectFit: 'contain'
                                                    }}/>

                                                </div>
                                            </div>}
                                        {v.title === '하사점 검출장치 안내도' &&
                                            <div style={{
                                                display: 'flex',
                                                minWidth: '600px',
                                                flexDirection: 'column',
                                                gap: '20px'
                                            }}>
                                                <SpecContainer style={{width: '288px'}}>
                                                    {v.title}
                                                </SpecContainer>
                                                <div style={{
                                                    display: 'flex', maxWidth: '504px', maxHeight: '400px',
                                                    minHeight: '274px', minWidth: '296px'
                                                }}>
                                                    <img src={v.mImg} alt="" style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'contain'
                                                    }}/>

                                                </div>
                                                <div style={{
                                                    position: 'relative',
                                                    top: '100px',
                                                    whiteSpace: 'pre-wrap',
                                                    maxWidth: '436px',
                                                    boxSizing: 'border-box',
                                                    display: 'flex', fontSize: '14px',
                                                    color: '#006DFF', fontWeight: '400'
                                                }}>
                                                    {`※ 최적의 검출능력을 확보하기 위해 검출센서와 상금형 플레이트 사이의 간격을 최소 0.5mm이상 확보하여 설치하십시오. 센서와 상금형이 밀착된 상태에서는 이물질검출이 제대로 되지 않습니다`}
                                                </div>
                                            </div>}

                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {productList?.productGuide &&
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '0px'}}>
                            <SpecContainer style={{width: '312px'}}>
                                {'미스피드 검출장치 안내도'}
                            </SpecContainer>
                            <img src={productList?.productGuide} style={{width: '100%', marginTop: '40px'}}/>
                        </div>
                    }
                </div>
            </MainContainer>
    )
    }

    const renderMobile = () => {
        return (
            <MainContainer
                style={{
                    background: '#fff',
                    padding: 'calc(60px + 10%) 10% 60px 10%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                }}>
                {productList?.productInstall?.map((v: any, index: number) =>
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        // marginBottom: !isDesktop ? '100px' : '200px'
                    }}>
                        <div style={{
                            display: 'flex', fontSize: 'clamp(18px, 2.5vw, 24px)',
                            color: '#006DFF', fontWeight: '600',
                            marginBottom: !isDesktop ? '30px' : '40px'
                        }}>
                            {v.mainTitle}
                        </div>

                        {v.detail.map((v: any, index: number) =>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '0px', marginBottom: '80px'}}>
                                {v.title === '전원카드' &&
                                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                        <SpecContainer style={{
                                            maxWidth: '312px', minWidth: '280px',
                                            width: '100%', height: '56px', borderRadius: '28px'
                                        }}>
                                            {v.title}
                                        </SpecContainer>
                                        <img src={v.mImg} alt="" style={{
                                            width: '100%',
                                            height: '100%',
                                            maxWidth: '400px',
                                            maxHeight: '400px',
                                            objectFit: 'contain',
                                            objectPosition: 'center'
                                        }}/>
                                        <div style={{
                                            color: '#929292',
                                            fontWeight: 600,
                                            alignSelf: 'end',
                                            display: 'flex',
                                            fontSize: 'clamp(12px, 1vw, 15px)'
                                        }}>
                                            {` ${cardArr[1].notice[0]}`}
                                        </div>

                                        <DetailContainer>
                                            {cardArr[0].detail.map((v: any, index: number) =>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '10px'
                                                }}>
                                                    <div className={'title'} style={{display: 'flex'}}>
                                                        <span style={{
                                                            color: '#006DFF',
                                                            marginRight: '10px',
                                                            boxSizing: 'border-box',
                                                            fontWeight: 600,
                                                            width: '25px',
                                                            height: '25px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            border: '1px solid #006DFF',
                                                            borderRadius: '50%'
                                                        }}>
                                                            {index + 1}
                                                        </span>
                                                        {v.title}
                                                    </div>
                                                    <div className={'content'}>
                                                        {v.content}
                                                    </div>
                                                </div>
                                            )}
                                        </DetailContainer>
                                    </div>
                                }
                                {v.title === '캠입력/통신 카드' &&
                                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                        <SpecContainer style={{
                                            maxWidth: '312px',
                                            minWidth: '280px',
                                            width: '100%',
                                            height: '56px',
                                            borderRadius: '28px'
                                        }}>
                                            {v.title}
                                        </SpecContainer>
                                        <img src={v.mImg} alt="" style={{
                                            width: '100%',
                                            height: '100%',
                                            maxWidth: '400px',
                                            maxHeight: '400px',
                                            objectFit: 'contain',
                                            objectPosition: 'center'
                                        }}/>
                                        <div style={{
                                            color: '#929292',
                                            fontWeight: 600,
                                            alignSelf: 'end',
                                            display: 'flex',
                                            fontSize: 'clamp(12px, 1vw, 15px)'
                                        }}>
                                            {` ${cardArr[1].notice[0]}`}
                                        </div>

                                        <DetailContainer style={{height: '200px'}}>
                                            {cardArr[1].detail.map((v: any, index: number) =>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '10px'
                                                }}>
                                                    <div className={'title'} style={{display: 'flex'}}>
                                                        <span style={{
                                                            color: '#006DFF',
                                                            marginRight: '10px',
                                                            boxSizing: 'border-box',
                                                            fontWeight: 600,
                                                            width: '25px',
                                                            height: '25px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            border: '1px solid #006DFF',
                                                            borderRadius: '50%'
                                                        }}>
                                                            {index + 1}
                                                        </span>
                                                        {v.title}
                                                    </div>
                                                    <div className={'content'}>
                                                        {v.content}
                                                    </div>

                                                </div>
                                            )}

                                        </DetailContainer>
                                    </div>
                                }
                                {v.title === '미스피드 입력접점 정보' &&
                                    <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                        <SpecContainer style={{
                                            maxWidth: '312px',
                                            minWidth: '280px',
                                            width: '100%',
                                            height: '56px',
                                            borderRadius: '28px'
                                        }}>
                                            {v.title}
                                        </SpecContainer>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                                            <div style={{
                                                display: 'flex', flexDirection: 'column', maxWidth: '500px',
                                                minWidth: '280px', minHeight: '274px', margin: '10px 0px'
                                            }}>
                                                <img src={v.mImg} alt="" style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'contain',
                                                }}/>
                                                <div style={{
                                                    color: '#929292',
                                                    fontWeight: 600,
                                                    alignSelf: 'end',
                                                    display: 'flex',
                                                    fontSize: 'clamp(12px, 1vw, 15px)',
                                                    margin: '10px 0px'
                                                }}>
                                                    {'※ 미스피드 카드 정보'}
                                                </div>
                                            </div>
                                            <DetailContainer style={{
                                                gap: '10px',
                                                height: 'auto',
                                                display: 'grid',
                                                alignItems: 'center',
                                                width: '100%',
                                                gridTemplateColumns: '1fr 1fr',
                                                marginTop: '20px',

                                            }}>
                                                {misfeederArr.map((v: any, index: number) =>
                                                    <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                                        <div className={'title'}>
                                                            {`${index + 1}`}
                                                        </div>
                                                        <div className={'content'}
                                                             style={{minWidth: '140px', color: '#707070'}}>
                                                            {v}
                                                        </div>
                                                    </div>
                                                )}
                                            </DetailContainer>
                                        </div>
                                    </div>}
                                {v.title === '미스피드 세부 설정내용' &&
                                    <div style={{
                                        marginTop: '100px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px',
                                        position: 'relative'
                                    }}>
                                        <div style={{
                                            display: 'flex', fontSize: 'clamp(18px, 2.5vw, 24px)',
                                            color: '#006DFF', fontWeight: '600',
                                            position: 'absolute',
                                            top: '-70px',
                                        }}>
                                            {v.title}
                                        </div>

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxWidth: '500px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            minWidth: '280px',
                                            minHeight: '274px',
                                            margin: '10px 0px'
                                        }}>
                                            <img src={v.mImg} alt="" style={{
                                                width: '80%',
                                                height: '80%',
                                                objectFit: 'contain',
                                                objectPosition: 'center',
                                            }}/>
                                        </div>
                                    </div>}

                                {v.title === '하사점 검출센서 입력접점 정보' &&
                                    <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                        <SpecContainer style={{
                                            maxWidth: '312px',
                                            minWidth: '280px',
                                            width: '100%',
                                            height: '56px',
                                            borderRadius: '28px'
                                        }}>
                                            {v.title}
                                        </SpecContainer>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxWidth: '500px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            minWidth: '280px',
                                            minHeight: '274px',
                                            margin: '10px 0px'
                                        }}>
                                            <img src={v.mImg} alt="" style={{
                                                width: '80%',
                                                height: '80%',
                                                objectFit: 'contain',
                                                objectPosition: 'center',
                                            }}/>
                                        </div>
                                    </div>
                                }
                                {v.title === '하사점 검출장치 안내도' &&
                                    <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                        <SpecContainer style={{
                                            maxWidth: '312px',
                                            minWidth: '280px',
                                            width: '100%',
                                            height: '56px',
                                            borderRadius: '28px'
                                        }}>
                                            {v.title}
                                        </SpecContainer>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxWidth: '500px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            minWidth: '280px',
                                            minHeight: '274px',
                                            margin: '10px 0px'
                                        }}>
                                            <img src={v.mImg} alt="" style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                objectPosition: 'center',
                                            }}/>
                                            <div style={{
                                                marginTop: '20px',
                                                whiteSpace: 'pre-wrap',
                                                maxWidth: '436px',
                                                boxSizing: 'border-box',
                                                display: 'flex', fontSize: '14px',
                                                color: '#929292', fontWeight: '400'
                                            }}>
                                                {`※ 최적의 검출능력을 확보하기 위해 검출센서와 상금형 플레이트 사이의 간격을 최소 0.5mm이상 확보하여 설치하십시오. 센서와 상금형이 밀착된 상태에서는 이물질검출이 제대로 되지 않습니다`}
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        )}
                    </div>
                )}


                {
                    productList?.productGuide &&
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '0px'}}>
                        <SpecContainer style={{
                            maxWidth: '312px',
                            minWidth: '280px',
                            width: '100%',
                            height: '56px',
                            borderRadius: '28px'
                        }}>
                            {'미스피드 검출장치 안내도'}
                        </SpecContainer>
                        <img src={productList?.productGuide} style={{width: '100%', marginTop: '40px'}}/>
                    </div>
                }

            </MainContainer>
        )
    }

    return (
        <>
            {isDesktop ? renderDesktop() : renderMobile()}
        </>
    );
};

export default InterGuideSection;


const DetailContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    margin-left: 20px;
    gap: 20px;
    align-items: start;
    justify-content: center;


    @media (max-width: 425px) {
        gap: 40px;
    }


    .title {
        font-size: clamp(18px, 2vw, 20px);
        font-weight: 600;
        color: #006DFF;
    }

    .content {
        font-size: clamp(18px, 2vw, 20px);
        white-space: pre-line;
        color: #1E222D;
        line-height: 1.5;
        max-width: 247px;
        min-width: 200px;


    }


    .notice {
        font-size: clamp(12px, 1vw, 15px);
        color: #006DFF;
        font-weight: 600;
        white-space: pre-line;
    }

`

const SpecContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    max-width: 312px;
    min-width: 196px;
    height: 44px;
    background-color: #006DFF;
    color: #fff;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;

    @media (max-width: 768px) {
        height: 56px;
        border-radius: 28px;
        width: 312px;
`

const ImageContainer = styled.div<{ horizontal?: boolean }>`
    display: flex;
    box-sizing: border-box;
    width: ${props => props.horizontal ? '504px' : '296px;'};
    height: ${props => props.horizontal ? '296px' : '408px;'};
    position: relative;


    @media (max-width: 1024px) {
        width: ${props => props.horizontal ? '250px' : '200px;'};
        height: ${props => props.horizontal ? '150px' : '276px;'};
        margin: 0;
    }

    // @media (max-width: 425px) {
        //     width: ${props => props.horizontal ? '60%' : '60%;'};
        //     height: ${props => props.horizontal ? 'auto' : 'auto;'};
    //     margin: 0;
    //    
    //
    // }

`
