import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {useRecoilState, useRecoilValue} from 'recoil';
import { LanguageAtom } from '../../common/RecoilState';

const LanguageSwitcher: React.FC = () => {
    const { t, i18n } = useTranslation();
    const selectLanguage = useRecoilValue(LanguageAtom);
    const [selectedLang, setSelectedLang] = useRecoilState<{ lang: string; text: string }>(LanguageAtom);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const onChangeLang = (lang: string) => {
        i18n.changeLanguage(lang);
        window.localStorage.setItem('lang', lang);
        setSelectedLang({ lang, text: t(lang) });
        setIsOpen(false);
        window.open(`/${lang}`, '_blank');
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);



    return (
        <LanguageSwitcherContainer>
            <LanguageButton onClick={() => setIsOpen(!isOpen)} selected={isOpen}>
                <span className="material-symbols-outlined">language</span>
                {/*//열리지 않았거나 언어선택 없을때  '언어선택' 반환*/}
                {selectedLang.text ? (
                    <LanguageText>{selectedLang.text}</LanguageText>
                ) : (
                    <LanguageText>{'Select Language'}</LanguageText>
                )}
            </LanguageButton>

            {isOpen && (
                <DropdownMenu ref={dropdownRef}>
                    <div style={{ marginTop: '8px' }}>
                        {[{ text: 'English', lang: 'en' }, { text: t('한국어'), lang: 'ko' }].map((v, i) => (
                            <DropdownItem
                                key={v.lang}
                                onClick={() => {
                                    onChangeLang(v.lang);
                                    setSelectedLang({ lang: v.lang, text: v.text });
                                }}
                            >
                                {v.text}
                            </DropdownItem>
                        ))}
                    </div>
                </DropdownMenu>
            )}
        </LanguageSwitcherContainer>
    );
};

export default LanguageSwitcher;

const LanguageSwitcherContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const LanguageButton = styled.button<{ selected?: boolean }>`
    padding: 10px;
    width:100%;
    max-width: 90px;
    border: none;
    cursor: pointer;
    background-color: ${props => props.selected ? '#0058ff' : 'transparent'};
    color: white;
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
        background-color: #003bb5;
    }

    .material-symbols-outlined {
        font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48;
    }
`;

const LanguageText = styled.span`
    width: 80px;
    font-size: 12px;
    color: white;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 40px;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    z-index: 1000;
`;

const DropdownItem = styled.button`
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: white;
    color: #0058ff;
    font-size: 14px;
    width: 100%;
    text-align: left;

    &:hover {
        background-color: #f1f1f1;
    }
`;
