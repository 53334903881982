import React, {useEffect, useState} from 'react';
import {ContentFrame} from "../../styles/Styles";
import styled from "styled-components";
import ProductDetailMainSection from "./main/ProductDetailMainSection";
import V3FeatureSection from "./V3/V3FeatureSection";
import V3SpecSection from "./V3/V3SpecSection";
import ProductSuccessSection from "./success/ProductSuccessSection";
import ProductViewMoreSection from "./more/ProductViewMoreSection";
import ProductBrochureDownload from "./more/ProductBrochureDownload";
import Footer from "../../components/footer/Footer";
import ReactPageScroller from "react-page-scroller";
import Aos from "aos";
import V3ComponentSection from "./V3/V3ComponentSection";
import V1FeatureSection from "./V1/V1FeatureSection";
import V1SpecSection from "./V1/V1SpecSection";
import V1ComponentSection from "./V1/V1ComponentSection";
import MisfeederSpecSection from "./misfeeder/MisfeederSpecSection";
import MisfeederComponentSection from "./misfeeder/MisfeederComponentSection";
import DieHeight1chSpecSection from "./1ch/DieHeight1chSpecSection";
import DieHeight1chComponentSection from "./1ch/DieHeight1chComponentSection";
import InterComponentSection from "./intergration/InterComponentSection";
import InterSpecSection from "./intergration/InterSpecSection";
import InterGuideSection from "./intergration/InterGuideSection";
import SLMSSpecSection from "./SLMS/SLMSSpecSection";
import SLMSComponentSection from "./SLMS/SLMSComponentSection";
import PressureSpecSection from "./pressuretype/PressureSpecSection";
import PressureComponentSection from "./pressuretype/PressureComponentSection";
import SafetyHeroSection from "./safety/SafetyHeroSection";
import SafetySubHeroSection from "./safety/SafetySubHeroSection";
import SafetySpecSection from "./safety/SafetySpecSection";
import SafetyServiceSection from "./safety/SafetyServiceSection";
import ProductPanel from "../../components/panel/ProductPanel";
import {animateScroll as scroll} from "react-scroll/modules";
import {useMediaQuery} from "react-responsive";
import {specArray} from "../../common/DataSet";


interface Props {
     productList?: any;
}


const ProductDetailScrollSection:React.FC<Props> = ({productList}) => {



    useEffect(() => {
        Aos.init({ duration: 1000
        });
    },[])

    const isMobile = useMediaQuery({
        query: "(max-width:1024px)"
    })

    const scrollToComponent = (index: number) => {
        // @ts-ignore
        scroll.scrollTo( `component-${index}`, {
            duration: 700,
            smooth: 'easeInOutQuart',
        });
    };


    const renderProductSections = (product: any) => {
            if (product.productName === 'SPMS_V3') {
                return (
                    <>
                        {/*{!isMobile &&*/}
                        {/*    <ProductPanel compType={'list'}*/}
                        {/*                  productList={product}*/}
                        {/*                  onScrollToComponent={scrollToComponent}/>}*/}
                            <ProductDetailMainSection productList={product}/>
                            <V3FeatureSection productList={product}/>
                            <V3ComponentSection productList={product} onScrollToComponent={scrollToComponent}/>
                            <V3SpecSection productList={product}/>

                    </>
                )
            } else if(product.productName === 'SPMS_V1' || product.productName === 'SPMS_V2') {
                return (
                    <>
                        <ProductDetailMainSection productList={product}/>
                        <V1FeatureSection productList={product}/>
                        <V1ComponentSection productList={product}/>
                        <V1SpecSection  productList={product}/>
                    </>
                )
            } else if (product.productName === 'misfeeder' ) {
                return (
                    <>
                        <ProductDetailMainSection productList={product}/>
                        <MisfeederSpecSection productList={product}/>
                        <MisfeederComponentSection productList={product}/>
                    </>
                )
            } else if (product.productName === '1ch') {
                return (
                    <>
                        <ProductDetailMainSection productList={product}/>
                        <DieHeight1chSpecSection productList={product}/>
                        <DieHeight1chComponentSection productList={product}/>
                    </>
                )
            } else if (product.productName === '2ch') {
                return (
                    <>
                        <ProductDetailMainSection productList={product}/>
                        <DieHeight1chSpecSection productList={product}/>
                        <DieHeight1chComponentSection productList={product}/>
                    </>
                )
            } else if (product.productName === 'intergration') {
                return (
                    <>
                        <ProductDetailMainSection productList={product}/>
                        <InterSpecSection productList={product}/>
                        <InterGuideSection productList={product}/>
                        <InterComponentSection productList={product}/>
                    </>
                )
            } else if (product.productName === 'SLMS') {
                return (
                    <>
                        <ProductDetailMainSection productList={product}/>
                        <SLMSSpecSection productList={product}/>
                        <SLMSComponentSection productList={product}/>
                    </>
                )
            } else if(product.productName === 'pressure') {
                return (
                    <>
                        <ProductDetailMainSection productList={product}/>
                        <PressureSpecSection productList={product}/>
                        <PressureComponentSection productList={product}/>
                    </>
                )
            } else if(product.productName === 'safety') {
                return (
                    <>

                        <ProductDetailMainSection productList={product}/>
                        <SafetyHeroSection/>
                        <SafetySubHeroSection/>
                        <SafetySpecSection/>
                        <SafetyServiceSection/>
                    </>
                )
            } else {
                return <div>상품 정보가 없습니다.</div>
            }
    }



    return (
        // 제품 상세 페이지 입니다
        <ContentFrame>
            <ScrollSnapContainer>
            {/*<ReactPageScroller pageOnChange={handlePageChange} containerHeight={isMobile?'100vh':'100vh'}*/}
            {/*                   customPageNumber={currentPage} animationTimer={700}>*/}

                {/* 제품 상세 메인 섹션*/}
                {/*v3*/}
                {!isMobile && productList.productName === 'SPMS_V3' ?
                    <ProductPanel compType={'list'}
                                  productList={productList}
                                  onScrollToComponent={scrollToComponent}/>

                    : !isMobile &&
                    <ProductPanel compType={'type'}
                                  productList={specArray}
                                  onScrollToComponent={scrollToComponent}/>}
                {renderProductSections(productList)}
                <ProductViewMoreSection productList={productList}/>
            </ScrollSnapContainer>
        </ContentFrame>

    );
};

export default ProductDetailScrollSection;

const ScrollSnapContainer = styled.div`
    //scroll-snap-type: y mandatory;
    overflow-y:  scroll;
    height: 100%;
   
    width: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
    
    
     &::-webkit-scrollbar {
        display: none;
     }

  &::-webkit-scrollbar-track {
    background: transparent; /* 스크롤바 트랙 배경을 투명으로 설정 */
  }

  &::-webkit-scrollbar-thumb {
    background: transparent; /* 스크롤바 썸 배경을 투명으로 설정 */
  }
`;

