import React from 'react';
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import Images from "../../../styles/Images";


interface Props {
    solutionList?: any;

}

const SolutionDetailSubSection: React.FC<Props> = ({solutionList}) => {

    const isMobile = useMediaQuery({query: "(max-width: 768px)"});
    return (
        <MainContainer backgroundColor={'#fff'}
                       alignItems={'start'}
                       justifyContent={'center'}
                       padding={isMobile ? '0px 6%' : '0px 20%'}
                       height={'100%'}>
            {solutionList.title === '기업형 & 클라우드 솔루션' &&
                solutionList.detail.map((data: any, index: number) => {
                    return (
                        <div style={{
                            maxWidth: '1232px', alignSelf: 'center',
                            padding: isMobile ? '50px 8%' : '100px 10%', width: '100%'
                        }}>
                            <div key={index} style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: isMobile ? '40px' : '90px'
                            }}>
                                <TitleComp>
                                    <div style={{
                                        display: 'grid',
                                        gridTemplateColumns: '48px 72px 72px',
                                        width: '192px'
                                    }}>
                                        <div className={'bar'} style={{backgroundColor: '#E0E0E0'}}/>
                                        <div className={'bar'} style={{backgroundColor: '#AAAAAA'}}/>
                                        <div className={'bar'} style={{backgroundColor: '#006DFF'}}/>
                                    </div>
                                    <div className={'title'}>
                                        {data.specName}
                                    </div>
                                </TitleComp>
                                <img src={isMobile ? data.specImgM : data.specImg} alt={'subImg'}
                                     style={{width: '100%', height: 'auto'}}/>
                            </div>
                        </div>
                    )
                })
            }

            {solutionList.title === 'FAS' &&
                <>
                    <div style={{
                        maxWidth: '1232px',
                        alignSelf: 'center',
                        padding: !isMobile?'100px 16%':'100px 6%',
                        width: '100%',
                        height: '100%'
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <SpecContainer>
                                {'솔루션 모듈 소개'}
                            </SpecContainer>

                            {solutionList.detail.slice(0, 3).map((data: any, index: number) =>
                                <div style={{
                                    margin: !isMobile?'130px 0':'50px 0',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    flexDirection: isMobile ? 'column' : 'row'
                                }}>
                                    {!isMobile?
                                    <>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        whiteSpace: 'pre-line',
                                        justifyContent: 'start',
                                        gap: '1.5vh'
                                    }}>
                                        <div style={{
                                            fontSize: 'clamp(24px,2vw,40px)',
                                            color: '#006DFF',
                                            fontWeight: 800
                                        }}>
                                            {data.moduleName}
                                        </div>
                                        <div style={{
                                            fontSize: 'clamp(18px,2vw,24px)',
                                            color: '#006DFF',
                                            fontWeight: 500
                                        }}>
                                            {data.fullName}
                                        </div>
                                        <div style={{
                                            fontSize: 'clamp(16px,1vw,24px)',
                                            color: '#484848',
                                            fontWeight: 500,
                                            lineHeight: '1.7'
                                        }}>
                                            {data.moduleContent}
                                        </div>
                                    </div>
                                    <div style={{position: 'relative', marginLeft: '20px'}}>
                                        <img src={data.moduleImg} alt={'subImg'} style={{
                                            margin: isMobile ? '40px 0px' : '0',
                                            maxWidth: '504px',
                                            minWidth: '300px',
                                            width: '100%',
                                            height: 'auto',
                                            objectFit: 'contain',
                                            zIndex: 2,
                                            position: 'relative'
                                        }}/>
                                        <div style={{
                                            position: 'absolute',
                                            right: '-10%',
                                            bottom: isMobile ? '-10px' : '0px',
                                            width: '100%',
                                            maxWidth: '578px',
                                            minWidth: '420px',
                                            height: '72px',
                                            backgroundColor: 'rgba(30, 34, 45, 0.3)',
                                            borderRadius: '101px',
                                            filter: 'blur(36px)',
                                            zIndex: 1,
                                        }}/>
                                    </div>
                                    </> :
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'start',
                                            gap: '1.5vh',
                                            whiteSpace: 'nowrap',
                                        }}>
                                            <div style={{position: 'relative'}}>
                                                <img src={data.moduleImg} alt={'subImg'} style={{
                                                    margin: isMobile ? '0px 0px' : '0',
                                                    maxWidth: '504px',
                                                    minWidth: '250px',
                                                    width: '100%',
                                                    height: 'auto',
                                                    objectFit: 'contain',
                                                    zIndex: 2,
                                                    position: 'relative'
                                                }}/>
                                                <div style={{
                                                    position: 'absolute',
                                                    right: '-10%',
                                                    bottom: isMobile ? '-10px' : '0px',
                                                    width: '100%',
                                                    maxWidth: '578px',
                                                    minWidth: '250px',
                                                    height: '72px',
                                                    backgroundColor: 'rgba(30, 34, 45, 0.3)',
                                                    borderRadius: '101px',
                                                    filter: 'blur(36px)',
                                                    zIndex: 1,
                                                }}/>
                                            </div>
                                            <div style={{
                                                fontSize: 'clamp(24px,2vw,40px)',
                                                color: '#006DFF',
                                                fontWeight: 800,
                                                marginTop: '20px'
                                            }}>
                                                {data.moduleName}
                                                <span style={{
                                                    fontSize: 'clamp(12px,2vw,14px)',
                                                    fontWeight: 500
                                                }}> {data.fullName}</span>
                                            </div>
                                            <div style={{
                                                fontSize: '14px',
                                                color: '#484848',
                                                fontWeight: 500,
                                                lineHeight: '1.5',
                                                marginTop: '10px',
                                                whiteSpace: 'pre-line'
                                            }}>
                                                {data.moduleContentM}
                                            </div>

                                        </div>}

                                </div>
                            )}
                        </div>

                    </div>
                    <div style={{
                        maxWidth: '1440px',
                        alignSelf: 'center',
                        padding:  '100px 120%',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'flex-start',
                        backgroundColor: '#ccc',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <SpecContainer>
                                {'솔루션 오픈 예정'}
                            </SpecContainer>
                            <div style={{
                                margin: '120px 0px',
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {/*<div style={{*/}
                                {/*    display: 'flex',*/}
                                {/*    justifyContent: isMobile ? 'center' :'space-between',*/}
                                {/*    alignItems: 'start',*/}
                                {/*    flexWrap: 'wrap',*/}
                                {/*    padding: '0 8%',*/}
                                {/*    gap: isMobile ? '30px' : '100px',*/}
                                {/*    width: isMobile ? '100%' : 'calc(400px * 2 + 20vw)',*/}
                                {/*}}>*/}
                                <div style={{
                                    display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                                    gridColumnGap: '200px', gridRowGap: '80px', width: '100%',
                                    justifyContent: 'center', alignItems: 'start'
                                }}>
                                    {solutionList.detail.slice(3, 9).map((data: any, index: number) =>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',

                                            gap: '20px',
                                        }}>
                                            <div style={{
                                                boxSizing: 'border-box',
                                                height: 'auto',
                                                position: 'relative',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                                <img src={Images.fas.prepare} alt={'subImg'} style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    zIndex: 3,
                                                    width: '116px',
                                                    height: 'auto',
                                                    objectFit: 'cover'
                                                }}/>
                                                <img src={data.moduleImg} alt={'subImg'} style={{
                                                    margin: isMobile ? '40px 0px' : '0',
                                                    width: '100%',
                                                    minWidth: '280px',
                                                    maxWidth: '420px',
                                                    height: 'auto',
                                                    objectFit: 'cover',
                                                    zIndex: 2,
                                                    position: 'relative'
                                                }}/>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                whiteSpace: 'pre-line',
                                                width: '100%',
                                                justifyContent: 'center',
                                                gap: '1.5vh',
                                                textAlign: 'left'
                                            }}>
                                                <div style={{
                                                    fontSize: 'clamp(24px,2vw,32px)',
                                                    color: '#484848',
                                                    fontWeight: 700
                                                }}>
                                                    {data.moduleName}
                                                </div>
                                                <div style={{
                                                    fontSize: 'clamp(14px,2vw,16px)',
                                                    color: '#484848',
                                                    fontWeight: 500,
                                                    lineHeight: '1.7'
                                                }}>
                                                    {isMobile?data.moduleContentM : data.moduleContent}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }


        </MainContainer>


    );
}

export default SolutionDetailSubSection;

const TitleComp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    height: 100px;

    .bar {
        width: 100%;
        height: 8px;
    }

    .title {

        font-size: 32px;
        font-weight: 600;
        margin-top: 16px;
        white-space: pre-line;

    }

`

const SpecContainer = styled.div <{ row?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;
    width: 100%;
    max-width: 400px;
    background-color: ${props => props.row ? '#E0E0E0' : '#006DFF'};
    color: ${props => props.row ? '#707070' : '#fff'};
    border-radius: 30px;
    font-size: clamp(20px, 3vw, 32px);
    font-weight: 600;
    padding: 0 48px;
    white-space: nowrap;
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
        width: 312px;
        padding: 0 20px;
        height: 40px;
    }

    @media only screen and (max-width: 425px) {
        width: 100%;
        padding: 0 8px;
    }

    @media only screen and (max-width: 280px) {
        width: 100%;
        padding: 0 4px;
    }

`;

