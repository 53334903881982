import React from 'react';
import {useMediaQuery} from "react-responsive";
import {MainContainer, SwiperContainer} from "../../../styles/Styles";
import {ProductType} from "../../../types/Fundamental";
import Buttons from "../../../components/buttons/Buttons";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/swiper-react";

interface Props {
    productList?: ProductType |any;
}

const V1ComponentSection:React.FC<Props> = ({productList}) => {

    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    const renderDesktop = () => {

    return (
        <MainContainer
            style={{
                background: '#fff',
                padding: isDesktop ? '100px 16%' : '30px 10%',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div
                    style={{
                        display: 'flex',
                        fontSize: 'clamp(16px, 3vw, 24px)',
                        color: '#006DFF',
                        fontWeight: 'bold',
                        marginBottom: '40px'
                    }}>
                    {`${productList?.productName} 부속장치`}
                </div>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr',
                    gap: '40px',
                    marginBottom: '200px'
                }}>
                    {productList?.productComponents?.map((v: any, index: number) =>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center'}}>
                            <ImageContainer>
                                <img src={v.img}
                                     style={{
                                         width: '100%',
                                         height: '100%',
                                         objectFit: 'cover',
                                         objectPosition: 'center'
                                     }}/>
                            </ImageContainer>
                            <div style={{
                                fontSize: 'clamp(16px, 2.5vw, 24px)',
                                fontWeight: 'bold', color: '#1E222D'
                            }}>
                                {v.title}
                            </div>
                        </div>
                    )}
                </div>
                <div style={{width: '100%', height: '1px', backgroundColor: '#E0E0E0'}}></div>
            </div>
        </MainContainer>
    )
    }

    const renderMobile = () => {
        return (
            <MainContainer
                style={{
                    background: '#fff',
                    padding: '100px 10% 60px 10%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                }}>
                <div
                    style={{
                        display: 'flex',
                        fontSize: 'clamp(16px, 3vw, 24px)',
                        color: '#006DFF',
                        fontWeight: 'bold',
                        marginBottom: '80px'
                    }}>
                    {`${productList?.productName} 부속장치`}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '0px'}}>
                    {productList?.productComponents?.map((v: any, index: number) =>
                        <div
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                             }}>
                            <SpecContainer>
                                <div className={'title'} style={{fontSize: '16px', height: '48px'}}>
                                    {v.title.slice(1, 20)}
                                </div>
                            </SpecContainer>
                            <ImageContainer>
                                <img src={v.img} alt="" style={{objectFit: 'contain', width: '100%', height: '100%'}}/>
                            </ImageContainer>
                            <div style={{
                                marginBottom: '60px',
                                display: 'flex',
                                width: '100%',
                                maxWidth: '312px',
                                height: '1px',
                                backgroundColor: '#E0E0E0'
                            }}></div>

                        </div>
                    )}
                </div>
            </MainContainer>

        )
    }

    return (
        <>
            {isDesktop ? renderDesktop() : renderMobile()}
        </>


    );
};


export default V1ComponentSection;

const ImageContainer = styled.div`
    display: flex;
    box-sizing: content-box;
    width: 504px;
    height:304px;
    align-items: center;
    
    @media (max-width: 768px) {
        width: 312px;
        height: 232px;
        
    }
    
    `


const SpecContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    //width: 752px;
     
    justify-content: center;
    
    @media (max-width: 768px) {
        width: 100%;
       
        
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
        width: 100%;
        min-width: 200px;
        max-width: 312px;
        height: 56px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 16px 0px;
        box-sizing: border-box;
        background-color: #484848;
        color: #fff;
        border-radius: 28px;
    }

    .sub-title {
        font-size: 20px;
        font-weight: bold;
        color: #1E222D;
        border: 1px solid #006DFF;
        border-radius: 20px;
        padding: 0 16px;
        text-align: center;
        width: 104px;
        height: 40px;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: center;
    }


`
