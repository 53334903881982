import React, {useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import bg from "../../../asset/images/bg/회사소개_숫자로보는_BG.jpeg";
import {createInNumber, MainContainer} from "../../../styles/Styles";
import Aos from "aos";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentPageScroll, prevContainerScrollHeight, scrollPageBlock} from "../../../common/RecoilState";

const InNumberSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    // const isTablet = useMediaQuery({
    //     query: "(max-width: 768px)",
    // });

    // const isMobile = useMediaQuery({
    //     query: "(max-width:820px)"
    // });

    const containerRef = React.useRef<any>(null)
    console.log(containerRef.current?.scrollHeight)

    const [, setPrevScroll] = useRecoilState(prevContainerScrollHeight)


    const inNumberArray=[
        {title:'국내 프레스 제조회사 점유율',
        content:'70% +'},
        {title:'스마트팩토리 구축사례',
            content: '100개 +'},
        {title:'관련 특허기술',
            content: '00개 +'},
        {title:'데이터 수집 정보군',
            content: '700개 +'},
    ]

    useEffect(() => {
            Aos.init({duration: 1000
            });
        }
        ,[])

    const backgroundStyle = {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0.1)), url(${bg})`,
        // padding: isDesktop ? '80px 16%' : '80px 6%',
        backgroundSize: 'cover',
        width: '100vw',
        height: '120dvh',
        backgroundPosition: isDesktop ? 'center' : 'left',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
        minWidth: '300px',
        // boxSizing:'border-box',
    };

    // React.useEffect(()=>{
    //     setPrevScroll((prev:any)=>({...prev, inNumber: Math.round(containerRef?.current.scrollHeight)}))
    // },[])

    return (
        <MainContainer ref={containerRef}
                       // onMouseMove={()=>setPrevScroll((prev:any)=>({...prev, inNumber: Math.round(containerRef?.current.scrollHeight)}))}
                       justifyContent={'center'} alignItems={'center'}
                       width={'100vw'}
                       height={isDesktop?'1080px':'100vh'}>
            <div data-aos={!isDesktop ? '' : 'fade-up-right'}
                 className={'picture'} style={backgroundStyle}>
                <div style={{
                    maxWidth: '1232px', alignSelf: 'center',

                    margin: isDesktop ? '0% auto' : '0 6%',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        height: '100dvh',
                        justifyContent: 'center',
                        padding: isDesktop ? '0px 16% 0px 16%' : '0 10% 0px 10%',
                        boxSizing: 'border-box',
                    }}>
                        <div style={{
                            fontSize: isDesktop ? '24px' : '18px',
                            fontWeight: 'bold',
                            color: '#006DFF',
                            whiteSpace: 'nowrap'
                        }}>
                            {'숫자로 보는 제스텍'}
                        </div>
                        <div style={{
                            display: 'grid', gridTemplateColumns: !isDesktop ? '1fr' : '1fr 1fr',
                            gridColumnGap: isDesktop ? '64px' : '10px',
                            margin: isDesktop ? '40px 0px' : '10px 0px',
                            gridRowGap: isDesktop ? '64px' : '40px',
                        }}>

                            {inNumberArray.map((item, index) =>
                                <div data-aos={!isDesktop ? '' : 'fade-up'} data-aos-dealy={index * 50}
                                     style={{display: 'flex', flexDirection: 'column', gap: isDesktop ? '0px' : '0px'}}>
                                    <div style={{
                                        fontSize: 'clamp(16px,2vw, 32px)',
                                        fontWeight: 'bold', color: '#EDF5FF', whiteSpace: 'nowrap'
                                    }}>
                                        {item.title}
                                    </div>
                                    <div style={{
                                        fontSize: 'clamp(48px, 5vw, 80px)',
                                        fontWeight: 'bold', color: '#EDF5FF', whiteSpace: 'nowrap'
                                    }}>
                                        {item.content}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div data-aos={!isDesktop ? '' : 'fade-up'} style={{
                            fontSize: isDesktop ? '24px' : '18px',
                            fontWeight: 'normal',
                            color: '#fff',
                            opacity: '0.5'
                        }}>
                            {'2023년 00월 기준'}
                        </div>

                    </div>
                </div>
            </div>
        </MainContainer>
)
}
export default InNumberSection;
