import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {Navigation, Scrollbar, FreeMode, Autoplay} from 'swiper/modules';

// @ts-ignore
import {SwiperOptions } from 'swiper/types';
import {productSliderList, specArray} from "../../common/DataSet";
import ProductSlideComp from "../slides/ProductSlideComp";
import {SwiperContainer} from "../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import next from "../../asset/images/icons/Group 525.png"
import {useLocation} from "react-router-dom";



interface Props {
    productList?: any;
}


const ProductViewMoreSwiperContainer:React.FC<Props> = ({productList}) => {
    const swiperRef = React.useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const location = useLocation();

    useEffect(() => {
        if (swiperRef.current) {
            // @ts-ignore
            swiperRef.current.swiper.on('slideChange', function () {
                // @ts-ignore
                const realIndex = swiperRef.current.swiper.realIndex;
                setActiveIndex(realIndex);

                const activeSlide = specArray.find((item: any) => item.link === location.pathname);
                if (activeSlide && activeSlide.link !== location.pathname) {
                    window.location.href = activeSlide.link;
                }
            });
        }
    }, [swiperRef, location.pathname]);

    // useEffect(() => {
    //     if (swiperRef.current) {
    //         const activeSlide = specArray.find((item: any) => item.link === location.pathname);
    //         const initialSlide = activeSlide ? specArray.indexOf(activeSlide) : 0;
    //         swiperRef.current.swiper.slideTo(initialSlide, 0);
    //         setActiveIndex(initialSlide);
    //     }
    // }, [swiperRef, location.pathname]);




    const isDesktop = useMediaQuery({
        query: "(min-width: 769px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:416px)"
    })

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });


    const swiperParams: SwiperOptions = {

        on: {
            slideChange: function () {
                // @ts-ignore
                setActiveIndex(this.realIndex);
            }
        },

        modules: [Navigation,Autoplay],


        slideToClickedSlide: true,
        speed: 1000,
        centeredSlides: true,
        initialSlide: specArray.findIndex((item: any) => item.link === location.pathname),
        loop: true,
        navigation: {
            nextEl: '.custom-next',
            prevEl: '.custom-prev',
        },

        slideClass: 'swiper-slide-mobile',
        slideActiveClass:'swiper-slide-mobile-active',

        breakpoints: {
            280: {
                slidesPerView: 2,
                spaceBetween: 230,

            },
            425 : {
                slidesPerView: 2,
                spaceBetween: 180,


            },
            // 530:{
            //     slidesPerView:3,
            //     spaceBetween:300,
            //
            // },
            768:{
                slidesPerView:3,
                spaceBetween:200,

            },
            769:{
                slidesPerView:3,
                spaceBetween:150,


            },
            800:{
                slidesPerView:3,
                spaceBetween:100,

            },
            900:{
                slidesPerView:4,
                spaceBetween:200,

            },

            1024:{
                slidesPerView:4,
                spaceBetween:200,

            },
            1025:{
                slidesPerView:4,
                spaceBetween:180,

            },
            1100:{
                slidesPerView:4,
                spaceBetween:150,

            },
            1200:{
                slidesPerView:4,
                spaceBetween:80,

            },
            1280:{
                slidesPerView:5,
                spaceBetween:100,

            },
            1440:{
                slidesPerView:5,
                spaceBetween:100,

            },
            1580:{
                slidesPerView:5,
                spaceBetween:50,

            },
            1880:{
                slidesPerView:6,
                spaceBetween:270,
            },



            2560:{
                slidesPerView:5,
                spaceBetween:0,
            }
        }

    };


    return (
        <SwiperContainer style={{position:'relative',width:'100%',maxWidth:'1600px'}}>
            <Swiper ref={swiperRef} className={'swiper-container-viewmore'}
                    {...swiperParams}>

                {specArray.map((item: any, index: number) =>

                        <SwiperSlide key={index} className={'swiper-slide-mobile'} style={{
                            opacity: //현재 링크와 같은 슬라이드 , 액티브 슬라이드 둘다 1로 설정
                                item.link === location.pathname || activeIndex === index ? 1 : 0.5,}}>
                            <ProductSlideComp clickEvent={() => {window.location.href=item.productName}}
                                              selected={item.link === location.pathname}
                                compType={'mobile'} title={item.productName} sub={item.info} productType={item.category} image={item.mainImg} />
                        </SwiperSlide>
                )}
            </Swiper>

            <img src={next} alt="next" className={'custom-next'} style={{position:'absolute',top:'250px',right:'0%',zIndex:'201'}}/>
            <img src={next} alt="next" className={'custom-prev'} style={{position:'absolute',top:'250px',left:'0%',zIndex:'1'}}/>



        </SwiperContainer>
    );
};
export default ProductViewMoreSwiperContainer;
