import React, {useEffect, useState} from 'react';
import ReactPageScroller from "react-page-scroller";

import RegisterCsSection from "./inquiry/RegisterCsSection";
import Aos from "aos";
import {useMediaQuery} from "react-responsive";
import Footer from "../../components/footer/Footer";
import styled from "styled-components";

const CustomerServiceScrollSection = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);



    const isMobile = useMediaQuery({
            query: "(max-width:768px)"
        }
    )
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handlePageChange = (number: number) => {
        setCurrentPage(number);
    }



    return (
        <React.Fragment>
                <ScrollSnapContainer>
                    <RegisterCsSection/>
                </ScrollSnapContainer>
        </React.Fragment>

    );
};

export default CustomerServiceScrollSection;




const ScrollSnapContainer = styled.div`
  //scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 1100%;
  width: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  
  //scroll-snap-stop: always;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & > div {
    scroll-snap-align: start;
  }
`;
