import React, {useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import bg from "../../../asset/images/bg/ctaBG.webp";
import Buttons from "../../../components/buttons/Buttons";
import Aos from "aos";
import bgM from "../../../asset/images/bg/product_cta_mobile.png";
import bg1 from "../../../asset/images/bg/product_cta_bg.png";

const SolutionCtaSection = () => {

    const isDesktop = useMediaQuery({
        query: "(min-width: 769px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    // useEffect(() => {
    //     Aos.init({
    //         duration: 1000
    //     });
    // }, [])
    const backgroundStyle = {
        backgroundSize: isDesktop?'cover':'auto 46vh',
        width: '100%',
        backgroundPosition: isDesktop ? 'center' : '60% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
    };

    return (
        <MainContainer justifyContent={'center'} height={'100%'}
                       flexDirection={'column'}>
            <div className={'picture'} style={{
                backgroundImage: isMobile ? `url(${bgM})` : `url(${bg1})`,
                height: !isDesktop ? '320px' : '600px',
                justifyContent: 'center',
                boxSizing: 'border-box',
                backgroundSize: 'cover',
                width: '100%',
                filter: isDesktop ? 'contrast(0.9)' : 'none',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <div className={'hero-main'} style={{padding: isDesktop ? '0 16%' : '0 4%'}}>
                    <div style={{
                        zIndex: 2,
                        display: 'flex', flexDirection: 'column',
                        gap: isDesktop ? '60px' : '30px', padding: '0', alignItems: 'center', justifyContent: 'center'
                    }}>
                        <div className={'hero_text'}
                             style={{
                                 display: '-webkit-box',
                                 WebkitLineClamp: 3,
                                 WebkitBoxOrient: 'horizontal',
                                 fontSize: 'clamp(24px, 5vw, 56px)',
                                 fontWeight: 'bold',
                                 // maxWidth: isDesktop ? '770px' : (isTablet ? '500px' : '200px'),
                                 overflow: 'visible',
                                 textAlign: 'center',
                                 whiteSpace: 'pre-line',
                                 letterSpacing: ' 0.0em',
                             }}>
                            {'프레스 관련 제품 및 솔루션\n공정개선까지 주식회사 제스텍'}
                        </div>

                        <Buttons buttonType={'blue-main'} width={isDesktop ? '320px' : '216px'}
                                 height={isDesktop ? '64px' : '48px'}
                                 fontSize={isDesktop ? '24px' : '16px'} clickEvent={() => {
                            window.location.href = '/cs'
                        }}
                                 text={'맞춤 서비스 신청하기 >'}/>


                    </div>
                </div>

            </div>
        </MainContainer>
    );
};

export default SolutionCtaSection;
