import React, {useEffect} from 'react';
import {MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import {ProductType} from "../../../types/Fundamental";
import {featureArray} from "../../../common/DataSet";
import Aos from "aos";

interface Props {
    productList?: ProductType | any;
}


const V3FeatureSection: React.FC<Props> = ({productList}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:430px)"
    });

    // const titleArray = [
    //     {
    //         img: Icons.data,
    //         title: '생산 및\n 보전 데이터 처리',
    //         bg: Images.data,
    //         content: '제품 생산 데이터 뿐만 아니라,\n입/출력, 파라미터 정보 , 공정 상태 정보 등\n 100여가지 정보를 가지고 있으며, \n연동 시 해당 정보를 가져갈 수 있습니다.'
    //     },
    //     {
    //         img: Icons.module,
    //         title: '모듈화로 인한\n 보전 편리',
    //         bg: Images.module,
    //         content: '제품 교체 시 고장 난 모듈만\n교체할 수 있도록 구성되어 있습니다.'
    //     },
    //     {
    //         img: Icons.low,
    //         title: 'PLC 대비\n 비용 절감',
    //         bg: Images.low,
    //         content: '스마트 팩토리 시대에 필요한 요소를 구성하기 위한\nPLC 구성 품 대비 저렴하게 구축할 수 있습니다.'
    //     }
    // ]
    useEffect(() => {
            Aos.init({
                duration: 1000
            });
        }
        , [])
    return (
        <MainContainer
            style={{
                background: '#fff',
                padding: isDesktop ? '100px 16%' : '60px 10%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%'
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div data-aos={'fade-up'}
                     style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div style={{
                        marginTop: '100px',
                        gap: '120px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        // height: isMobile ? '1000px' : '1080px',
                        width: '100%'
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '20px', justifyContent: 'center'}}>
                            <div className={'hero_text'}
                                 style={{
                                     fontWeight: '600',
                                     fontSize: 'clamp(24px, 3vw, 40px)',
                                     zIndex: '2',
                                     textAlign: 'left',
                                     color: '#00337D',
                                     whiteSpace: isDesktop ? 'nowrap' : 'pre-line',
                                     width: '100%'
                                 }}>
                                {/*{`${productList?.productFeature[0].title}`}*/}
                                {'SPMS Protocol을 기반으로 Cloud 또는 S/W에 연결할 경우,'}
                            </div>
                            <div className={'hero_text'}
                                 style={{
                                     fontWeight: 'normal',
                                     fontSize: 'clamp(20px, 3vw, 40px)',
                                     textAlign: 'left',
                                     color: '#000000',
                                     whiteSpace: 'pre-line',
                                     width: isDesktop ? '100%' : '80%',
                                 }}>
                                {/*{`${productList?.productFeature[0].content}`}*/}
                                {' 생산관리 및 설비 관리 데이터를 받아 보실 수 있습니다.'}
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            justifyContent: 'space-between'
                        }}>
                            <div className={'hero_text'}
                                 style={{
                                     fontWeight: '700',
                                     fontSize: 'clamp(24px, 3vw, 40px)',
                                     zIndex: '2',
                                     textAlign: 'left',
                                     width: '100%',
                                     color: '#006DFF',
                                     whiteSpace: isDesktop ? 'nowrap' : 'pre-line'
                                 }}>
                                {/*{`${productList?.productFeature[0].title}`}*/}
                                {'제스텍만의 독보적인 공정 데이터 전송 기술 SPMS'}
                            </div>
                            <div className={'hero_text'}
                                 style={{
                                     fontWeight: 'normal',
                                     fontSize: 'clamp(20px, 3vw, 32px)',
                                     textAlign: 'left',
                                     width: isDesktop ? '100%' : '80%',
                                     color: '#000000',
                                     whiteSpace: 'pre-line',
                                     lineHeight: '1.5'
                                 }}>
                                {/*{`${productList?.productFeature[0].content}`}*/}
                                {' 초당 최대 20회 이상 속도로 5가지의 Type 데이터를 전송하며,\n공정 SPMS 200까지 데이터를 놓치지 않고 수집할 수 있습니다.'}
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            <div style={{fontWeight: 'bold', fontSize: 'clamp(18px, 4vw, 24px)', color: '#006DFF'}}>
                                {'주요 특징'}
                            </div>
                            <div data-aos={'fade-up'}
                                 style={{
                                     display: 'flex',
                                     alignItems: 'center',
                                     justifyContent: !isDesktop ? 'center' : 'start',
                                     marginTop: isMobile ? '10px' : '40px',
                                     gap: isMobile ? '10px' : '40px',
                                 }}>
                                {featureArray.map((item, index) =>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '20px',
                                        marginTop: isMobile ? '20px' : '40px',
                                        alignItems: 'center'
                                    }}>
                                        <IconContainer>
                                            <img src={item.img} className={'img'}/>
                                        </IconContainer>
                                        <div style={{
                                            fontWeight: 600,
                                            fontSize: 'clamp(12px, 2.5vw, 20px)',
                                            color: '#000000',
                                            height: '20px',
                                            textAlign: 'center',
                                            whiteSpace: 'pre-line'
                                        }}>{item.title}</div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                    <div style={{
                        maxWidth: '1200px',
                        height: '1px',
                        backgroundColor: '#E0E0E0',
                        marginTop: isMobile ? '100px' : '150px'
                    }}></div>

                    {/*이미지 박스 */}
                    <div style={{
                        display: 'flex',
                        // height: '100%',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: '20%',
                        gap: isDesktop ? '100px' : '0px',
                    }}>
                        {featureArray.map((item, index) =>
                            <div>
                                <ImageContainer>
                                    <div className={'text-wrapper'}
                                         style={{backgroundColor: index === 0 ? '#006DFF' : '#1E222D', zIndex: 2}}>
                                        <div style={{
                                            fontWeight: 'bold',
                                            fontSize: 'clamp(25px, 2vw, 40px)',
                                            color: '#fff',
                                            whiteSpace: 'pre-line'
                                        }}>
                                            {item.title}
                                        </div>
                                        <div style={{
                                            fontWeight: 'normal',
                                            fontSize: 'clamp(14px, 2vw, 24px)',
                                            color: '#fff',
                                            whiteSpace: 'pre-line',
                                        }}>
                                            {item.content}
                                        </div>
                                    </div>

                                    <div style={{
                                        maxWidth: '612px',
                                        height: !isDesktop ? '430px' : '480px',
                                        position: 'absolute',
                                        right: isMobile ? '0px' : '20px',
                                        width: '100%',
                                        zIndex: 1,

                                    }}/>
                                    <img src={item.bg} className={'img'}
                                         style={{objectPosition: !isDesktop && index === 2 ? '50% -120px' : ''}}/>
                                </ImageContainer>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </MainContainer>
);
};

export default V3FeatureSection;

const IconContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 227px;
    height: 227px;
    border-radius: 50%;
    background-color: #EDF5FF;
    box-sizing: border-box;


    .img {
        width: 122px;
        height: 122px;
        object-fit: contain;
    }

    @media all and (max-width: 768px) {
        width: 120px;
        height: 120px;
        .img {
            width: 70px;
            height: 70px;;
        }
    }

    @media all and (max-width: 430px) {
        width: 96px;
        height:96px;
        .img {
            width:42px;
            height: 42px;
        }
    }
`

const ImageContainer = styled.div`
    
    width: 1200px;
    height: 480px;
    border-radius: 56px 0 0 0;
    display: flex;
    overflow: hidden;
    position: relative;
    min-width: 300px;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px #00000029;

    .text-wrapper {
        display: flex;
        width: 608px;
        height: 480px;
        flex-direction: column;
        justify-content: center;
        border-radius: 56px 0 0 0;
        z-index: 1;
        padding: 60px 64px;
        min-width: 300px;
        gap: 30px;
        box-sizing: border-box;
    }


    .img {
        width: 800px;
        height: 480px;
        object-fit: cover;
        z-index: 0;
        position: absolute;
        right: 0px;
    }
    
    @media screen and (max-width: 1440px) {
        width: 960px;
        
        .text-wrapper {
            width: 480px;
            padding: 60px 64px;
        }
        .img {
            width: 800px;
            height: 480px;
        }
        
       
        
    }

    @media (max-width: 1024px) {
        width: 480px;
        height: 480px;
        border-radius: 0;
        box-sizing: content-box;
        display: flex;
        overflow: hidden;
        position: relative;

        .text-wrapper {
            display: flex;
            width: 480px;
            height: 240px;
            flex-direction: column;
            justify-content: flex-start;
            border-radius: 56px 0 0 0;
            background: rgba(0, 109, 255, 0.56);
            z-index: 1;
            padding: 40px 60px;
            gap: 10px;
            box-sizing: border-box;
            bottom: -290px;
            position: relative;

            .img {
                width: 480px;
                height: 480px;
                object-fit: contain;
                z-index: 0;
                position: absolute;
                right: 0;
            }
        }

        @media (max-width: 425px) {
            width: 100vw;
            height: 425px;
            .text-wrapper {
                width: 100vw;
                height: 200px;
                bottom: -250px;
                padding: 30px 40px;
                gap: 8px;
            }

            .img {
                width: 110%;
                height: 425px;
                object-fit: cover;
                z-index: 0;
                position: absolute;
                right: 0;
            }
        }
        @media (max-width: 375px) {
            width: 100vw;
            height: 344px;
            .text-wrapper {
                width: 100vw;
                height: 200px;
                bottom: -170px;
                padding: 30px 40px;
                gap: 8px;

            }

            .img {
                width: 110%;
                height: 344px;
                object-fit: cover;
                z-index: 0;
                position: absolute;
                right: 0;

            }
        }
    }
`
