import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import icon from "../../asset/images/icons/Path 21@2x.png";
import {useMediaQuery} from "react-responsive";

const DownButton = () => {
    const isDesktop =  useMediaQuery({
        query: "(min-width: 821px)",
    }
    )
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            // 현재 스크롤 위치를 가져오기
            const scrollY = window.scrollY || window.pageYOffset;
            // 스크롤 위치에 따라 isVisible 업데이트
            setIsVisible(scrollY < document.body.clientHeight - window.innerHeight);
        };
        // 스크롤 이벤트 리스너 등록
        window.addEventListener('scroll', handleScroll);
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // []는 한 번만 실행


    return (
        <ButtonComp style={{opacity: isVisible ? 1 : 0, visibility: isVisible ? 'visible' : 'hidden'}}>

            <div>
                <img src={icon} alt="chat icon"  style={{cursor:'pointer',width:isDesktop?'100%':'50px'}}
                />
            </div>
        </ButtonComp>
    );
};

export default DownButton;


const ButtonComp=styled.div`
  position: fixed;
  bottom: 50px;  
  left: 50%; 
  transform: translateX(-50%);
  z-index: 100;
  transition: all 0.2s ease-in-out;

  & > div {
    animation: bounce 2s infinite;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
    
`