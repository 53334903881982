import React from 'react';
import {useMediaQuery} from "react-responsive";

import {MainContainer} from "../../../styles/Styles";
import Images from "../../../styles/Images";


const SafetyHeroSection: React.FC = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    // const isTablet = useMediaQuery({
    //     query: "(max-width: 1023px)",
    // });

    const isMobile = useMediaQuery({
        query: "(max-width:1024px)"
    });

    const backgroundStyle = {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(${Images.safety.bg})`,
        padding: isDesktop ? '0 16%' : '0 6%',
        backgroundSize: 'cover',
        width: '100%',
        height:'1080px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'content-box',
    };


    const renderDesktop = () => {
        return (
            <MainContainer backgroundImage={backgroundStyle.backgroundImage}
                           justifyContent={'center'}
                           alignItems={'center'}
                           height={'100%'}>
                <div className={'picture'} style={backgroundStyle}>
                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                        width: '100%'
                    }}>
                        <img src={Images.safety.ic} alt={'safety'} style={{
                            width: isDesktop ? '189px' : '50%',
                            height: isDesktop ? '168px' : '50%',
                            objectFit: 'cover'
                        }}/>
                        <div style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'horizontal',
                            fontSize: 'clamp(24px, 4.8vw, 40px)',
                            fontWeight: 'bold',
                            overflow: 'visible',
                            marginTop: isDesktop ? '30px' : '20px',
                            color: '#fff',
                            whiteSpace: 'pre-line',
                            letterSpacing: ' 0.0em',
                            textAlign: 'center'
                        }}>
                            {'산업 안전사고'}
                        </div>
                        <div style={{
                            marginTop: isDesktop ? '40px' : '20px',
                            color: '#fff',
                            fontSize: 'clamp(18px, 2.5vw, 32px)',
                            display: 'flex',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'horizontal',
                            // maxWidth: isDesktop ? '1030px' : isTablet ? '600px' : '280px',
                            // maxHeight: isDesktop ? '100px' : isTablet ? '80px' : '80px',
                            overflow: 'visible',
                            whiteSpace: !isDesktop ? 'pre-line' : 'nowrap',
                            lineHeight: 1.3,
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '40px',
                            textAlign: !isDesktop ? 'center' : 'left'
                        }}>
                            <div>{'2023년 상반기 392명,\n'}
                                <span style={{color: '#5390ec', fontWeight: '700'}}>
                                하루 2명 이상 산재 사고로 사망</span>합니다.
                            </div>
                            <div>{'50인 미만 사업장 사고의 79.5%는\n'}
                                <span style={{color: '#5390ec', fontWeight: '700'}}>
                                {'사망사고'}
                            </span>
                                {'로 이어지고 있습니다.\n(2023년 상반기 기준)'}
                            </div>

                        </div>
                    </div>
                </div>
            </MainContainer>
        )
    }


    const renderMobile = () => {
        return (
            <MainContainer backgroundColor={'#292A30'}
                           justifyContent={'center'}
                           alignItems={'center'}
                           height={isMobile ? '100%' : '100%'}>
                <div style={{position: 'relative', width: '100%', height: '100%'}}>
                    <img src={Images.safety.bg} style={{width: '100%', minWidth: '320px', zIndex: 1}}/>
                    <div style={{
                        position: 'absolute',
                        top: '-4px',
                        left: 0,
                        backgroundColor: '#1E222D',
                        width: '100%',
                        height: '100%',
                        mixBlendMode: 'multiply',
                        opacity:0.92,
                    }}/>
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 3,
                        color: '#fff',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <img src={Images.safety.ic} alt={'safety'} style={{
                            width: isDesktop ? '189px' : '50%',
                            height: isDesktop ? '168px' : '50%',
                            objectFit: 'cover'
                        }}/>
                        <div style={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'horizontal',
                            fontSize: 'clamp(18px, 4.8vw, 40px)',
                            fontWeight: 'bold',
                            overflow: 'visible',
                            marginTop: isDesktop ? '30px' : '20px',
                            color: '#fff',
                            whiteSpace: 'pre-line',
                            letterSpacing: ' 0.0em',
                            textAlign: 'center'
                        }}>
                            {'산업 안전사고'}
                        </div>
                    </div>

                </div>
                <div style={{
                    marginTop: isDesktop ? '40px' : '20px',
                    color: '#fff',
                    padding: '80px 0 100px 0px',
                    fontSize: 'clamp(18px, 2.5vw, 32px)',
                    display: 'flex',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'horizontal',
                    overflow: 'visible',
                    whiteSpace: !isDesktop ? 'pre-line' : 'nowrap',
                    lineHeight: 1.3,
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '40px',
                    textAlign: !isDesktop ? 'center' : 'left'
                }}>
                    <div>{'2023년 상반기 392명,\n'}
                        <span style={{color: '#5390ec', fontWeight: '700'}}>
                                하루 2명 이상 산재 사고로 사망</span>합니다.
                    </div>
                    <div>{'50인 미만 사업장 사고의 79.5%는\n'}
                        <span style={{color: '#5390ec', fontWeight: '700'}}>
                                {'사망사고'}
                            </span>
                        {'로 이어지고 있습니다.'}
                        <span style={{color: '#A1A1A1',fontSize:'16px', fontWeight: '500'}}>
                            {'\n(2023년 상반기 기준)'}
                        </span>
                    </div>

                </div>


            </MainContainer>

        )
    }

    return (
        <>
            {isDesktop ? renderDesktop() : renderMobile()}
        </>
    );
};

export default SafetyHeroSection;