import React, {useContext, useEffect, useRef} from 'react';
import styled from "styled-components";
import logo from '../../asset/images/logo_white.svg'
import { useMediaQuery } from "react-responsive";
import {ContainerProps} from "../../types/Fundamental";
import Notiflix from "notiflix";
import {useLocation, useNavigate} from "react-router-dom";
import MobileMenuComponent from "../menu/MobileMenuComponent";
import { menuArr } from "../../common/DataSet";
import LanguageSwitcher from "./LanguageSwitcher";
import {useTranslation} from "react-i18next";


interface HeaderProps {
    menu?: any
    openModal?: () => void
}

const Header: React.FC<HeaderProps> = ({openModal}) => {
    const { t }:any = useTranslation();
    const location = useLocation();
    const [menuOpen, setMenuOpen] = React.useState(-1);
    const [selectedMenu, setSelectedMenu] = React.useState(-1);
    const isDesktop = useMediaQuery({
        query: "(min-width:1025px)"
    });

    const navigate = useNavigate();


    const tempValidate = (link: string,menu?:string) => {
        if (link === '') {
            Notiflix.Report.info('준비중...', '', 'OK');
        }else if(menu ==='IR'){
            openModal!()
        }
        else {
            window.location.href = link;
        }
    }
    const pageBackground = location.pathname.includes('product') ? '#000' : 'transparent linear-gradient(180deg, #00000086 0%, #00000046 56%, #00000000 89%, #00000000 100%) 0% 0% no-repeat padding-box';



    return (
        <React.Fragment>
            {isDesktop ?
                <Container height={80} backgroundColor={pageBackground}>
                   <div style={{
                    maxWidth:'1230px', alignSelf: 'center', display:'flex',
                    margin: '0 auto',
                    justifyContent: 'space-between', width: '100%',
                       alignItems:'center'
                }}>
                        <div onClick={() => window.location.href = '/'} className={'logo'}>
                            <img style={{width: '120px', height: '42px'}} src={logo} alt="logo"/>
                        </div>
                        <div className={'menu'}>
                            {menuArr.map((v, i) => (
                                <div key={i}
                                     style={{
                                         backgroundColor: v.link === location.pathname ? '#006DFF' : '',
                                         position: 'relative'
                                     }}
                                     onMouseEnter={() => setMenuOpen(i)}
                                     onClick={() => {
                                         tempValidate(v.link, v.title);
                                     }}>
                                    {t(v.title)}
                                    {v.subMenu && menuOpen === i && (
                                        <div className={'sub-menu'} onMouseLeave={() => setMenuOpen(-1)}
                                             style={{height: v.subMenu.length * 64 + 20}}>
                                            {v.subMenu.map((subMenuItem, i) => (
                                                <div key={i}
                                                     className={'sub-menu-item'}
                                                     style={{
                                                         backgroundColor:subMenuItem.link === location.pathname ? '#006DFF' : '',
                                                     }}
                                                     onClick={(e) => {
                                                         e.stopPropagation()
                                                         tempValidate(subMenuItem.link, subMenuItem.menu)
                                                     }
                                                     }>
                                                    {t(subMenuItem.menu)}
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                </div>
                            ))}

                        </div>
                   </div>
                    <LanguageSwitcher/>
                </Container>


                :
                <>

                <div style={{backgroundColor:'#000' ,position:'fixed',width:'100%',zIndex:100}}>
                  <MobileMenuComponent sub={''} openModal={openModal} />
             </div>
                </>

}

</React.Fragment>
    );
};

export default Header;

const Container = styled.div<ContainerProps>`
  padding: 24px 15%;
  top: 0;
  align-items: center;
  height: ${props => props.height}px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 100;
  cursor: pointer;
  position: fixed;
  top:0;
  box-sizing: border-box;
    
  background: ${props => props.backgroundColor ??
          'transparent linear-gradient(180deg, #00000086 0%, #00000046 56%, #00000000 89%, #00000000 100%) 0% 0% no-repeat padding-box'};
  
  .menu {
    display: grid;
    width: 60%;
    grid-template-columns: repeat(4, auto);
    grid-auto-flow: column;
    grid-column-gap: 30px;
    white-space: nowrap;
    font-size: 20px;
    color: #fff;
    font-weight: normal;
    box-sizing: border-box;
    transition: background-color 0.3s;
    
    & > div {
      text-align: center;
      border-radius: 16px;
      height: 56px;
      width: 100%;
      padding: 4px;
      box-sizing: border-box;
      align-items: center;
      max-width: 160px;
      line-height: 48px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #1E222D;
        padding: 4px;
        }
      
      
      &:hover .sub-menu {
        //transition: all 0.3s;
        //transform: translateX(-50%) translateY(0);
      }
    }
    .sub-menu {
        display: flex;
      //display: none;
      border-radius: 16px;
      transform: translateX(-50%) translateY(0);
      flex-direction: column;
      position: absolute;
      margin-top: 2px;
        top: 100%;
        left: 50%;
      z-index: 100;
      align-items: center;
      justify-content: center;
      width: 360px;
      box-sizing: border-box;
      background-color: #1E222D;
      transition: all 0.3s;
      
      .sub-menu-item {
        z-index: 101;
        text-align: center;
        box-sizing: border-box;
        width: 360px;
        height: 64px;
        font-size: 18px;
        line-height: 64px;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 109, 255, 0.5);
          text-decoration: underline;
          text-underline-offset: 3px;
          font-weight: bold;
        
        }
       
      }
    }
  }
}
`
