import React, {useEffect, useState} from 'react';
import {ProductType} from "../../../types/Fundamental";
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";


interface Props {
    productList?: ProductType |any;
}

const InterComponentSection:React.FC<Props> = ({productList}) => {

    const isDesktop = useMediaQuery({
        query: "(min-width: 1440px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    const nameTranslate = (name:any) => {
        if (name === 'intergration') {
            return '통합 검출 장치'
        } else {
            return name
        }
    }

    // const detailArr = [
    //     {
    //         name: '전원카드',
    //         notice:'※ 전원카드 단자대 핀 맵',
    //         detail:
    //             [{title: '출력신호 단자', content: '이상 발생시\n활성화되는 출력단자 2CH\n작동은 동시에 하지만,\n각 채널은 전기적으로 분리되어있음'},
    //             {title: 'RST', content: 'RST 리셋입력, N24와 연결되면 에러리셋'},
    //             {title: '전원단자', content: 'DC24V/1A 전원입력'}]
    //     },
    //     {
    //         name: '캠입력/통신 카드',
    //         notice:['※ 캠입력/통신카드 단자대 핀 맵','※ COM 단자는 기계 본체 또는\nN24에 연결하십시오',],
    //         detail:
    //             [{title: 'RS485', content:'RS485 통신 핀타입 단자'},
    //             {title: '캠 입력 단자', content: 'COM:공통단자\nCAM1~4: 캠입력 1~4'}
    //             ]
    //     },
    //
    // ]
    //
    // const misfeederArr = ['재료말단','재료휨','미스피드 CH1','미스피드 CH2','미스피드 CH3','미스피드 CH4']
    //
    //
    // const renderDetail = (detail:any) => {
    //     return (
    //         <DetailContainer>
    //             {detail.detail && detail.detail.map((v: any, index: number) =>
    //                 <div style={{display:'flex',gap:!isDesktop?'0px':'10px',flexDirection:'column',
    //                     marginTop:!isDesktop?'30px':'40px',whiteSpace:isMobile?'nowrap':'pre-line'}}>
    //                     <div className={'title'}>
    //                         {v.title}
    //                     </div>
    //                         {isMobile && v.title !== '설치안내(미스피드)'?
    //                             null
    //                             :
    //                             <div className={'content'}>
    //                                 {v.content}
    //                             </div>}
    //                 </div>
    //                     )}
    //                 </DetailContainer>
    //             )
    //             }
    // const rendermisfeeder = (detail: any) => {
    //     return (
    //         <DetailContainer style={{gap:'0px'}}>
    //             {detail.map((v: any, index: number) =>
    //                 <div style={{
    //                     display: 'flex',
    //                     gap: '20px',
    //                     whiteSpace: 'pre-line',
    //                     alignItems:'center'
    //                 }}>
    //                     <div className={'title'}>
    //                         {`${index+1}`}
    //                     </div>
    //                     <div className={'content'}>
    //                         {v}
    //                     </div>
    //                 </div>
    //             )}
    //         </DetailContainer>
    //     )
    // }
    // const renderNotice = (detail:any) => {
    //     return (
    //         <DetailContainer>
    //             {Array.isArray(detail.notice) ?
    //                 detail.notice.map((v: any, index: number) =>
    //                     <div key={index} style={{
    //                         display: 'flex',
    //                         gap: '10px',
    //                         flexDirection: 'column',
    //                         position: index === 1 ? 'relative' : 'static', // 두 번째 요소에 대한 상대 위치 설정
    //                         top: index === 1 ? !isDesktop?'10px':'-260px' : '0', // 두 번째 요소에 대한 상단 위치 설정
    //                         left: index === 1 ? !isDesktop?'0px':'300px' : '0', // 두 번째 요소에 대한 왼쪽 위치 설정
    //                         whiteSpace: 'pre-line'
    //                     }}>
    //                         <div className={'notice'}>
    //                             {v}
    //                         </div>
    //                     </div>
    //                 )
    //                 :
    //                 <div style={{
    //                     display: 'flex',
    //                     gap: '10px',
    //                     flexDirection: 'column',
    //                     marginTop: !isDesktop?'0px':'40px',
    //                     whiteSpace: 'pre-line'
    //                 }}>
    //                     <div className={'notice'}>
    //                         {detail.notice}
    //                     </div>
    //                 </div>
    //             }
    //         </DetailContainer>
    //     )
    // }

  const renderDesktop = () => {
    return (
        <MainContainer
            style={{
                background: '#fff',
                padding: isDesktop ? '100px 16% 200px 16%' : '150px 10%',
                flexDirection: 'column',
                justifyContent: 'center',
                height: isDesktop ? '100%' : isMobile ? '100%' : '100%',
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div
                    style={{
                        display: 'flex', fontSize: 'clamp(18px, 2.5vw, 24px)',
                        color: '#006DFF', fontWeight: 'bold', marginBottom: '40px'
                    }}>
                    {`${nameTranslate(productList?.productName)} 부속품`}
                </div>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    height: '100%',
                    gap: '20px',
                    justifyContent: 'center'

                }}>
                    {productList?.productComponents?.map((v: any, index: number) => {
                        if (index <= 2) {
                            return (

                                <div style={{
                                    width: '30%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                    alignItems: 'center'
                                }}>
                                    <ImageContainer>
                                        <img src={v.img}
                                             style={{
                                                 width: '100%',
                                                 height: '100%',
                                                 objectFit: 'contain',
                                                 objectPosition: 'center',

                                             }}/>
                                    </ImageContainer>
                                    <div style={{
                                        fontSize: 'clamp(16px, 2.5vw, 24px)',
                                        fontWeight: 'bold', color: '#1E222D'
                                    }}>
                                        {v.title}
                                    </div>
                                    {v.title.includes('2EA') && (
                                        <div style={{position: 'relative', zIndex: 1}}>
                                            <EaContainer>{'X2'}</EaContainer>
                                        </div>
                                    )}
                                    {v.title.includes('4EA') && (
                                        <div style={{position: 'relative', zIndex: 1}}>
                                            <EaContainer>{'X4'}</EaContainer>
                                        </div>
                                    )}
                                </div>

                            )
                                ;
                        } else {
                            return (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                    width: '40%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '40px'
                                }}>
                                    <ImageContainer horizontal={true}>
                                        <img src={v.img}
                                             style={{
                                                 width: '100%',
                                                 height: '100%',
                                                 objectFit: 'contain',
                                                 objectPosition: 'center',

                                             }}/>
                                    </ImageContainer>
                                    <div style={{
                                        fontSize: 'clamp(16px, 2.5vw, 24px)',
                                        fontWeight: 'bold', color: '#1E222D'
                                    }}>
                                        {v.title}
                                    </div>
                                </div>


                            )
                        }
                    })
                    }
                </div>
            </div>
        </MainContainer>
  )}

  const renderMobile = () => {
      return (
          <MainContainer
              style={{
                  background: '#fff',
                  padding: '100px 10% 60px 10%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                }}>
                <div
                    style={{
                        display: 'flex',
                        fontSize: 'clamp(16px, 3vw, 24px)',
                        color: '#006DFF',
                        fontWeight: 'bold',
                        marginBottom: '20px'
                    }}>
                    {`${nameTranslate(productList?.productName)} 부속장치`}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '0px'}}>
                    {productList?.productComponents?.map((v: any, index: number) =>
                        <div
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                             }}>
                            <SpecContainer>
                                <div className={'title'} style={{fontSize: '16px', height: '48px'}}>
                                    {v.title.slice(1, 20)}
                                </div>
                            </SpecContainer>
                            <ImageContainer style={{border: 'none'}}>

                                {v.title.includes('방진고무') ?
                                    <img src={v.img} alt="" style={{
                                        objectFit: 'contain', left: '30%', position: 'absolute',
                                        width: '120px', height: '100%'
                                    }}/>
                                    :
                                    <img src={v.img} alt=""
                                         style={{objectFit: 'contain', width: '100%', height: '100%'}}/>
                                }

                            </ImageContainer>
                            {v.title.includes('2EA') && (
                                <div style={{position: 'relative', zIndex: 1}}>
                                    <EaContainer>{'X2'}</EaContainer>
                                </div>
                            )}
                            {v.title.includes('4EA') && (
                                <div style={{position: 'relative', zIndex: 1}}>
                                    <EaContainer>{'X4'}</EaContainer>
                                </div>
                            )}
                            <div style={{
                                marginBottom: '60px',
                                display: 'flex',
                                width: '100%',
                                maxWidth: '312px',
                                height: '1px',
                                backgroundColor: '#E0E0E0'
                            }}></div>

                        </div>
                    )}


                </div>
            </MainContainer>
        )
    }

    return (
        <>
            {isDesktop ? renderDesktop() : renderMobile()}

        </>
    );
};


export default InterComponentSection;

const ImageContainer = styled.div<{ horizontal?: boolean }>`
    display: flex;
    box-sizing: content-box;
    width: ${props => props.horizontal ? '504px' : '296px;'};
    height: ${props => props.horizontal ? '296px' : '408px;'};
    align-items: center;
    position: relative;
    margin-left: 20px;

    @media (max-width: 1440px) {
        width: ${props => props.horizontal ? '250px' : '200px;'};
        height: ${props => props.horizontal ? '150px' : '276px;'};
        margin: 0;
    }

    // @media (max-width: 425px) {
        //     width: ${props => props.horizontal ? '60%' : '60%;'};
        //     height: ${props => props.horizontal ? 'auto' : 'auto;'};
    //     margin: 0;
    //    
    //
    // }

`

const SpecContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    //width: 752px;
     
    justify-content: center;
    
    @media (max-width: 768px) {
        //width: 100%;
        
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
        width: 100%;
        min-width: 280px;
        max-width: 312px;
        height: 56px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 16px 0px;
        box-sizing: border-box;
        background-color: #484848;
        color: #fff;
        border-radius: 28px;
    }

    .sub-title {
        font-size: 20px;
        font-weight: bold;
        color: #1E222D;
        border: 1px solid #006DFF;
        border-radius: 20px;
        padding: 0 16px;
        text-align: center;
        width: 104px;
        height: 40px;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: center;
    }


`


const EaContainer = styled.div`
    border-radius: 50%;
    background-color: #EDF5FF;
    color: #006DFF;
    width: 66px;
    height: 66px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(24px, 3vw, 30px);
    position: absolute; /* 절대 위치 설정 */
    top: -200px; /* 이미지 상단에 위치하도록 조정 */
    left: 80px;
    transform: translateX(-0%); /* X축 중앙 정렬을 위한 변환 */

    @media (max-width: 768px) {
        width: 44px;
        height: 44px;
        font-size: 20px;
        top: -138px;
        left: 50px;
    }
`;
