import React from 'react';
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import {certificationData} from "../../common/DataSet";
import {ContainerProps} from "../../types/Fundamental";
import Aos from "aos";
import "aos/dist/aos.css";


const CertificationComp = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 769px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:416px)"
    });


    React.useEffect(() => {
            Aos.init();

        }
        , [])


    return (
        <Container>
            <div className={'cert_paper_group'}>
                {certificationData.map((value, index) => (
                    <React.Fragment>
                        <CertPaper
                            style={{backgroundImage: `url(${value.certImage})`}} text={value.certText}/>
                    </React.Fragment>
                ))}
            </div>
        </Container>
    );
};

export default CertificationComp;


const Container = styled.div`

    .cert_paper_group {
        margin-top: 48px;
        display: grid;
        grid-auto-flow: dense;
        grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));
        grid-gap: 16px;
        justify-content: center;
    }

    .cert_paper_group > * {
        justify-self: center;
    }

`

const CertPaper = styled.div<ContainerProps>`
    max-width: 296px;
    width: 100%;
    height: 404px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;


    &::before {
        content: '${props => props.text}';
        position: absolute;
        bottom: 0;
        width: 100%;
        max-height: 100%;
        height: 0;
        background-color: rgba(36, 70, 150, 0.1);
        color: #fff;
        transition: all 0.3s;
        box-sizing: border-box;
        padding: 0 24px;
        overflow: hidden;
        line-height: 24px;
    }

    &:hover {
        &::before {
            content: '${props => props.text}';
            position: absolute;
            bottom: 0;
            width: 100%;
            max-height: 100%;
            height: 120px;
            background-color: rgba(30, 34, 45, 0.7);
            border-radius:  0 0 24px 24px;
            color: #fff;
            font-size: clamp(16px, 1.5vw, 24px);
            font-weight: bold;
            text-align: center;
            box-sizing: border-box;
            padding: 24px;
            transition: all 0.3s;
            overflow: hidden;
            line-height: 24px;
        }
    }
`