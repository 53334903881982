
import data from '../asset/images/products/common/feature/Mask Group 54@2x.png';
import module from '../asset/images/products/common/feature/Mask Group 63@2x.png';
import low from '../asset/images/products/common/feature/Mask Group 99@2x.png';
import xButton from '../asset/images/icons/ic_x.svg';
import arrow from '../asset/images/icons/chevron-down.svg';
import mainBg from '../asset/images/bg/main_bg.png';
import mainFactory from '../asset/images/main/factory.svg';
import mainCloud from '../asset/images/main/ic_cloud.svg';
import mainIndustry from '../asset/images/main/ic_industry.svg';
import mainIot from '../asset/images/main/ic_iot.svg';
import mainSmartFac from '../asset/images/main/ic_smartfactory.svg';
import mainSubHero from '../asset/images/main/main_subHero.png';


//솔루션 페이지
import fasSystem from '../asset/images/solutions/fassystem@2x.png';



//솔루션별 이미지

//fas 솔루션
import fasBg from '../asset/images/bg/SolutionFasBG.webp';
import fasMonitor from '../asset/images/solutions/fas/fas_monitor@2x.png';
import pms from '../asset/images/solutions/fas/pms@2x.png';
import pbas from '../asset/images/solutions/fas/pbas@2x.png';
import fems from '../asset/images/solutions/fas/fems@2x.png';
import prepare from '../asset/images/solutions/fas/prepare@2x.png';
import blurImage from '../asset/images/solutions/fas/blurImage@2x.png';

//기업형 & 클라우드 솔루션
import bizCloudBG from '../asset/images/bg/bizCloudBG.webp';
import callback from '../asset/images/solutions/bizcloud/callBack@2x.png';
import callbackM from '../asset/images/solutions/bizcloud/callBack_mobile0@2x.png';
import backup from '../asset/images/solutions/bizcloud/backUp@2x.png';
import backupM from '../asset/images/solutions/bizcloud/backup_mobile@2x.png';
import dataApi from '../asset/images/solutions/bizcloud/dataAPI@2x.png';
import dataApiM from '../asset/images/solutions/bizcloud/dataApi_mobile@2x.png';



//제품별 이미지

// V3
import spmsv3 from '../asset/images/products/V3/main/V3_main@2x.png';
import v3base from '../asset/images/products/V3/spec/v3_base@2x.png';
import v3cpu from '../asset/images/products/V3/spec/V3_cpu@2x.png';
import v3power from '../asset/images/products/V3/spec/V3_power card@2x.png';
import v3input from '../asset/images/products/V3/spec/V3_input@2x.png';
import v3output from '../asset/images/products/V3/spec/V3_output@2x.png';
import v3front from '../asset/images/products/V3/spec/V3_front@2x.png';
import v3sensor from '../asset/images/products/V3/spec/V3_sensor@2x.png';
import v3layoutFront from '../asset/images/products/V3/spec/V3_layout_front@2x.png';
import v3layoutBack from '../asset/images/products/V3/spec/V3_layout_back@2x.png';

// V1
import spmsv1 from '../asset/images/products/V1/main/V1_main@2x.png';
import sensor from '../asset/images/products/common/spec/V1_sensor@2x.png';
import power from '../asset/images/products/common/spec/V1_power.@2x.png';
import ramp from '../asset/images/products/common/spec/V1_ramp@2x.png';
import spare from '../asset/images/products/common/spec/V1_sparecam.png';

//V2
import spmsv2 from '../asset/images/products/V2/main/V2_main@2x.png';

// 미스피더
import misfeeder from '../asset/images/products/misfeeder/main/misfeeder_newmain@2x.png';
import magnet from '../asset/images/products/common/spec/magnet@2x.png';
import proof from '../asset/images/products/common/spec/dustproofrubber@2x.png';
import lshape from '../asset/images/products/common/spec/L-shapebracket@2x.png';
import misfeederSpring from '../asset/images/products/common/spec/missfeeder_springsensor-set@2x.png';
import guide from '../asset/images/products/common/spec/install-drawing@2x.png';

// 하사점 검출장치 1ch
import dieHeight1ch from '../asset/images/products/1ch/main/1ch_main@2x.png';
import dieHeightSensor from '../asset/images/products/common/spec/1ch_sensor@2x.png';
import dieHeightSpring from '../asset/images/products/common/spec/1ch_spring@2x.png';

//하사점 검출장치 2ch
import dieHeight2ch from '../asset/images/products/2ch/main/2ch_main@2x.png';

//통합 검출장치
import intergration from '../asset/images/products/intergration/main/inter_main@2x.png';
import interPower from '../asset/images/products/intergration/spec/inter_power@2x.png';
import mInterPwer from '../asset/images/products/intergration/spec/m_inter_power@2x.png';
import interCam from '../asset/images/products/intergration/spec/inter_cam@2x.png';
import mInterCam from '../asset/images/products/intergration/spec/m_inter_cam@2x.png';
import interMiss from '../asset/images/products/intergration/spec/inter_missfeed@2x.png';
import mInterMiss from '../asset/images/products/intergration/spec/m_inter_missfeed@2x.png';
import interWave from '../asset/images/products/intergration/spec/inter_waveform@2x.png';
import mInterWave from '../asset/images/products/intergration/spec/m_inter_waveform@2x.png';
import interdie from '../asset/images/products/intergration/spec/inter_dieheight@2x.png';
import mInterDie from '../asset/images/products/intergration/spec/m_inter_dieheight@2x.png';
import interGuide from '../asset/images/products/intergration/spec/inter_guide@2x.png'
import mGuide from '../asset/images/products/intergration/spec/m_inter_guide@2x.png'

//SLMS
import slms from '../asset/images/products/SLMS/main/SLMS_main@2x.png';
import slmsExample from '../asset/images/products/SLMS/Group 39168@2x.png';
import slmsArrow from '../asset/images/products/SLMS/Group 39343.svg';
import slmsInstall1 from '../asset/images/products/SLMS/Mask Group 157@2x.png';
import slmsInstall2 from '../asset/images/products/SLMS/Mask Group 158@2x.png';

//압전압식 로드모니터
import pressure from '../asset/images/products/pressuretype/main/pressuretype_main@2x.png';
import pressureInstall from '../asset/images/products/pressuretype/spec/pressuretype_install@2x.png';
import pressureWire from '../asset/images/products/pressuretype/spec/pressuretype_wiring@2x.png';
import pressureCam from '../asset/images/products/pressuretype/spec/pressuretype_cam@2x.png';
import pressureRestet from '../asset/images/products/pressuretype/spec/pressuretype_reset@2x.png';
import pressureOutput from '../asset/images/products/pressuretype/spec/pressuretype_output@2x.png';
import npn from '../asset/images/products/pressuretype/spec/pressuretype_npn@2x.png';
import pnp from '../asset/images/products/pressuretype/spec/pressuretype_pnp@2x.png';
import pressureSetting from '../asset/images/products/pressuretype/spec/pressuretype_setting@2x.png';
import mPressureSetting from '../asset/images/products/pressuretype/spec/m_pressuretype_setting.png';
import mInstall from '../asset/images/products/pressuretype/spec/m_pressuretype_install.png';

//안전 솔루션
import safetyBG from '../asset/images/products/safety/hero/safety_bg@2x.png';
import safetyIC from '../asset/images/products/safety/hero/ic_safety.svg';
import safety from '../asset/images/products/safety/main/safety@2x.png';
import safetyThumb from '../asset/images/products/safety/hero/ic_thumb.png';
import safetySite from '../asset/images/products/safety/spec/safety_site@2x.png';
import safetyMethod from '../asset/images/products/safety/spec/safety_method@2x.png';
import safetyNotebook from '../asset/images/products/safety/spec/safety_notebook@2x.png';
import safetySolution from '../asset/images/products/safety/spec/safety_solution.png';
import safetyIconEye from '../asset/images/icons/free-icon-eye-scan-1629093@2x.png';
import safetyIconAlarm from '../asset/images/icons/free-icon-alarm-4697084@2x.png';
import safetyIconSensor from '../asset/images/icons/free-icon-sensor-4407903@2x.png';
import safetyIconControl from '../asset/images/icons/free-icon-control-device-2360657@2x.png';
import safetyProcess from '../asset/images/products/safety/spec/safety_process@2x.png';
import safetyProcessLine from '../asset/images/products/safety/spec/safety_processLine@2x.png';
import safetyArrow from '../asset/images/icons/ic_solution_arrow.svg';

//스마트 솔루션 fas
import pbas1 from '../asset/images/solutions/fas/detail/FAS@2x.png';
import pbas2 from '../asset/images/solutions/fas/detail/FAS2@2x.png';
import pbas3 from '../asset/images/solutions/fas/detail/FAS3@2x.png';
import pms1 from '../asset/images/solutions/fas/detail/FAS4@2x.png';
import pms2 from '../asset/images/solutions/fas/detail/FAS5@2x.png';
import pms3 from '../asset/images/solutions/fas/detail/FAS7@2x.png';
import fems1 from '../asset/images/solutions/fas/detail/FAS8@2x.png';
import fems2 from '../asset/images/solutions/fas/detail/FAS9@2x.png';
import fems3 from '../asset/images/solutions/fas/detail/FAS10@2x.png';


const Images =  {
    icons:{
        xButton:xButton,
        arrow:arrow
    },
    main:{
        bg:mainBg,
        factory:mainFactory,
        subHero:mainSubHero,
        cloud:mainCloud,
        industry:mainIndustry,
        iot:mainIot,
        smartFac:mainSmartFac,

    },
    data: data,
    module: module,
    low: low,
    fasSystem:fasSystem,
    v3 : {
        main:spmsv3,
        base:v3base,
        cpu:v3cpu,
        power:v3power,
        input:v3input,
        output:v3output,
        front:v3front,
        sensor:v3sensor,
        layoutFront:v3layoutFront,
        layoutBack:v3layoutBack
    },
    v1: {
        main:spmsv1,
        sensor:sensor,
        power:power,
        ramp:ramp,
        spare:spare
    },
    v2: {
        main:spmsv2
    },
    misfeeder:{
        main:misfeeder,
        magnet:magnet,
        proof:proof,
        lshape:lshape,
        spring:misfeederSpring,
        guide:guide
    },
    dieHeight1ch:{
        main:dieHeight1ch,
        sensor:dieHeightSensor,
        spring:dieHeightSpring

    },
    dieHeight2ch:{
        main:dieHeight2ch
    },
    intergration:{
        main:intergration,
        power:interPower,
        mPower:mInterPwer,
        cam:interCam,
        mCam:mInterCam,
        miss:interMiss,
        mMiss:mInterMiss,
        wave:interWave,
        mWave:mInterWave,
        die:interdie,
        mDie:mInterDie,
        guide:interGuide,
        mGuide:mGuide

    },
    slms:{
        main:slms,
        example:slmsExample,
        arrow: slmsArrow,
        install1:slmsInstall1,
        install2:slmsInstall2
    },
    pressure:{
        main:pressure,
        install:pressureInstall,
        wire:pressureWire,
        cam:pressureCam,
        reset:pressureRestet,
        output:pressureOutput,
        npn:npn,
        pnp:pnp,
        setting:pressureSetting,
        mSetting:mPressureSetting,
        mInstall:mInstall
    },
    safety:{
        ic:safetyIC,
        bg:safetyBG,
        main:safety,
        icThumb:safetyThumb,
        method:safetyMethod,
        solution:safetySolution,
        site:safetySite,
        notebook:safetyNotebook,
        eye:safetyIconEye,
        sensor:safetyIconSensor,
        alarm:safetyIconAlarm,
        control:safetyIconControl,
        process:safetyProcess,
        line:safetyProcessLine,
        icArrow:safetyArrow
    },
    bizCloud:{
        bg:bizCloudBG,
        callback:callback,
        callbackM:callbackM,
        backup:backup,
        backupM:backupM,
        dataApi:dataApi,
        dataApiM:dataApiM
    },
    fas:{
        bg: fasBg,
        monitor:fasMonitor,
        pms:pms,
        pbas:pbas,
        fems:fems,
        blurImage:blurImage,
        prepare:prepare
    },
    smartFas:{
        pbas1:pbas1,
        pbas2:pbas2,
        pbas3:pbas3,
        pms1:pms1,
        pms2:pms2,
        pms3:pms3,
        fems1:fems1,
        fems2:fems2,
        fems3:fems3
    }


}
export default Images;
