import React from 'react';
import {ProductType} from "../../../types/Fundamental";
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";


interface Props {
    productList?: ProductType | any;
}

const PressureComponentSection: React.FC<Props> = ({productList}) => {

    const isDesktop = useMediaQuery({
        query: "(min-width: 1440px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    });

    const nameTranslate = (name: any) => {
        if (name === 'pressure') {
            return '압전압식\n로드모니터(별치형)'
        } else {
            return name
        }
    }

    const installArray = [
        {main: '출력신호 단자', sub: '이상 발생시 활성화되는 출력단자'},
        {main: 'IN2', sub: '채널2 센서입력'},
        {main: 'IN1', sub: '채널1 센서입력'},
        {main: 'IP2', sub: '리셋입력'},
        {main: 'IP1', sub: '캠입력'},
        {main: 'B1~B13', sub: '채널2 데이터출력'},
        {main: 'A1~A13', sub: '채널1 데이터출력'},
        {main: '전원단자', sub: 'DC24V 전원입력'},
    ]


    return (
        <MainContainer
            style={{
                background: '#fff',
                padding: isDesktop ? '200px 16%' : '100px 5%',
                flexDirection: 'column',
                justifyContent: 'start',
                height: isDesktop ? '100%' : isMobile ? '100%' : '100%'
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                {productList?.productInstall?.map((v: any, index: number) =>
                    <React.Fragment>
                        <div style={{display: 'flex', flexDirection: 'column', marginBottom: '0px'}}>
                            <div style={{
                                display: 'flex',
                                fontSize: 'clamp(18px , 2.5vw, 24px)',
                                color: '#006DFF',
                                fontWeight: '700',
                            }}>
                                {v.mainTitle}
                            </div>
                        </div>
                        {v.mainTitle === '설치안내' &&
                            <div style={{
                                display: 'flex', flexDirection: 'column', marginBottom: '60px',
                                alignItems: !isDesktop ? 'center' : 'start', justifyContent: 'center'
                            }}>
                                <img src={!isDesktop ? v.mImg : v.img} alt={"img"}
                                     style={{
                                         margin: '20px 0',
                                         width: isDesktop ? '660px' : '312px',
                                         height: 'auto',
                                         objectFit: 'contain'
                                     }}/>
                                {!isDesktop &&
                                    installArray.map((v: any, index: number) =>
                                            <DetailContainer isNotice={false}
                                                             style={{
                                                                 display: 'flex', height: '100%', minHeight: '0px',
                                                                 alignItems: 'start', width: '100%',
                                                                 marginBottom: isMobile ? '40px' : '40px'
                                                             }}>
                                                <div className={'title'} style={{display: 'flex'}}>
                                     <span style={{
                                         color: '#006DFF',
                                         marginRight: '10px',
                                         boxSizing: 'border-box',
                                         fontWeight: 600,
                                         width: '25px',
                                         height: '25px',
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'center',
                                         border: '1px solid #006DFF',
                                         borderRadius: '50%'
                                     }}>
                              {index + 1}
                                </span>
                                                    {v.main}
                                                </div>
                                                <div style={{
                                                    fontSize: 'clamp(14px, 2.5vw, 20px)',
                                                    color: '#1E222D',
                                                    marginLeft: '35px',
                                                    lineHeight: '1.5'
                                                }}>{v.sub}</div>
                                            </DetailContainer>
                                    )}
                            </div>
                        }

                        {v.mainTitle === '센서 결선도' &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: '150px',
                                alignItems: 'center'
                            }}>
                                <img src={v.img} alt={"img"}
                                     style={{
                                         width: !isDesktop ? '100%' : '516px',
                                         height: 'auto',
                                         objectFit: 'contain'
                                     }}/>
                                <DetailContainer isNotice={!isDesktop ? false : true}>
                                    {!isDesktop ?
                                        <div style={{
                                            display: 'flex',
                                            width: '120px',
                                            borderRadius: '28px',
                                            height: '56px',
                                            backgroundColor: '#006dff',
                                            color: '#fff',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontWeight: 600,
                                        }}>
                                            {'주의사항'}
                                        </div>
                                        :
                                        <div className="title">{'주의사항'}</div>
                                    }

                                    <div style={{display: 'flex', flexDirection: 'column', marginBottom: '10px'}}>

                                        {v.notice.map((v: any, index: number) =>
                                            <div style={{
                                                display: 'flex',
                                                gap: '10px',
                                                marginTop: '20px',
                                                alignItems: 'baseline',
                                            }}>
                                                <div style={{
                                                    boxSizing: 'border-box',
                                                    background: !isDesktop ? '#006dff' : '#000',
                                                    width: '6px',
                                                    height: '6px',
                                                    borderRadius: '50%',
                                                }}></div>
                                                <div className="content"
                                                     style={{display: 'flex', width: '100%'}}>
                                                    {v}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </DetailContainer>
                            </div>
                        }
                        {v.mainTitle === '캠 결선도' &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: '150px',
                                alignItems: 'center'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    marginTop: '40px',
                                    marginBottom: '50px',
                                    flexDirection: !isDesktop ? 'column' : 'row',
                                    alignItems: 'center'
                                }}>
                                    <img src={v.img} alt={"img"}
                                         style={{
                                             width: !isDesktop ? '100%' : '516px',
                                             height: 'auto',
                                             objectFit: 'contain'
                                         }}/>
                                    <div style={{
                                        lineHeight: '1.5',
                                        fontSize: 'clamp(18px, 2.5vw, 20px)',
                                        marginTop: '40px',
                                        whiteSpace: 'pre-line'
                                    }}>{v.content}</div>
                                </div>
                                <DetailContainer isNotice={!isDesktop ? false : true}>
                                    {!isDesktop ?
                                        <div style={{
                                            display: 'flex',
                                            width: '120px',
                                            borderRadius: '28px',
                                            height: '56px',
                                            backgroundColor: '#006dff',
                                            color: '#fff',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontWeight: 600,
                                        }}>
                                            {'주의사항'}
                                        </div>
                                        :
                                        <div className="title">{'주의사항'}</div>
                                    }

                                    <div style={{display: 'flex', width: '100%', marginBottom: '0px'}}>

                                        {v.notice.map((v: any, index: number) =>

                                            <div style={{
                                                display: 'flex',
                                                gap: '10px',
                                                marginTop: '20px',
                                                alignItems: 'baseline',
                                            }}>
                                                <div style={{
                                                    boxSizing: 'border-box',
                                                    background: !isDesktop ? '#006dff' : '#000',
                                                    width: '6px',
                                                    height: '6px',
                                                    borderRadius: '50%',
                                                }}></div>
                                                <div className="content"
                                                     style={{display: 'flex', width: '100%'}}>

                                                    {v}
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </DetailContainer>
                            </div>
                        }
                        {v.mainTitle === '리셋 입력 결선도' &&
                            <div style={{display: 'flex', flexDirection: 'column', marginBottom: '150px'}}>
                                <div style={{
                                    display: 'flex',
                                    marginTop: '40px',
                                    marginBottom: '0px',
                                    flexDirection: !isDesktop ? 'column' : 'row',
                                    alignItems: 'center'
                                }}>
                                    <img src={v.img} alt={"img"}
                                         style={{
                                             width: !isDesktop ? '100%' : '516px',
                                             height: 'auto',
                                             objectFit: 'contain'
                                         }}/>
                                    <div style={{
                                        lineHeight: '1.5',
                                        marginTop: '40px',
                                        fontSize: 'clamp(18px, 2.5vw, 20px)',
                                        whiteSpace: 'pre-line'
                                    }}>{v.content}</div>
                                </div>
                            </div>
                        }
                        {v.mainTitle === '데이터 출력' &&
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                < img src={v.img} alt={"img"}
                                      style={{
                                          marginBottom: '80px',
                                          width: '100%',
                                          minWidth: '312px',
                                          objectPosition: 'center',

                                          height: 'auto',
                                          objectFit: 'contain'
                                      }}/>
                            </div>
                        }
                        {v.mainTitle === 'NPN 타입' &&
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                < img src={v.img} alt={"img"}
                                      style={{
                                          marginBottom: '80px',
                                          width: '100%',
                                          minWidth: '312px',
                                          objectPosition: 'center',

                                          height: 'auto',
                                          objectFit: 'contain'
                                      }}/>
                            </div>
                        }
                        {v.mainTitle === 'PNP 타입' &&
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                < img src={v.img} alt={"img"}
                                      style={{
                                          marginBottom: '80px',
                                          width: '100%',
                                          minWidth: '312px',
                                          objectPosition: 'center',

                                          height: 'auto',
                                          objectFit: 'contain'
                                      }}/>
                            </div>
                        }
                        {v.mainTitle === '출력 설정 방법' &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '80px',

                            }}>
                                <SpecContainer>
                                    {'세팅모드 진입 방법'}
                                </SpecContainer>
                                <div style={{
                                    lineHeight: '1.5',
                                    fontSize: 'clamp(18px, 2.5vw, 20px)',
                                    marginTop: '40px',
                                    marginBottom: '100px',
                                    whiteSpace: 'pre-line',
                                }}>{v.content}</div>
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <img src={!isDesktop ? v.mImg : v.img} alt={"img"}
                                         style={{
                                             marginBottom: '40px',
                                             width: '100%',
                                             minWidth: '312px',
                                             objectPosition: 'center',

                                             height: 'auto',
                                             objectFit: 'contain'
                                         }}/>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: !isDesktop ? 'column' : 'row',
                                    gap: '20px'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginTop: !isDesktop ? '20px' : '40px'
                                    }}>
                                        {v.notice.map((v: any, index: number) =>
                                            <div style={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'baseline',
                                                marginTop: '20px'
                                            }}>

                                                <div style={{
                                                    boxSizing: 'border-box',
                                                    background: !isDesktop ? '#006dff' : '#000',
                                                    width: '6px',
                                                    height: '6px',
                                                    borderRadius: '50%',
                                                }}></div>
                                                <div style={{
                                                    width: '100%',
                                                    lineHeight: '1.5',
                                                    fontSize: 'clamp(18px, 2.5vw, 20px)',
                                                    whiteSpace: 'pre-line'
                                                }}>{v}</div>
                                            </div>
                                        )}
                                    </div>
                                    <DetailContainer isNotice={!isDesktop ? false : true}
                                                     style={{
                                                         width: !isDesktop ? '100%' : '427px',
                                                         borderRadius: '54px',
                                                         padding: !isDesktop ? '0px' : '0 47px',
                                                         boxSizing: 'border-box'
                                                     }}>
                                        {!isDesktop ?
                                            <SpecContainer>
                                                {'출력 타입 설명'}
                                            </SpecContainer>
                                            :
                                            <div className={'title'}>{'출력 타입 설명'}</div>}
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',

                                            marginTop: '10px',
                                            gap: '10px'
                                        }}>

                                            <div style={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'baseline',
                                                marginTop: '0px'
                                            }}>
                                                <div style={{
                                                    boxSizing: 'border-box',
                                                    background: !isDesktop ? '#006dff' : '#000',
                                                    width: '6px',
                                                    height: '6px',
                                                    borderRadius: '50%',
                                                }}></div>
                                                <div className={'content'}>
                                            <span style={{
                                                color: 'rgba(16,68,138,0.95)',
                                                marginRight: '8px'
                                            }}>{'BIN'}</span>
                                                    {'- 2진수 출력'}</div>
                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                                marginTop: '0px'
                                            }}>
                                                <div style={{
                                                    boxSizing: 'border-box',
                                                    background: !isDesktop ? '#006dff' : '#000',
                                                    width: '6px',
                                                    height: '6px',
                                                    borderRadius: '50%',
                                                }}></div>
                                                <div className={'content'}>
                                        <span style={{
                                            color: 'rgba(16,68,138,0.95)',
                                            marginRight: '8px'
                                        }}>{'BCD'}</span>
                                                    {'- 2진화 10진 코드 출력'}</div>
                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                gap: '10px',
                                                alignItems: 'center',
                                                marginTop: '0px'
                                            }}>
                                                <div style={{
                                                    boxSizing: 'border-box',
                                                    background: !isDesktop ? '#006dff' : '#000',
                                                    width: '6px',
                                                    height: '6px',
                                                    borderRadius: '50%',
                                                }}></div>
                                                <div className={'content'}>
                                            <span style={{
                                                color: 'rgba(16,68,138,0.95)',
                                                marginRight: '8px'
                                            }}>{'GRY'}</span>
                                                    {'- 그레이코드 출력'}</div>
                                            </div>
                                        </div>


                                    </DetailContainer>
                                </div>
                            </div>
                        }


                    </React.Fragment>
                )
                }

            </div>
        </MainContainer>
)
;
};

export default PressureComponentSection;

const DetailContainer = styled.div
    <{ isNotice: boolean }>`
background-color: ${
    props => props.isNotice ? '#EDF5FF' : 'none'
};
border-radius: ${props => props.isNotice ? '104px' : 'none'};
    width: 100%;
    height: ${props => props.isNotice ? '247px' : '100%'};
    display: flex;
    justify-content: center;
    padding: ${props => props.isNotice ? '0 0 0 90px' : '0'};

    flex-direction: column;
    box-sizing: content-box;

    .title {
        font-size: clamp(18px, 2.5vw, 20px);
        font-weight: 700;
        color: #006DFF;


    }

    .content {
        font-size: clamp(18px, 2vw, 20px);
        color: #1E222D;
        line-height: 1.5;
    }

    //@media (max-width: 768px) {
    //    height: auto;
    //    min-height: 100px;
    //    padding: 10%;
    //    border-radius: 54px;
    //}

`


const SpecContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    max-width: 312px;
    min-width: 196px;
    height: 44px;
    background-color: #006DFF;
    color: #fff;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;

    @media (max-width: 768px) {
        height: 56px;
        border-radius: 28px;
        width: 200px;
`


//
// const DetailContainer = styled.div`
//
//     .title {
//         font-size: 20px;
//         font-weight: 600;
//         color: #006DFF;
//     }
//     .content {
//         font-size: 20px;
//         color: #1E222D;
//         line-height: 1.8;
//     }
//     .notice{
//         font-size: 15px;
//         color: #006DFF;
//         font-weight: 600;
//         white-space: pre-line;
//     }
//
//     `
//
// const EaContainer = styled.div`
//     border-radius: 50%;
//     background-color: #EDF5FF;
//     color: #006DFF;
//     width: 66px;
//     height: 66px;
//     box-sizing: border-box;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-size: 30px;
//     position: absolute; /* 절대 위치 설정 */
//     top: -200px; /* 이미지 상단에 위치하도록 조정 */
//     left: 80px;
//     transform: translateX(-0%); /* X축 중앙 정렬을 위한 변환 */
// `;
