import React, {useEffect} from 'react';
import {MainContainer} from "../../../styles/Styles";
import ProductTypeSlideComp from "../../../components/slides/ProductTypeSlideComp";
import ProductSwiperContainer from "../../../components/swipers/ProductSwiperContainer";
import {useMediaQuery} from "react-responsive";
import Aos from "aos";
import {useSpecArray} from "../../../common/Functions";

interface Props {
    productList?: any;
    renderType?: string;

}

const ProductIInfoSection:React.FC<Props> = ({productList,renderType}) => {
    const isMobile = useMediaQuery({
        query: "(max-width:820px)"
    });
    useEffect(() => {
            Aos.init({duration: 3000
            });
        }
        ,[])

    const controllerRef = React.useRef<HTMLDivElement>(null);
    const deviceRef = React.useRef<HTMLDivElement>(null);


    useEffect(() => {
        if (renderType  ==='controller'  && controllerRef.current) {
            controllerRef.current.scrollIntoView({ behavior: 'auto' });
        } else if (renderType === 'device' && deviceRef.current) {
            deviceRef.current.scrollIntoView({ behavior: 'auto' });
        }
    }, [])

    const renderController= () => {
        return (
            <div data-aos={'fade-up'}
                 style={{
                     display: 'flex',
                     width: '100%',
                     height: '100vh',
                     justifyContent: 'center',
                     flexDirection: 'column',
                     gap: isMobile ? '20px' : '40px',
                 }}>
                <div style={{
                    padding: isMobile ? '0 6%' : '0 20%', fontWeight: 'bold',
                    fontSize: 'clamp(18px, 5vw, 24px)',
                    color: '#006DFF'
                }}>
                    {'프레스 컨트롤러'}
                </div>
                <ProductSwiperContainer categoryName={'프레스 컨트롤러'} key={'프레스 컨트롤러'}/>
            </div>

        )
    }

    const renderDevice = () => {
        return (
            <div data-aos={'fade-up'}
                 ref={deviceRef}
                 style={{
                     display: 'flex',
                     width: '100%',
                     justifyContent: 'center',
                     flexDirection: 'column',
                     gap: isMobile ? '20px' : '40px',
                     height:'100vh'
                 }}>
                <div style={{
                    padding: isMobile ? '0 6%' : '0 20%', fontWeight: 'bold',
                    fontSize: 'clamp(18px, 5vw, 24px)',
                    color: '#006DFF'
                }}>
                    {'프레스 주변장치'}
                </div>
                <ProductSwiperContainer categoryName={'프레스 주변장치'} key={'프레스 주변장치'}/>
            </div>
        )
    }


    return (
        <MainContainer justifyContent={'center'} height={'100%'} flexDirection={'column'}
                       padding={isMobile?'0px':'100px 0'}>
            <div style={{maxWidth: '1920px', alignSelf: 'center', width: '100%', overflow: 'hidden'}}>
                {renderType === 'all' ?
                      <>
                        {renderController()}
                        {renderDevice()}
                      </>
                    :
                    renderType === 'controller' ?
                        <div ref={controllerRef} style={{display:'flex',flexDirection:'column',gap:isMobile?'20px':'40px'}}>
                            {renderController()}
                            {renderDevice()}
                        </div>
                        :
                        <div style={{display:'flex',flexDirection:'column',gap:isMobile?'20px':'40px'}}>
                            {renderController()}
                            {renderDevice()}
                        </div>
                }



            </div>
        </MainContainer>
);
};

export default ProductIInfoSection;
