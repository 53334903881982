import styled from "styled-components";
import {ContainerProps} from "../types/Fundamental";
import React from "react";
import {useMediaQuery} from "react-responsive";
import bg from "../asset/images/bg/스마트솔루션_상단_BG.jpeg";




export const ContentFrame = styled.div<ContainerProps>`
    width: ${props => props.width ?? '100%'};
    height: ${props => props.height ?? '100%'};
    justify-content: ${props => props.justifyContent};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding:${props => props.padding};
   overflow: hidden;
    background-color: #000000;
 
  
 
`

export const MainContainer = styled.div<ContainerProps>`
  background-color: ${props => props.backgroundColor ?? '#000000'};
  display: flex;
  min-width: 280px;
  width: ${props => props.width??'100%'};
  background : ${props => props.backgroundImage};
  height: ${props => props.height ?? '100%'};
  justify-content: ${props => props.justifyContent??'center'};
  padding:${props => props.padding};
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.flexDirection ?? 'column'};
  overflow: ${props => props.overflow ?? 'hidden'};
  box-sizing: border-box;
 
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  
 

.hero_main{
    //max-width: 1920px;
    //min-width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    box-sizing: border-box;
    

}

.hero_sub{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.hero_text{
    color:#fff;
    padding: 0;
    //width: 100%;
    white-space: pre-line;
    //z-index: 100;
}

 
  
  .menu_title {
    padding: 0 16%;
    font-weight: bold;
    font-size: 24px;
  }
  
  .picture {
    background-image: url(${props => props.backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    align-items: center;
    display: flex;
  }

`
export const createHeroMainText = (text:string,isDesktop:any) => {
    return  (
    <div className={'hero_text'}
             style={{
                 display: '-webkit-box',
                 WebkitLineClamp: 3,
                 WebkitBoxOrient: 'horizontal',
                 fontSize:isDesktop?'64px':'32px',
                 maxWidth: isDesktop?'600px':'280px',
                 fontWeight: 'bold',
                 overflow:'visible',
                 whiteSpace: 'normal',
                 letterSpacing:' 0.0em',
                }}>

                {text}


    </div>
    )
}

export const createHeroSubText = (text:string,isDesktop:any) => {
    return  (
        <div className={'hero_text'}
             style={{
                 fontWeight: 'normal',
                 zIndex:'1',
                 textAlign:'left',
                 maxWidth: isDesktop?'100%':'90%',
                 fontSize: isDesktop?'32px':'18px',
                 margin: '38px 0 100px 0',
             }}>
                {text}
        </div>
    )

}

export const createInNumber = (title:string,content:any) => {
 return (
    <div style={{display:'flex',flexDirection:'column',gap:'40px'}}>
        <div style={{fontSize:'32px',fontWeight:'bold',color:'#EDF5FF'}}>
            {title}
        </div>
        <div style={{fontSize:'80px',fontWeight:'bold',color:'#EDF5FF'}}>
            {content}
        </div>
    </div>
)}





export const SwiperContainer = styled.div<ContainerProps>`
   

  .swiper-container{
    width: 100%;
    //height: 600px;
    justify-self: center;
    align-self: center;
    //max-height: 100%;
  }
  
    .swiper-wrapper{
        height: 700px;
        
        @media (min-width: 769px){
            height: 620px;
        }
        
        @media (max-width: 768px) {
            height: 500px;
        }
    }
 
   .swiper-container-viewmore{
    width: 100%;
    max-width: 1600px;
    max-height: 100%;
    justify-self: center;
    align-self: center;
  }
  
  .swiper-container-years{
    height: 48px;
  }
  
  .swiper-container-buttons{
    height: 65px;
  }
  
  .swiper-container-certification{
    align-self: center;
    height: 500px;
    display: flex;
    justify-content: center;  
    align-items: center;
  }
  .swiper-container-pr {
      width: 1920px;
      max-width: 100%;
      max-height: 100%;
      justify-self: center;
      align-self: center;
     .swiper-wrapper{
        height: 500px;
         
     }
      .swiper-slide{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .swiper-slide-active{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }
    
    
  .custom-next{
    cursor: pointer;
    width: 48px;
    height: 48px;
  }
  
  .custom-prev{
    cursor: pointer;
    width: 48px;
    height: 48px;
    transform: rotate(180deg);  
  }
  
  .swiper-pagination-progressbar{
    background-color: rgba(30, 34, 45, 0.2);
    height: 14px;
    border-radius: 10px;
    position: absolute;
    top: 420px;
    margin-top: 40px;
  }
  .swiper-pagination-progressbar-fill{
    background-color: rgba(30, 34, 45, 0.5);
    height: 14px;
    border-radius: 10px;
  }

 
  .swiper-slide{
    width: 100% ;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide-active{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
  }
  .swiper-slide-inactive{
   
    opacity: 0.6;
    transition: all 0.3s ease;
    transform: scale(0.9);
    
  }
    
   .swiper-slide-certification{
  
  }
  .swiper-slide-years{
    width: 168px;
  height: 48px;
  }
  .swiper-slide-buttons{
   
    height: 65px;
  }
  .swiper-slide-buttons-active{
    height: 65px;
    
  }
  .swiper-slide-pr {
    width: 100%;
      height: auto;
      align-items: baseline;
     justify-content: center;
  }
    .swiper-slide-pr-active {
       width: 400px;
        justify-content: center;
    }
    
  .swiper-slide-mobile{
    width: 400px;
      
    justify-content: center;
  }
  .swiper-slide-mobile-active{
    width: 400px;
    justify-content: center;
    
   
    .swiper-pagination {
        bottom: 0;
    }
       
    }

  .custom-bullet{
    background-color: #fff;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display:  inline-block;
    margin: 0 8px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    cursor: pointer;
    box-sizing: border-box;
  }
      
    .custom-bullet-active{
    background-color: #006DFF;
    opacity: 1;
    }
      
  .custom-bullet-news{
    background-color: #ccc;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display:  inline-block;
    margin: 0 8px;
    opacity: 0.5;
    z-index: 100;
    transition: opacity 0.3s ease;
    cursor: pointer;
    box-sizing: border-box;
  }


  ///* 슬라이드 활성화 상태 설정 */
  //.active-slide {
  //  opacity: 1;
  //}
  //
  ///* 슬라이드 비활성화 상태 설정 */
  //.inactive-slide {
  //  opacity: 0.5;
  //}
 

`

export const NoImage = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  
`

