import React, {useEffect, useState} from 'react';
// @ts-ignore
import { Swiper, SwiperSlide, SwiperOptions} from  'swiper/react';
import 'swiper/swiper-bundle.css';
import {Navigation, Pagination, Scrollbar, FreeMode, Autoplay} from 'swiper/modules';
import ProductSlider from "../slides/ProductSlideComp";
import {productSliderList, specArray} from "../../common/DataSet";
import ProductSlideComp from "../slides/ProductSlideComp";
import {SwiperContainer} from "../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import {useTranslation} from "react-i18next";


interface Props {
   categoryName?: any;
}

const ProductSwiperContainer:React.FC<Props> = ({categoryName}) => {
    const {t}:any =useTranslation();
    const swiperRef = React.useRef(null);
    const [activeIndex, setActiveIndex] = useState(0)

    useEffect(() => {
        if(swiperRef.current){
            // @ts-ignore
            swiperRef.current.swiper.on('slideChange', function () {
                // @ts-ignore
                setActiveIndex(swiperRef.current.swiper.realIndex)
            });
        }
    }, []);

    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:416px)"
    })

    // const isTablet = useMediaQuery({
    //     query: "(max-width: 768px)",
    // });



    const swiperParams: SwiperOptions = {
        modules: [Navigation, Pagination, Scrollbar, FreeMode,Autoplay],
        on: {
            slideChange: function () {
                setActiveIndex(activeIndex)
            }
        },
                initialSlide: 0,
                // slidesPerView:2,
                spaceBetween: 50,
                centeredSlides: true,
                slideToClickedSlide: true,
                speed: 500,
                loop: true,
                autoplay: {
                    delay: 2000,
                },
                pagination: {
                    bulletClass: 'custom-bullet',
                    bulletActiveClass: 'custom-bullet-active',
                    clickable: true,
                    type: 'bullets',
                },
                slideActiveClass: 'swiper-slide-active',
                slideClass: 'swiper-slide',
        breakpoints: {
            280: {
                slidesPerView: 1,
                spaceBetween: 0,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },

            320: {
                slidesPerView: 2,
                spaceBetween: 230,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            375: {
                slidesPerView: 2,
                spaceBetween: 180,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            425: {
                slidesPerView: 2,
                spaceBetween: 120,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            430: {
                slidesPerView: 2,
                spaceBetween: 100,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            600: {
                slidesPerView: 3,
                spaceBetween: 200,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 100,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            800: {
                slidesPerView: 4,
                spaceBetween: 250,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },

            1024: {
                slidesPerView: 4,
                spaceBetween: 50,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            1025: {
                slidesPerView: 2,
                spaceBetween: 420,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            1152: {
                slidesPerView: 2,
                spaceBetween: 280,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            1200: {
                slidesPerView: 2,
                spaceBetween: 200,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },

            1440: {
                slidesPerView: 2,
                spaceBetween: 100,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            1441: {
                slidesPerView: 2,
                spaceBetween: 600,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            1600: {
                slidesPerView: 2,
                spaceBetween: 300,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            1880: {
                slidesPerView: 2,
                spaceBetween: 200,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            1920: {
                slidesPerView: 2,
                spaceBetween: 200,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            2560: {
                slidesPerView: 2,
                spaceBetween: 150,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            },
            2561: {
                slidesPerView: 1,
                spaceBetween: 10,
                centeredSlides: true,
                initialSlide: 0,
                slideToClickedSlide: true,
            }
        },
    };


    const categoryToDisplay = categoryName || '전체';

    return (

        <SwiperContainer>
            {isDesktop?
            <Swiper ref={swiperRef}
                    className={'swiper-container'} style={{width:'100%',maxWidth:'1920px',overflow:'visible'}}
                    {...swiperParams}>
                {specArray
                    .filter(item => categoryToDisplay === '전체' || item.category === categoryToDisplay)
                    .map((item, index) => (
                            <SwiperSlide key={index} className={'swiper-slide'}>
                                <div
                                    className={`swiper-slide-active ${activeIndex === index ? 'swiper-slide-active' : 'swiper-slide-inactive'}`}>
                                    <ProductSlideComp
                                        clickEvent={() => window.location.href = `/product/${item.productName}`}
                                        // clickEvent={()=>{window.location.href = item.link}}
                                        compType={'desktop'} title={t(item.productName)} sub={t(item.info)}
                                        productType={t(item.category)} image={item.mainImg}/>
                                </div>
                            </SwiperSlide>
                        )
                    )
                }

            </Swiper>
                :
                <Swiper ref={swiperRef}
                        className={'swiper-container-mobile'}
                        {...swiperParams}>
                    {specArray
                        .filter(item => categoryToDisplay === '전체' || item.category === categoryToDisplay)
                        .map((item, index) =>

                            <SwiperSlide key={index} className={'swiper-slide'}>
                                <div className={`swiper-slide-active ${activeIndex === index ? 'swiper-slide-active' : 'swiper-slide-inactive'}`}>
                                    <ProductSlideComp clickEvent={()=>window.location.href = `/product/${item.productName}`}
                                        // clickEvent={()=>{window.location.href = item.link}}
                                        compType={'mobile'} title={t(item.productName)} sub={t(item.info)} productType={t(item.category)} image={item.mainImg}/>
                            </div>
                            </SwiperSlide>
                        )
                    }

                </Swiper>
            }


        </SwiperContainer>
    );
};

export default ProductSwiperContainer;
