import React from 'react';
import data from '../asset/images/icons/ic_data.png';
import module from '../asset/images/icons/ic_module.png';
import low from '../asset/images/icons/ic_low.png';
import notice from '../asset/images/icons/ic_notice.svg';

const Icons =  {
    data: data,
    module: module,
    low: low,
    notice: notice
}
export default Icons;
