import React, { useState } from 'react';
import styled from "styled-components";
import ArrowButton from "../buttons/ArrowButton";
import left from '../../asset/images/icons/chevron-left.svg'
import {menuArr} from "../../common/DataSet";
import logo from '../../asset/images/logo_white.svg'
import Notiflix from "notiflix";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "../header/LanguageSwitcher";

type HamburgerStyle = {
    active: boolean
    clickEvent: ()=>void
}
interface MenuProps {
    menu?:string
    sub?:string
    buttonContent?:any
    clickEvent?:()=>void
    openModal?:()=>void
}

const MobileMenuComponent: React.FC<MenuProps> = ({menu,sub,buttonContent,clickEvent,openModal}) => {
    const{t}:any = useTranslation()
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectMainMenu, setSelectMainMenu] = useState<string>('')
    const location = useLocation()
    const [selectSubMenu, setSelectSubMenu] = useState<{title:any,length:any}>({title:'', length:0})
    const tempValidate = (link: string,menu?:string) => {
        if (link === '') {
            Notiflix.Report.info('준비중...', '', 'OK');
        }else if(menu ==='IR'){
            openModal!()
        } else {
            window.location.href = link;
        }
    }
    const pageBackground = location.pathname.includes('product') ? '#000' : 'transparent linear-gradient(180deg, #00000086 0%, #00000046 56%, #00000000 89%, #00000000 100%) 0% 0% no-repeat padding-box';
    // @ts-ignore
    return (
        <MobileSideComp style={{background:pageBackground}}>
            <div>
                <div className={'header_menu'}>
                  <img style={{width:'80px',height:'28.47px'}} src={logo} alt="logo"  onClick={()=>window.location.href='/'} />

                   <div style={{marginRight:'40px'}}>
                    <LanguageSwitcher/>
                   </div>

                    <Hamburger onClick={()=>setMenuOpen(!menuOpen)} style={{display:!menuOpen?'block':'none'}}>
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </Hamburger>
                </div>
                {sub ?
                    <div style={{fontWeight:700, fontSize:'14px',height:'56px',padding:'0 24px',background:'transparent',
                        display:'flex',alignItems:'center',justifyContent:'center',position:"relative"}}>
                           <img src={left} style={{position:'absolute',left:'24px'}} onClick={clickEvent}/>
                             <div>{sub}</div>
                             {buttonContent && buttonContent}
                    </div>
                    :
                    null
                }
            </div>
            <MobileMenu isOpened={menuOpen}>
                <div onTouchMove={()=>setMenuOpen(false)}
                     style={{display:'flex',
                         minHeight:'56px', width:'inherit',
                         justifyContent:'space-between', alignItems:'center'}}>
                    <XmarkButton onClick={()=>setMenuOpen(!menuOpen)}>
                        <div/>
                        <div/>
                    </XmarkButton>
                </div>
                <div className={'menu'} onChange={(e)=>e.preventDefault()}>
                    {menuArr.map((v)=>(
                        <div style={{backgroundColor:selectMainMenu === v.title? '#1f232e' : '#1f232e'}}>
                            <MainMenu className={'main'} disabled={!v.link?.length}
                                          onMouseDown={()=> {
                                              setSelectMainMenu( selectMainMenu === v.title ? '' : v.title )
                                              setSelectSubMenu(selectMainMenu === v.title? selectSubMenu : {title:'', length:0})
                                          }}>
                                <div onClick={() => {
                                    tempValidate(v.link, v.title)
                                }}>
                                    {t(v.title)}</div>
                                {v.subMenu?.length &&
                                 <div style={{position:'absolute',right:'24px'}}>
                                    <ArrowButton arrowDirection={selectMainMenu === v.title? 'top' : 'bottom'}/>
                                </div>}
                            </MainMenu>
                            <div style={{transition:'all 0.3s', height: 'auto', maxHeight: selectMainMenu === v.title? '1000px' : '0px',
                                overflow:'hidden'}}>
                                {v.subMenu?.map((sub)=>(
                                    <div style={{backgroundColor:selectSubMenu.title === sub.menu? 'rgba(0,88,255,0.1)' : '#1f232e'}}>
                                        <SubMenuTitle className={'subMenu-title'}
                                                      onMouseDown={()=>setSelectSubMenu(selectSubMenu.title === sub.menu ? {title:'', length:0} : {title:sub.menu, length:sub.menu?.length})}>
                                            <div onClick={()=>tempValidate(sub.link,sub.menu) }
                                                style={{
                                                textDecoration:selectSubMenu.title === sub.menu? 'underline' : 'none',
                                                textUnderlineOffset:'3px'}}>
                                                {t(sub.menu)}</div>
                                        </SubMenuTitle>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </MobileMenu>
        </MobileSideComp>
    );
};

export default MobileMenuComponent;

const MobileSideComp = styled.div`
  position: absolute;
  user-select: none;
  z-index: 100;
  width: 100%;
  background: transparent linear-gradient(180deg, #00000086 0%, #00000046 56%, #00000000 89%, #00000000 100%) 0% 0% no-repeat padding-box;

  .header_menu {
    display: flex;
    align-items: center;
    height: 64px;
    box-sizing: border-box;
    width: 100%;
    padding: 0 24px;
    position: relative;
      justify-content: space-between;
 
   
   
    //
  }
`


const MobileMenu = styled.div<{isOpened?: boolean}>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 3px 6px #00000029;
  align-items: center;
  align-self: center;
  box-sizing: border-box;
 
  //height: calc(100vh - 64px);
  //  background-color: #1E222D;
  background: transparent linear-gradient(180deg, #00000086 0%, #00000046 56%, #00000000 89%, #00000000 100%) 0% 0% no-repeat padding-box;
    transform: translateY(${props => props.isOpened ? '0' : '-100%'});
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
  z-index: 100;
  white-space: nowrap;

    min-width: ${props => props.isOpened ? 'calc(40vw - 20px)' : '0'};


    @media screen and (max-width: 717px) and (max-height: 512px) {
        max-width: 60vw;
        max-height: 512px;
        overflow: scroll;
    }
     
    

    @media (max-width: 430px) {
        min-width: ${props => props.isOpened ? '300px' : '0'};  
    }

  .menu {
    overflow-y: scroll;
    width: inherit;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
 ;
    border-radius: 16px 16px 0 0;

    .main{
      background-color: #000;
      color: #fff;
      
      &:hover {
        background-color:rgba(0, 109, 255, 1);
        color: #fff;
      }
    }

    .subMenu-title {
      justify-content: center;
      font-weight: normal;
      font-size: 18px;
      color: #fff;

      &:hover {
        background-color: rgba(0, 109, 255, 0.5);
        color: #fff;
      }
    }

    //.detailMenu-title {
    //  &:hover {
    //    background-color: rgba(0, 88, 255, 0.1);
    //    color: #0058ff;
    //  }
    //}

  }


`

const MainMenu = styled.div<{disabled?: boolean}>`
  display: flex;
  justify-content: center;
  height: 64px;
  align-items: center;
  font-size: 20px;
  box-sizing: border-box;
  padding: 0 20px;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  user-select: none;
  overflow: hidden;
  //border-bottom: 1px solid #d5d7e3;
  opacity: ${props => props.disabled? 0.3 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};

  &::before{
    content:'';
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    width: calc(100% - 8px);
    //background-color: #1E222D;
  }
`
const SubMenuTitle = styled.div`
  display: flex;
  height: 64px;
  box-sizing: border-box;
  padding: 0 48px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`


const Hamburger = styled.div<{isOpened?:boolean}>`
  display: block;
  cursor: pointer;
  position: absolute;
   top: 10px;
  right: 24px;
  width: 25px;
  height: 36px;
    
    @media (max-width: 375px) {
        right: 5%;
    }

  div {
    width: 100%;
    max-width: 18px;
    padding: 1px;
    border-radius: 15px;
    background-color: #fff;
    position: absolute;
    transition: all 0.3s;

    &:nth-child(1) {
      top: 13px;
      transform: scale(100%);
    }

    &:nth-child(2) {
      top: 20px;
      transform: rotate(0deg);
    }

    &:nth-child(3) {
      top: 20px;
      transform: rotate(0deg);
    }

    &:nth-child(4) {
      top: 27px;
      transform: scale(100%);
    }
  }
`


const XmarkButton = styled.div`
  display: block;
  cursor: pointer;
  position:  absolute;
  right: 0;
  min-width: 25px;
  box-sizing: border-box;
  padding: 0 36px 0 8px;
  div {
    width: 100%;
    max-width: 18px;
    padding: 1px;
    border-radius: 15px;
    background-color: #949494;
    transition: all 0.3s;
    position: absolute;
    &:nth-child(1) {

      transform: rotate(45deg);
    }
    &:nth-child(2) {

      transform: rotate(-45deg);
    }
  }
`
