import React from 'react';
import {BrowserRouter, Routes, Route, useParams, Navigate} from "react-router-dom";
import MainPage from "../pages/MainPage";
import MainPageScrollSection from "../pages/MainPageScrollSection";
import AboutUsPage from "../pages/AboutUsPage";
import ProductPage from "../pages/ProductPage";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SmartSolutionPage from "../pages/SmartSolutionPage";
import CustomerServicePage from "../pages/CustomerServicePage";

const Router = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route element={<MainPage/>} path={"/main"}/>
                <Route element={<AboutUsPage/>} path={"/about"}/>
                <Route element={<AboutUsPage/>} path={"/about/address"}/>

                <Route element={<ProductPage/>} path={"/product"}/>
                <Route element={<ProductPage/>} path={"/product/controller"}/>
                <Route element={<ProductPage/>} path={"/product/device"}/>
                <Route element={<ProductPage/>} path={"/product/:id"}/>
                <Route element={<SmartSolutionPage/>} path={"/solution"}/>
                <Route element={<SmartSolutionPage/>} path={"/solution/:id"}/>
                <Route element={<CustomerServicePage/>} path={"/cs"}/>
                {/*<Route element={<MainPageScrollSection/>} path={"/test"}/>*/}
                <Route path={'/'} element={<Navigate replace to={'/main'}/>}/>
                <Route path={'/*'} element={<Navigate replace to={'/main'}/>}/>
            </Routes>

        </BrowserRouter>
    );
};

export default Router;
