import React from 'react';
import icon from '../../asset/images/icons/chatIcon.svg';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const CSchat = () => {
    const location = window.location.pathname;
    const cs = location.includes('cs');
    const {t}:any = useTranslation();

    if(cs){
        return null;
    }

    return (
        <IconComp content={t('문의하기')}>
            <div>
            <img src={icon} alt="chat icon"  style={{cursor:'pointer'}} onClick={() => {
                window.location.href = '/cs'
            }}/>
            </div>
        </IconComp>
    );
};

export default CSchat;

const IconComp = styled.div<{content: string}>`
    position: fixed;
    bottom: 40px;
    right: 20%;
    z-index: 1000;
    transition: all 0.2s ease-in-out;
    
    @media (max-width: 768px) {
        right: 20px;
    }
  
    & > div {
        animation: bounce 2s infinite;
        transition: all 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
  
    &:hover {
        & > div {
            animation: none;
          transform: translateY(-10px);
          transition: all 0.3s ease-in-out;
        }
      &::after {
        content: '${props => props.content}';
        position: absolute;
        bottom: -10px;
        left: 15px;
        color: #fff;
        font-weight: bold;
        font-size: 12px;
        text-decoration: underline;
        text-shadow: 0 0 10px #111;
        transition: all 0.2s ease-in-out;
      
        
      }
    }
    
   
  
    @keyframes bounce {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-20px);
        }
        100% {
            transform: translateY(0);
        }
    }
`;
