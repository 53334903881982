import React from 'react';
import {ProductType} from "../../../types/Fundamental";
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";


interface Props {
    productList?: ProductType |any;
}
const PressureSpecSection:React.FC<Props> = ({productList}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1441px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    return (
        <MainContainer
            style={{
                background: '#EDF5FF',
                padding: isDesktop ? '100px 20% 100px 20%' : '80px 8%',
                flexDirection: 'column',
                justifyContent: 'center',

                height: isDesktop ? '100%' : '100%'
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {productList?.productSpec.map((v: any, index: number) =>
                        <div style={{
                            display: 'flex', gap: isMobile ? '20px' : '46px',
                            marginTop: isMobile ? '80px' : '120px', width: '100%',
                            flexDirection: isDesktop ? 'row' : 'column', alignItems: isMobile ? 'center' : 'baseline'
                        }}>
                            <SpecContainer>
                                {v.specName}
                            </SpecContainer>
                            <div style={{
                                fontSize: 'clamp(14px, 2.5vw, 20px)',
                                color: '#1E222D', whiteSpace: 'pre-line', lineHeight: '1.8'
                            }}>
                                {v.value}
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </MainContainer>
);
};

export default PressureSpecSection;

const TitleContainer = styled.div`
display: flex;
box-sizing: border-box;
background-color: #484848;
color: #fff;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 71px;
    max-width: 976px;
    min-width: 200px;
    font-weight: 700;
    font-size: 25px;
    `

const FlowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    max-width: 154px;
    min-width: 154px;
    background-color:#fff;
    border:1px solid #006DFF;
    color: #006DFF;
    font-size:20px;
    z-index: 1;
    `

const SpecContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width:196px;
    max-width: 100%;
    min-width:196px;
    height:44px;
    background-color:#006DFF;
    color : #fff;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;

    @media (max-width: 768px) {
        height: 56px;
        border-radius: 28px;
        width: 312px;
`
