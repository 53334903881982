import React, {useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import Aos from "aos";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";

const SolutionTableSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:430px)"
    });

    useEffect(() => {
            Aos.init({
                duration: 1000
            });
        }
        , [])


    // const titleArr =['종목,항목','MOQ(개,대)','금액','비고']

    const tableArr = [
        {
            title: '데이터 수집',
            header: ['종목', '항목', 'MOQ(개,대)', '금액', '비고'],
            content: [
                {category: '클라우드형', name: '프레스', moq: '1', price: '150,000/월', note: '무선형'},
                {category: '클라우드형', name: 'CNC', moq: '1', price: '150,000/월', note: '무선형'},
                {category: '클라우드형', name: 'Robot', moq: '1', price: '150,000/월', note: '무선형'},
                {category: '클라우드형', name: 'Automation', moq: '1', price: '150,000/월', note: '무선형'},
                {category: '클라우드형', name: '한전 API', moq: '1', price: '150,000/월', note: '무선형'},
                {category: '클라우드형', name: '프레스', moq: '1', price: '100,000/월', note: '유선형'},
                {category: '클라우드형', name: 'CNC', moq: '1', price: '100,000/월', note: '유선형'},
                {category: '클라우드형', name: 'Robot', moq: '1', price: '100,000/월', note: '유선형'},
                {category: '클라우드형', name: 'Automation', moq: '1', price: '100,000/월', note: '유선형'},
                {category: '클라우드형', name: '한전 API', moq: '1', price: '100,000/월', note: '유선형'},
                {category: '구입형', name: '프레스', moq: '1', price: '견적문의', note: '무선형/유선형'},
                {category: '구입형', name: 'CNC', moq: '1', price: '견적문의', note: '무선형/유선형'},
                {category: '구입형', name: 'Robot', moq: '1', price: '견적문의', note: '무선형/유선형'},
                {category: '구입형', name: 'Automation', moq: '1', price: '견적문의', note: '무선형/유선형'},
                {category: '구입형', name: '한전 API', moq: '1', price: '견적문의', note: '무선형/유선형'},
            ]
        },
        {
            title: 'FAS',
            header: ['종목', '항목', 'MOQ(개,대)', '금액'],
            content: [
                {category: '클라우드형', name: 'MES', moq: '1', price: '300,000/월', note: ''},
                {category: '클라우드형', name: 'FEMS', moq: '1', price: '300,000/월', note: ''},
                {category: '클라우드형', name: 'P-BAS', moq: '1', price: '100,000/월', note: ''},
                {category: '클라우드형', name: 'P-MS', moq: '1', price: '100,000/월', note: ''},
                {category: '클라우드형', name: 'C-MS', moq: '1', price: '100,000/월', note: ''},
                {category: '클라우드형', name: 'C-BAS', moq: '1', price: '100,000/월', note: ''},
                {category: '클라우드형', name: 'A-MS', moq: '1', price: '100,000/월', note: ''},
                {category: '클라우드형', name: 'A-BAS', moq: '1', price: '100,000/월', note: ''},
                {category: '클라우드형', name: 'R-MS', moq: '1', price: '100,000/월', note: ''},
                {category: '클라우드형', name: 'R-BAS', moq: '1', price: '100,000/월', note: ''},
                {category: '구입형', name: 'MES', moq: '1', price: '가격문의', note: ''},
                {category: '구입형', name: 'FEMS', moq: '1', price: '가격문의', note: ''},
                {category: '구입형', name: 'P-BAS', moq: '1', price: '가격문의', note: ''},
                {category: '구입형', name: 'P-MS', moq: '1', price: '가격문의', note: ''},
                {category: '구입형', name: 'C-MS', moq: '1', price: '가격문의', note: ''},
                {category: '구입형', name: 'C-BAS', moq: '1', price: '가격문의', note: ''},
                {category: '구입형', name: 'A-MS', moq: '1', price: '가격문의', note: ''},
                {category: '구입형', name: 'A-BAS', moq: '1', price: '가격문의', note: ''},
                {category: '구입형', name: 'R-MS', moq: '1', price: '가격문의', note: ''},
                {category: '구입형', name: 'R-BAS', moq: '1', price: '가격문의', note: ''},
                {category: '클라우드형-API', name: 'Callback', moq: '1', price: '가격문의', note: ''},
                {category: '클라우드형-API', name: 'Back-up', moq: '1', price: '가격문의', note: ''},
            ]
        }
    ]


    return (
        <MainContainer
            style={{
                background: '#EDF5FF',
                padding: isDesktop ? '200px 16% 100px 16%' : '100px 5%',
                flexDirection: 'column',
                justifyContent: isDesktop ? 'flex-start' : 'center',
                height: isDesktop ? '100%' : isMobile ? '100%' : '100%',
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>

                <TableContainer data-aos={!isDesktop ? '' : 'fade-up'}>
                    {tableArr.map((item, i) =>
                        <div style={{
                            display: 'grid',
                            gap: !isDesktop ? '40px' : isMobile ? '20px' : '80px',
                            gridTemplateColumns: !isDesktop ? '1fr' : '120px 1fr'
                        }}>
                            <div className={'title'}>
                                {item.title}
                            </div>
                            <div className={'scroll'} style={{width: '90vw', overflowX: 'auto'}}>
                                <div style={{
                                    display: 'flex', flexDirection: 'column',
                                    maxWidth: '900px',
                                    minWidth: item.title === 'FAS' ? '380px' : '550px'
                                }}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <div style={{display: 'flex'}}>
                                            {item.header.map((v, i) =>
                                                <div className={'header'}>
                                                    {v}
                                                </div>
                                            )}
                                        </div>

                                        {item.content.map((v, i) =>
                                            <div style={{display: 'flex'}}>
                                                <div
                                                    className={'category' + (v.category === '클라우드형-API' ? ' api' : '')}>
                                                    {v.category}
                                                </div>
                                                <div className={'name'}>
                                                    {v.name}
                                                </div>
                                                <div className={'content'}>
                                                    {v.moq}
                                                </div>
                                                <div className={'content'}>
                                                    {v.price}
                                                </div>
                                                {v.note &&
                                                    <div className={'content'}>
                                                        {v.note}
                                                    </div>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}

                </TableContainer>
            </div>
        </MainContainer>
);
}


export default SolutionTableSection;

const TableContainer = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
box-sizing: border-box;
align-items: flex-start;
    gap: 80px;
    width: 100%;
   


    @media (max-width: 600px) {
        .scroll {
            overflow-x: auto;
            min-width: 300px;
            width: 100%;

            &::-webkit-scrollbar {
                display: block;
                height: 8px; // 수직 스크롤바 높이 설정
            }

            &::-webkit-scrollbar-thumb {
                background-color: #D5D7E3; // 스크롤바 색상 설정
                border-radius: 4px; // 스크롤바 모서리 둥글게 설정
            }
            
            &::-webkit-scrollbar-track {
                background-color: #fff;
            }

        }
    }

    .title {
        font-weight: bold;
        font-size: clamp(18px, 2.5vw, 24px);
        color: #006DFF;
        white-space: nowrap;
    }

    .header {
        height: 32px;
        width: 100px;
        padding: 0 16px;
        white-space: nowrap;
        color: #fff;
        display: flex;
        align-items: center;
        background-color: #0095ff;
        font-size: clamp(12px, 3vw, 14px);
        font-weight: 600;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;

        @media (max-width: 768px) {
            width: 80px;
        }
        //@media(max-width: 430px){
        //    width:40px;
        //}
    }

    .category {
        height: 32px;
        width: 100px;
        padding: 0 16px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        color: #006DFF;
        background-color: #006DFF33;
        font-size: clamp(12px, 3vw, 14px);
        font-weight: 600;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        
        @media (max-width: 768px) {
            width: 80px;

            &.api {
                white-space: normal;
                text-align: left;
                /* Adjust line height as needed */
                line-height: 1.0;
            }
        }
        //@media(max-width: 430px){
        //    width:40px;
        //}
      
    }

    .name {
        height: 32px;
        color: #000;
        width: 100px;
        padding: 0 16px;
        background-color: #f2f2f2;
        font-size: clamp(12px, 3vw, 14px);
        font-weight: 500;
        white-space: nowrap;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        @media (max-width: 768px) {
            width: 80px;
        }
        //@media(max-width: 430px){
        //    width:40px;
        //}
    }

    .content {
        background-color: #fff;
        width: 100px;
        padding: 0 16px;
        color: #000;
        font-size: clamp(12px, 3vw, 14px);
        white-space: nowrap;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
        @media (max-width: 768px) {
            width: 80px;
        }
        //@media(max-width: 430px){
        //    width:40px;
        //}
    }
`;
