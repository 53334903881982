import React from 'react';
import {MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import CertificationComp from "../../../components/certification/CertificationComp";
import CertificationSwiperContainer from "../../../components/swipers/CertificationSwiperContainer";
import Aos from "aos";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentPageScroll, prevContainerScrollHeight, scrollPageBlock} from "../../../common/RecoilState";



const CertificationSection = () => {

  const containerRef = React.useRef<any>(null)
  const [scrollValue, setScrollValue] = React.useState(0)
  const currentPage = useRecoilValue(currentPageScroll)
  const [pageScroll, setPageScroll] = useRecoilState(scrollPageBlock)
  const [prevScroll, setPrevScroll] = useRecoilState(prevContainerScrollHeight)

    const isDesktop = useMediaQuery({
        query: "(min-width: 769px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    React.useEffect(() => {
            Aos.init();

        }
        ,[])

  // React.useEffect(()=>{
  //   if(scrollValue === 0 && currentPage === 5){
  //     setPageScroll({up: false, down: true})
  //   }
  //   if(scrollValue > 0 && scrollValue < 1101){
  //     setPageScroll({up: true, down: true})
  //   }
  //   if(scrollValue === 1101){
  //     setPageScroll({up: true, down: false})
  //   }
  // },[scrollValue, currentPage])

  // React.useEffect(()=>{
  //   if(Math.round(scrollValue) === 0 && currentPage === 5){
  //     setPageScroll({up: false, down: true})
  //   }
  //   if(Math.round(scrollValue) > 0 && Math.round(scrollValue) < (prevScroll.certificate - prevScroll.inNumber)){
  //     setPageScroll({up: true, down: true})
  //   }
  //   if(Math.round(scrollValue) === (prevScroll.certificate - prevScroll.inNumber) || Math.round(scrollValue) === (prevScroll.certificate - prevScroll.inNumber)+1 || Math.round(scrollValue) === (prevScroll.certificate - prevScroll.inNumber)-1){
  //     setPageScroll({up: true, down: false})
  //   }
  // },[scrollValue, currentPage])

  // console.log(pageScroll)
  // console.log(prevScroll)
  // console.log(containerRef?.current?.scrollHeight)
  // console.log(scrollValue, 'cert scroll')
  // console.log((prevScroll.certificate - prevScroll.inNumber) ,'cert height')
  //
  // React.useEffect(()=>{
  //   setPrevScroll((prev:any)=>({...prev, certificate: Math.round(containerRef?.current.scrollHeight)}))
  // },[])

    return (
        <MainContainer ref={containerRef}
                       onMouseMove={() => setPrevScroll((prev: any) => ({
                           ...prev,
                           certificate: Math.round(containerRef?.current.scrollHeight)
                       }))}
                       onScroll={(e) => setScrollValue(Math.round(containerRef.current?.scrollTop))}
                       style={{
                           background: '#EDF5FF',
                           padding: isDesktop ? '100px 16%' : '60px 0%',
                           flexDirection: 'column',
                           justifyContent: 'flex-start',
                           height: '100%',
                       }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center',

                margin: isDesktop ? '0% auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div style={{gap: '0px', display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flexDirection: 'column', gap: '20px', padding: '0px 6%'}}>
                        <div style={{
                            fontWeight: 'bold',
                            fontSize: 'clamp(18px 4vw 24px)',
                            color: '#006DFF',
                            height: '20px'
                        }}>
                            {'인증 현황'}
                        </div>
                        <div style={{
                            fontWeight: 'normal',
                            fontSize: 'clamp(18px 4vw 24px)',
                            color: '#000',
                            height: '20px'
                        }}>
                            {'관련 분야에 알맞은 특허와 기술을 꾸준히 개발하여 국내 기술 선진화에 앞장섭니다.'}
                        </div>
                    </div>
                    {isDesktop ?
                        <div style={{marginTop: '40px'}}>
                            <CertificationComp/>
                        </div>
                        :
                        <div style={{marginTop: '10px'}}>
                            <CertificationSwiperContainer/>
                        </div>
                    }

                </div>
            </div>
        </MainContainer>

);
};

export default CertificationSection;
