import spms from '../asset/images/products/Image 22.png'
import loadtton from '../asset/images/products/lot_main.png'
import productImg from "../asset/images/spms_spec/Group 517.png";
import layout1 from "../asset/images/spms_spec/1000_F_171532492_xzT5fw02NntntJCCARGw2PJwsTddpfwd.png";
import compImg from "../asset/images/spms_spec/1000_F_392533069_jTOZwr3FhthnAEbk5ZROn7L4Za8TRu71.png";
import circuit from "../asset/images/spms_spec/240_F_632272232_9cO2vebm3mSM5obuGGHd4SUWMu2V5HAY.png";
import customer from "../asset/images/icons/Group 608@2x.png"
import creation from "../asset/images/icons/Group 610.svg"
import innovation from "../asset/images/icons/Group 612.svg"
import hr from "../asset/images/icons/Group 614.svg"
import technology from '../asset/images/certification/2021년-기술평가우수기업인증서[TCB-4].png'
import innoBiz from '../asset/images/certification/이노비즈확인서[220805].png'
import creatingJob from '../asset/images/certification/일자리창출우수기업인증서[240704].png'
import attachLaboratory from '../asset/images/certification/제스텍-기업부설연구소인증서[2206].png'
import venture from '../asset/images/certification/제스텍-벤처인증[20220127].png'
import specialize from '../asset/images/certification/제스텍-소부장-전문기업확인서[210209].png'
import design from '../asset/images/certification/DDA-21-001-디자인등록증[30-2021-0011341].png'
import cert1 from '../asset/images/certification/cert-10-1616818호.png'
import cert2 from '../asset/images/certification/cert-10-2007605호.png'
import cert3 from '../asset/images/certification/cert-10-2018478호.png'
import cert4 from '../asset/images/certification/cert-10-2069998호.png'
import cert5 from '../asset/images/certification/cert-10-2069999호.png'
import cert6 from '../asset/images/certification/cert-10-2405990호.png'
import solution_fas from "../asset/images/bg/solutionFAS.webp"
import solution_cloud from "../asset/images/bg/solutionCloud.webp"
import solution_ai from "../asset/images/bg/solutionAI.webp"
import fasIn from "../asset/images/bg/fasInner.png"



import spmsMain from "../asset/images/products/V3/main/V3_main@2x.png"
import allMain from "../asset/images/products/통합검출장치.png"
import ch1Main from "../asset/images/products/하사점1.png"
import ch2Main from "../asset/images/products/하사점2.png"
import misfeedMain from "../asset/images/products/misfeeder/main/misfeeder_main.png"
import Images from "../styles/Images";
import Icons from "../styles/Icons";
import {useContext} from "react";
import {createContext} from "node:vm";


export const menuArr=[
    {title:'제품소개',
        link:'/product',
        subMenu:[
            {menu:'프레스 컨트롤러',link:'/product/controller'},
            {menu:'프레스 주변장치',link:'/product/device'},
            // {menu:'프레스 미스피더 & 하사점 검출장치',link:''},
            // {menu:'프레스 슬라이드카운터',link:''},
            // {menu:'프레스 광선식 안정기',link:''},
            {menu:'안전 솔루션',link:'/product/safety'},
        ]},
    {title:'스마트 솔루션',link:'/solution'
            ,subMenu:[
                {menu:'FAS',link:'/solution/fas'},
                {menu:'기업형&Cloud 솔루션',link:'/solution/cloud'},
                {menu:'AI 솔루션',link:''},
    ]},
    // {title:'스마트 자동화',link:'/automation'
    //     ,subMenu:[
    //         {menu:'스마트 다관절 프레스 자동화',link:'/automation/multi'},
    //         {menu:'스마트 피더 프레스 자동화',link:'/automation/feeder'},
    //     ]},
    // {title:'스마트팩토리 구축',link:'/factory'
    //     ,subMenu:[
    //         {menu:'스마트팩토리 구축 사업',link:'/factory/project'},
    //         {menu:'스마트팩토리 구축 사례',link:'/factory/case'},
    //     ]},
    {title:'고객서비스',link:'/cs'
        ,subMenu:[
            {menu:'고객지원',link:'/cs'},
            {menu:'기술지원',link:''},
            {menu:'자료실',link:''},
            {menu:'단종제품 조회',link:''}
        ]},

    {title:'회사소개',link:'/about'
        ,subMenu:[
            {menu:'회사소개',link:'/about'},
            {menu:'사업영역',link:''},
            {menu:'IR',link:'/investment'},
            {menu:'인재채용',link:''},
            {menu:'오시는 길',link:'/about/address'},

        ]},
    // {title:'연도별 재무제표',link:'/investment'},
    // {title:'투자정보',link:'/investment'}
        // ,subMenu: [
        //     {menu: '재무정보', link: ''},
        //     {menu: '전자공고', link: ''},
        //     {menu: '공시정보', link: ''},
        //     {menu: 'IR자료실', link: ''},
        //     {menu: '주식정보', link: ''},
        // ]},
            ]


export const productSliderList = [
    {
        productType:'프레스 컨트롤러',
        title:'SPMS_v3 Module',
        sub:`소형 프레스 범용 제어가 가능한 
            지능형 프레스 제어 시스템`,
        image:spms
    },

    {  productType:'프레스 주변장치',
        title:'LOT 관리형 LoadTon Module',
        sub:`SPMS와 연계하여 프레스 자체의 공타,  
             유효타를 구분하고, 유효타 중 불량…`,
          image: '',
    },
    {
        productType: '프레스 컨트롤러',
        title: 'SPMS_v3 Module',
        sub: '안녕하세요못해요',
    },
    {  productType:'프레스 컨트롤러',
        title:'LodTon Module',
        sub:`프레스 가공 시 금형의 이물질을 체크하여 
        금형 손상 예방, 성형 불량 최소…`,
        image:''
    },
    {  productType:'프레스 컨트롤러',
        title:'LodTon Module',
        sub:`프레스 가공 시 금형의 이물질을 체크하여 
        금형 손상 예방, 성형 불량 최소…`,
        image:''
    },
]

export const productKeywords = [
    {keyword:'사용자고려'},
    {keyword:'자체개발'},
    {keyword:'미래성장'},
    {keyword:'전문인력'},
    {keyword:'100% 국내기술'},
    {keyword:'커스텀'},
    {keyword:'스마트팩토리'},
    {keyword:'SPMS'},
    {keyword:'FAS'},
    {keyword:'P-MS'},
    {keyword:'스마트 솔루션'},
    {keyword:'설비 관리'},
    ]



export const specArray = [
    {productName: 'SPMS_V3',
        subName:'Smart Press Monitoring System',
        info:'소형 프레스 범용 제어가 가능한 \n지능형 프레스 제어 시스템',
        copyWrite:`SPMS는 프레스 기종과 관계없이 
         종합적인 프레스 컨트롤러 역할을 하며,
         프레스 관련 데이터를 전송할 수 있는 
         ICT기능이 탑재된 제품입니다.`,
        mobileCopyWrite: `종합적인 프레스 컨트롤러 역할을 하며,
         프레스 관련 데이터를 전송할 수 있는 
         ICT기능이 탑재된 제품입니다.`,
        certification:['KC인증','CE인증','UL인증'],
        mainImg:Images.v3.main,
        // productFeature:[
        //     {title:'',
        //      content:'SPMS Protocol을 기반으로 Cloud 또는 S/W에 연결할 경우,\n생산관리 및 설비 관리 데이터를 받아 보실 수 있습니다.'}],
        productComponents:[
            {title:'SPMS_V3_BASE',size:'300(W) X 94.8(D) X 135(H)',img:Images.v3.base,temperature:'-20 ℃~70℃'},
            {title:'SPMS_V3_CPU',size:'300(W) X 94.8(D) X 135(H)',img:Images.v3.cpu,temperature:'-20 ℃~70℃'},
            {title:'SPMS_V3_POWER',size:'300(W) X 94.8(D) X 135(H)',img:Images.v3.power,temperature:'-20 ℃~70℃'},
            {title:'SPMS_V3_IN PUT',size:'300(W) X 94.8(D) X 135(H)',img:Images.v3.input,temperature:'-20 ℃~70℃'},
            {title:'SPMS_V3_OUT PUT',size:'300(W) X 94.8(D) X 135(H)',img:Images.v3.output,temperature:'-20 ℃~70℃'},
            {title:'SPMS_V3_Front',size:'300(W) X 94.8(D) X 135(H)',img:Images.v3.front,temperature:'-20 ℃~70℃'},
            {title:'SPMS_V3_Sensor',size:'300(W) X 94.8(D) X 135(H)',img:Images.v3.sensor,temperature:'-20 ℃~70℃'},
        ],
        productImg:[{product:productImg,layout:layout1,comp:compImg,circuit:circuit}],
        productSpec: [
            {specName:'프레스 통합 제어(내장 시스템)',value:'-'},
            {specName:'파라미터 설정 기능',value:'200 ch'},
            {specName:'SPMS Protocol 지원',value:'TCP/IP 기반 자체 프로토콜 표준'},
            {specName:'Input 카드',value:'-'},
            {specName:'Output 카드',value:'-'},
            {specName:'Front 카드',value:'Touch Monitor , spare output'},
            {specName:'AD 카드',value:'Current 2ch, VS 1ch'},
            {specName:'Power 카드',value:'DC 24V'},
        ],productOption:[
            {optionName:'Temperature 카드',value:'max 6ch'},
            {optionName:'Encorder Signal',value:'-'},
            {optionName:'LoadTon',value:'-'},
            {optionName:'misfeeder Detector',value:'-'},
            {optionName:'Low Point Detector',value:'-'},
            {optionName:'Spare Input(기능추가)',value:'-'},
            {optionName:'Spare Output(기능추가)',value:'-'},
        ],
        link:'/product/SPMS_V3',
        category:'프레스 컨트롤러'
    },

    {productName:'SPMS_V1',
        subName:'Smart Press Monitoring System',
        info:'소형 프레스 범용 제어가 가능한 \n지능형 프레스 제어 시스템',
        copyWrite:`SPMS는 프레스 기종과 관계없이 
         종합적인 프레스 컨트롤러 역할을 하며,
         프레스 관련 데이터를 전송할 수 있는 
         ICT기능이 탑재된 제품입니다.`,
        mobileCopyWrite: `종합적인 프레스 컨트롤러 역할을 하며,
         프레스 관련 데이터를 전송할 수 있는 
         ICT기능이 탑재된 제품입니다.`,
        certification:['KC인증','CE인증','UL인증'],
        mainImg:Images.v1.main,
        productImg:[{product:'',layout:'',comp:'',circuit:''}],
        productFeature:[
            {title:'LOT 관리형 LoadTon Module',
                content:'SPMS (Smart Press Monitoring System)과 연계하여 프레스 자체의공타, 유효타를 구분하고, ' +
                    '유효타 중 불량/양품을 구분하여 기계 스스로 LOT관리를 할 수 있게 합니다. \n'}],
        productComponents:[
            {title:'▲ Sensor Board', img:Images.v1.sensor},
            {title:'▲ Power Board', img:Images.v1.power},
            {title:'▲ Ramp Board', img:Images.v1.ramp},
            {title:'▲ Spare Cam', img:Images.v1.spare},
            ],
        productSpec: [
            {specName:'프레스 통합 제어(내장 시스템)',value:'-'},
            {specName:'파라미터 설정 기능',value:'200 ch'},
            {specName:'SPMS Protocol 지원',value:'TCP/IP 기반 자체 프로토콜 표준'},
            {specName:'Channel',value:'2Channel'},
            {specName:'Input',value:'-'},
            {specName:'Output',value:'-'},
            {specName:'Front',value:'Touch Monitor , spare output'},
            {specName:'AD',value:'Current 2ch, VS 1ch'},
            {specName:'Power',value:'DC 24V'},
        ],productOption:[
            {optionName:'Temperature 카드',value:'max 6ch'},
            {optionName:'Encorder Signal',value:'-'},
            {optionName:'LoadTon',value:'-'},
            {optionName:'misfeeder Detector',value:'-'},
            {optionName:'Low Point Detector',value:'-'},
            {optionName:'Spare Input(기능추가)',value:'-'},
            {optionName:'Spare Output(기능추가)',value:'-'},
        ],
        link:'/product/SPMS_V1',
        category:'프레스 컨트롤러'
    },
    {productName:'SPMS_V2',
        subName:'Smart Press Monitoring System',
        info:'소형 프레스 범용 제어가 가능한 \n지능형 프레스 제어 시스템',
        copyWrite:`SPMS는 프레스 기종과 관계없이 
         종합적인 프레스 컨트롤러 역할을 하며,
         프레스 관련 데이터를 전송할 수 있는 
         ICT기능이 탑재된 제품입니다.`,
        mobileCopyWrite: `종합적인 프레스 컨트롤러 역할을 하며,
         프레스 관련 데이터를 전송할 수 있는 
         ICT기능이 탑재된 제품입니다.`,
        certification:['KC인증','CE인증','UL인증'],
        mainImg:Images.v2.main,
        // productImg:[{product:'',layout:'',comp:'',circuit:''}],
        // productFeature:[
        //     {title:'LOT 관리형 LoadTon Module',
        //         content:'SPMS (Smart Press Monitoring System)과 연계하여 프레스 자체의공타, 유효타를 구분하고, ' +
        //             '유효타 중 불량/양품을 구분하여 기계 스스로 LOT관리를 할 수 있게 합니다. \n'}],
        productComponents:[
            {title:'▲ Sensor Board', img:Images.v1.sensor},
            {title:'▲ Power Board', img:Images.v1.power},
            {title:'▲ Ramp Board', img:Images.v1.ramp},
            {title:'▲ Spare Cam', img:Images.v1.spare},
        ],
        productSpec: [
            {specName:'프레스 통합 제어(내장 시스템)',value:'-'},
            {specName:'파라미터 설정 기능',value:'200 ch'},
            {specName:'SPMS Protocol 지원',value:'TCP/IP 기반 자체 프로토콜 표준'},
            {specName:'Channel',value:'2Channel'},
            {specName:'Input',value:'-'},
            {specName:'Output',value:'-'},
            {specName:'Front',value:'Touch Monitor , spare output'},
            {specName:'AD',value:'Current 2ch, VS 1ch'},
            {specName:'Power',value:'DC 24V'},
        ],productOption:[
            {optionName:'Temperature 카드',value:'max 6ch'},
            {optionName:'Encorder Signal',value:'-'},
            {optionName:'LoadTon',value:'-'},
            {optionName:'misfeeder Detector',value:'-'},
            {optionName:'Low Point Detector',value:'-'},
            {optionName:'Spare Input(기능추가)',value:'-'},
            {optionName:'Spare Output(기능추가)',value:'-'},
        ],
        link:'/product/SPMS_V2',
        category:'프레스 컨트롤러'
    },
    {productName:`misfeeder`,
        subName:'미스피더',
        copyWrite:`프레스 공정 진행중에 
        제품 생산의 불량요소를 각종 센서를 통한 
        이상감지를 발견하여 
        연속적인 생산활동을 방지하고
        금형 파손 및 불량 생산을 최소화하는 
        프레스 공정 주변 장치 입니다.`,
        mobileCopyWrite:`연속적인 생산활동을 방지하여 
        금형 파손 및 불량 생산을 최소화 
        하는 프레스 공정 주변 장치`,
        certification:['KC인증','CE인증','UL인증'],
        mainImg: Images.misfeeder.main,
        // productImg:[{product:'',layout:'',comp:'',circuit:''}],
        productFeature:[
            {title:'미스피드 검출장치',
                content:'프레스 작업도중 금형내부의 각종 이상현상 발생시 센서로 감지하고, 프레스를 정지시켜 제품 불량과 금형파손을 예방하는데 도움을 주는 장치입니다.'}],
        productSpec: [
            {specName:'재료말단',value:'1채널, 피더가 원자재(Coil)를 일정한 텐션을 유지한채 일정하게 재료를 공급해주는지 여부를 확인하고, \n 일정하게 재료 공급이 안되었을때 감지합니다.'},
            {specName:'재료휨',value:'1채널, 피더와 프레스 사이에 연결되어있는 원자재(Coil)가 일정한 텐션을 유지하면서 제품을 생산 중인지 하고,\n 원자재(Coil)가 일정한 텐션을 유지하지 못해, 재료가 일정 수준 이상으로 휘어 있을 때, 감지합니다.'},
            {specName:'다기능 입력 4채널',value:'A접점, B접점 선택가능'},
            {specName:'무게',value:'다기능 입력 4채널'},
            {specName:'사이즈',value:'W185 * H110 * D135 mm'},
        ],  productComponents:[
            {title:'▲ 마그네트 2EA', img:Images.misfeeder.magnet},
            {title:'▲ 방진고무 4EA', img:Images.misfeeder.proof},
            {title:'▲ L자형 브라켓', img:Images.misfeeder.lshape},
            {title:'▲ 스프링센서 1SET', img:Images.misfeeder.spring},
        ], productExample:[Images.misfeeder.guide],
        link:'/product/misfeeder',
        category: '프레스 주변장치'
    },
    {productName:'1ch',
        infoName:'하사점 검출장치 1채널',
        copyWrite:`와전류 변위센서에 의한 금형의 하사점 변위를 
        마이크로미터 단위로 체크하여 검출할 수 있는 기기로, 
        실제 가공중 변위 오차를 표기하면서 
        일정 수준의 변위 오차가 발생했을때, 
        생산활동을 방지하여 금형 파손 및 불량 생산을 
        최소화 하는 프레스 공정 주변 장치 입니다.`,
        mobileCopyWrite:`연속적인 생산활동을 방지하여 
        금형 파손 및 불량 생산을 최소화 
        하는 프레스 공정 주변 장치`,
        certification:['KC인증','CE인증','UL인증'],
        mainImg:Images.dieHeight1ch.main,
        // productImg:[{product:'',layout:'',comp:'',circuit:''}],
        productFeature:[
            {title:'하사점 검출장치 1채널',
                content:'프레스 공정에서 금형의 이물질을 체크하여 금형 손상을 예방하고, 불량을 최소화하는데 도움을 주는 다기능 검출 장치입니다.'}],
        productSpec: [
            {specName:'하사점검출',value:'프레스 공정 과정 중, 금형 또는 원자재, 생산중인 제품 위에 이물질이 발견될 경우,\n일정 변위 오차 이상이 발생했을 경우 감지합니다.'},
            {specName:'재료말단',value:'1채널, 피더가 원자재(Coil)를 일정한 텐션을 유지한채 일정하게 재료를 공급해주는지 여부를 확인하고,\n일정하게 재료 공급이 안되었을때 감지합니다.'},
            {specName:'재료휨',value:'1채널, 피더와 프레스 사이에 연결되어있는 원자재(Coil)가 일정한 텐션을 유지하면서 제품을 생산 중인지 하고,\n 원자재(Coil)가 일정한 텐션을 유지하지 못해, 재료가 일정 수준 이상으로 휘어 있을 때, 감지합니다.'},
            {specName:'최대 검출',value:'SPM 120'},
            {specName:'최대 검출 가능 판재 두께',value:'3T'},
            {specName:'사이즈',value:'W195 * H110 * D135 mm'},
        ],  productComponents:[
            {title:'▲ 마그네트 2EA', img:Images.misfeeder.magnet},
            {title:'▲ 방진고무 4EA', img:Images.misfeeder.proof},
            {title:'▲ L자형 브라켓', img:Images.misfeeder.lshape},
            {title:'▲ 스프링센서 1SET', img:Images.dieHeight1ch.spring},
            {title:'▲ 하사점센서 1SET', img:Images.dieHeight1ch.sensor},
        ],
        link:'/product/1ch',
        category: '프레스 주변장치'
    },
    {productName: `2ch`,
        infoName:'하사점 검출장치 2채널',
        copyWrite:`와전류 변위센서에 의한 금형의 하사점 변위를 
        마이크로미터 단위로 체크하여 검출할 수 있는 기기로, 
        실제 가공중 변위 오차를 표기하면서 
        일정 수준의 변위 오차가 발생했을때, 
        생산활동을 방지하여 금형 파손 및 불량 생산을 
        최소화 하는 프레스 공정 주변 장치 입니다.`,
        mobileCopyWrite:`연속적인 생산활동을 방지하여 
        금형 파손 및 불량 생산을 최소화 
        하는 프레스 공정 주변 장치`,
        certification:['KC인증','CE인증','UL인증'],
        mainImg:Images.dieHeight2ch.main,
        // productImg:[{product:'',layout:'',comp:'',circuit:''}],
        productFeature:[
            {title:'하사점 검출장치 1채널',
                content:'프레스 공정에서 금형의 이물질을 체크하여 금형 손상을 예방하고, 불량을 최소화하는데 도움을 주는 다기능 검출 장치입니다.'}],
        productSpec: [
            {specName:'하사점검출',value:'프레스 공정 과정 중, 금형 또는 원자재, 생산중인 제품 위에 이물질이 발견될 경우,\n일정 변위 오차 이상이 발생했을 경우 감지합니다.'},
            {specName:'재료말단',value:'1채널, 피더가 원자재(Coil)를 일정한 텐션을 유지한채 일정하게 재료를 공급해주는지 여부를 확인하고,\n일정하게 재료 공급이 안되었을때 감지합니다.'},
            {specName:'재료휨',value:'1채널, 피더와 프레스 사이에 연결되어있는 원자재(Coil)가 일정한 텐션을 유지하면서 제품을 생산 중인지 하고,\n 원자재(Coil)가 일정한 텐션을 유지하지 못해, 재료가 일정 수준 이상으로 휘어 있을 때, 감지합니다.'},
            {specName:'최대 검출',value:'SPM 120'},
            {specName:'최대 검출 가능 판재 두께',value:'3T'},
            {specName:'사이즈',value:'W195 * H110 * D135 mm'},
        ],  productComponents:[
            {title:'▲ 마그네트 2EA', img:Images.misfeeder.magnet},
            {title:'▲ 방진고무 4EA', img:Images.misfeeder.proof},
            {title:'▲ L자형 브라켓', img:Images.misfeeder.lshape},
            {title:'▲ 스프링센서 1SET', img:Images.dieHeight1ch.spring},
            {title:'▲ 하사점센서 1SET', img:Images.dieHeight1ch.sensor},
        ],
        link:'/product/2ch',
        category: '프레스 주변장치'
    },

    {productName:'intergration',
        infoName:'통합 하사점 검출장치',
        copyWrite:'하사점 검출장치와 미스피드의 기능이\n통합된 제품입니다.',
        mobileCopyWrite:`하사점 검출장치와 미스피드의\n기능이 통합된 제품입니다.`,
        // mainImg: allMain,
        // productImg:[{product:'',layout:'',comp:'',circuit:''}],
        certification:['KC인증','CE인증','UL인증'],
        mainImg:Images.intergration.main,
        productFeature:[
            {title:'통합 하사점 검출 장치',
                content:'프레스 작업도중 금형내부의 각종 이상현상 발생시 센서로 감지하고, 프레스를 정지시켜 제품 불량과 금형파손을 예방하는데 도움을 주는 장치입니다.'}],
        productSpec: [
            {specName:'외형',value:'200x140x114mm 강철 1.5T'},
            {specName:'무게',value:'약1.5Kg'},
            {specName:'압력전압',value:'DC24V'},
            {specName:'디스플레이',value:'7Inch Touch LCD(1024X600, 저항막 Touch)'},
            {specName:'다기능 미스피드 입력',value:'6채널 (A접점, B접점 선택가능)'},
            {specName:'최대 검출',value:'SPM 300'},
            {specName:'최대 검출 가능 판재 두께',value:'4T'},
            {specName:'사이즈',value:'W200*H115*D130 mm'},
        ],productOption:[
            {optionName:'COMMON SLOT',value:'RS485, CAM 4채널'},
            {optionName:'MISPEEDER SLOT',value:'6채널'},
            {optionName:'DIE HEIGHT DETECTOR SLOT',value:'2채널'},
            {optionName:'EMPT SLOT',value:'-'},
            {optionName:'POWER SLOT',value:'Relay 2채널, Reset 1ch, N24,P24'},
        ],productGuide:[Images.misfeeder.guide],
        productInstall: [
            {mainTitle:'설치안내(입출력)',
                detail:[ {title:'전원카드', img:Images.intergration.power,mImg:Images.intergration.mPower},
                    {title:'캠입력/통신 카드', img:Images.intergration.cam,mImg:Images.intergration.mCam}]},
            {mainTitle:'설치안내(미스피드)',
                detail:[ {title:'미스피드 입력접점 정보', img:Images.intergration.miss,mImg:Images.intergration.mMiss},
                    {title:'미스피드 세부 설정내용', img:Images.intergration.wave,mImg:Images.intergration.mWave}]}
            ,{mainTitle:'설치안내(하사점 검출)',
                detail:[ {title:'하사점 검출센서 입력접점 정보', img:Images.intergration.die,mImg:Images.intergration.mDie},
                    {title:'하사점 검출장치 안내도', img:Images.intergration.guide,mImg:Images.intergration.mGuide}]}
        ],
        productComponents:[
            {title:'▲ 마그네트 2EA', img:Images.misfeeder.magnet},
            {title:'▲ 방진고무 4EA', img:Images.misfeeder.proof},
            {title:'▲ L자형 브라켓', img:Images.misfeeder.lshape},
            {title:'▲ 스프링센서 1SET', img:Images.dieHeight1ch.spring},
            {title:'▲ 하사점센서 1SET', img:Images.dieHeight1ch.sensor},
        ],
        link:'/product/intergration',
        category: '프레스 주변장치'
    },
    {productName:'SLMS',
        copyWrite:`스트레인게이지 센서를 이용하여 
        부하별 톤 및 에너지를 각도별로 확인하여 
        제품 공정 개선에 활용할 수 있습니다.`,
        mobileCopyWrite:`스트레인게이지 센서를 이용하여 
        부하별 톤 및 에너지를 각도별로 
        확인하여 제품 공정 개선에 
        활용할 수 있습니다.`,

        certification:['KC인증','CE인증','UL인증'],
        mainImg:Images.slms.main,
        // productImg:[{product:'',layout:'',comp:'',circuit:''}],
        productSpec: [
            {specName:'제품명',value:'프레스 로드톤 데이터 처리 모듈'},
            {specName:'모델명',value:'AOPLOCA20'},
            {specName:'전체 사이즈',value:'110mm * 118mm * 65mm'},
            {specName:'제품 타입',value:'모듈형 로드 옵션 장치'},
        ],productOption:[
            {optionName:'입력전원',value:'DC 24V'},
            {optionName:'Sensor Type',value:'Strain Gauge Sensor'},
            {optionName:'Channel',value:'2'},
            {optionName:'I/O',value:'Encorder (360분해능) Input'},
            {optionName:'통신지원',value:'Ethernet, RS485'},
            {optionName:'적용 SPM',value:'60SPM 이하'},
            {optionName:'SMPS 지원 버전',value:'V2, V3'},
        ],
        link:'/product/SLMS',
        category:'프레스 컨트롤러'
    },
    {productName:'pressure',
        copyWrite:`압전압식 센서를 이용하여 
        부하별 톤 및 에너지를 각도별로 확인하여 
        제품 공정 개선에 활용할 수 있습니다.`,
        mobileCopyWrite:`압전압식 센서를 이용하여 
        부하별 톤 및 에너지를 각도별로 확인`,
        certification:['KC인증','CE인증','UL인증'],
        mainImg:Images.pressure.main,
        // productImg:[{product:'',layout:'',comp:'',circuit:''}],
        productSpec: [
            {specName:'외형',value:'176X142X116mm 강철1.5T'},
            {specName:'무게',value:'약 1.6kg'},
            {specName:'구동전압',value:'DC24V'},
            {specName:'입력',value:'압전형 압력센서 4~20mA출력타입 2채널'},
            {specName:'출력',value:'톤데이터 13bit NPN/PNP 출력 2채널 (BIN, BCD, GRY)'},
        ],
        productInstall: [
            {mainTitle:'설치안내', img:Images.pressure.install,mImg: Images.pressure.mInstall},
            {mainTitle:'센서 결선도', img:Images.pressure.wire,
                notice:['센서 + - 연결방향 주의. 반대로 입력 시 정상적인 신호입력이 안됩니다. ',
                    '센서 출력은 반드시 4~20mA 전류출력 타입으로 사용',
                    '센서 케이블 길이가 10M이상 이거나 고전압장치 근처를 지나가는 경우 실드형 케이블 사용을 추천합니다.']},
            {mainTitle:'캠 결선도', img:Images.pressure.cam,
                content: 'IP1과 GND가 캠을 통하여 연결이 되고 있는 동안 압력센서의 변위량을 스캔하고 캠 입력이\n종료되는 시점에 스캔된 변위량을 바탕으로 톤값을 계산하여 출력합니다.',
                notice:['캠입력 종료 후 톤값 계산하여 PLC측으로 출력하기 때문에 PLC측에서는 톤값을 판단하는 시점을 캠 종료각도에서 10도이상 후에 잡는 것을 추천합니다. ']},
            {mainTitle:'리셋 입력 결선도', img:Images.pressure.reset,
                content:'에러 발생 시 본체 전면에 있는 ON 버튼 또는 단자대의 IP2에 GND를 입력시키면 비상신호 복귀가 진행됩니다. 외부 리셋 스위치 사용 시 연결하십시오.'},
            {mainTitle:'데이터 출력', img:Images.pressure.output},
            {mainTitle:'NPN 타입', img:Images.pressure.npn},
            {mainTitle:'PNP 타입', img:Images.pressure.pnp},
            {mainTitle:'출력 설정 방법', img:Images.pressure.setting,mImg:Images.pressure.mSetting,
                content:'기본 대기 상태에서 (2)SET 버튼을 2초 이상 누르면 LCD 화면이 변경됩니다.',
                notice:['방향키 상/하로 (1) 선택 커서를 이동하여 채널을 선택할지, 출력 타입을 설정할지 선택합니다.', '방향키 좌/우로 값을 변경할 수 있습니다.']},
        ],

        link:'/product/pressure',
        category:'프레스 주변장치'
    },
    {productName:'safety',
        copyWrite:`안전 규칙에 위반되는 상황들을 
        알고리즘으로 만들어 판독하고 ‘위험 상황’을 즉시 감지하여, 
        별도 PLC 설치 없이 (주)제스텍의 SPMS(스마트 프레스모니터링 시스템)을 통해 
        경고에 맞춰 제어 가능합니다.`,
        certification:['KC인증','CE인증','UL인증'],
        mainImg:Images.safety.main,
        link:'/product/safety',
        category:'안전 솔루션'
    },
]



export const philosophyArray = [
    {title:'사용자 중심',
        content:`고객으로부터 먼저 우리가 알아야 할것은 무엇인가 라는 질문으로부터 다양한 고객요구와 숨은 니즈를 발견하고 실현합니다.`,
        img:customer,
        id:'section0'
    },
    {title:'가치창출',
        content:`빠르게 변화하는 IT환경에 대응하고 협력하여 새로운 가치를 창출하도록 지원합니다.`,
        img:creation,
        id:'section1'
    },
    {title:'도전과 혁신',
        content:`최고의 기술 증진과 혁신을 위해 끊임없이 도전하여 안정적이고 성공적인 실현을 위해 노력합니다.`,
        img:innovation,
        id:'section2'
    },
    {
        title: `인재양성
        &사회공헌 `,
        content: `꾸준한 R&D 연구투자로 전문가를 배양하고 국내기술의 선진화를 이끌어 함께 성장하는 사회를 만들어갑니다.`,
        img: hr,
        id:'section3'
    }
    ]

export const historyData = [
    {year:2022, content:[
            {date:'2022.00', text:['기술혁신 형 중소기업(Inono-Biz) 인증, 기술등급 A']},
            {date:'2022',text:['2022년 인천광역시 일자리창출 우수기업 선정']},
            {date:'2022',text:['유일로보틱스 MOU체결, 실질적 생산성 개선을 위한 스마트팩토리 구축 추진 협력']},
            {date:'2022',text:['호남대 MOU체결, 제조산업의 스마트 팩토리 AI 플랫폼 개발']},
    ]},
    {year:2021, content:[
            {date:'2021', text:['2021년 기술평가 우수기업 인증']},
            {date:'2021', text:['2020년 스마트팩토리 구축사업 진행 25건 완료']},
            {date:'2021', text:['SmartFactory ICT 모듈 개발 시작','기업부설 연구소 설립']}]},
    {year:2020, content:[
            {date:'2020', text:['2020년 스마트팩토리 구축사업 진행 25건 완료']},
            {date:'2020', text:['JHN프레스 전용 컨트롤러 개발 및 공급']}]},
    {year:2019, content:[
            {date:'2019', text:['ICT 통합 하사점 검출장치, 로드모니터, 개발 완료 및 상용화']},
            {date:'2019', text:['(주)시즐 ICT 융합 S/W 컨소시엄 MOU 체결 및 공동 개발 (PMS, MES, WMS)']}]},
    {year:2018, content:[
            {date:'2018', text:['한일 너클프레스 전용 컨트롤러 개발 및 공급']},
            {date:'2018', text:['SPMS WEB 기발 스트리밍 모니터링 서비스 시작','ICT 프레스 주변장치 개발 시작','연구 전담부서 설립']},
            {date:'2018', text:['PMS, MES 개발시작']}]},
    {year:2017, content:[
            {date:'2017', text:['ICT 프레스 컨트롤러 라인화 (주)화XXXX 구성 시연회 완료(7대 라인)','ICT 프레스 컨트롤러 (주)심팩 시연회 완료(단일제품)']}]},
    {year:2014, content:[
            {date:'2014', text:['ICT 프레스 컨트롤러 개발']}]},
    {year:2013, content:[
            {date:'2013', text:['(주)심팩, 마이다프레스, 국일정공 전용 디지털 프레스 컨트롤러 공급',
                    '프레스 주변장치(미스피트, 하사점 검출장치, 로드셀) 컨트롤러 공급']},
            {date:'2013', text:['법인 설립']}
        ]} ]



export const certificationData = [
    {certImage:`${attachLaboratory}`, certText:`(주)제스텍 기업부설 연구소 인증서(2206)`},
    {certImage:`${venture}`, certText:`(주)제스텍 벤처인증(20220127)`},
    {certImage:`${specialize}`, certText:`(주)제스텍 소부장 전문기업확인서(210209)`},
    {certImage:`${technology}`, certText:`2021년 기술평가 우수기업 인증서(TCB-4)`},
    {certImage:`${innoBiz}`, certText:`이노비즈 확인서(220805)`},
    {certImage:`${creatingJob}`, certText:`일자리창출 우수기업 인증서(240704)`},
    {certImage:`${design}`, certText:'DDA-21-001 디자인등록증(30-2021-0011341)'},
    {certImage:`${cert1}`, certText:`특허 제 10-1616818호`},
    {certImage:`${cert2}`, certText:`특허 제 10-2007605호`},
    {certImage:`${cert3}`, certText:`특허 제 10-2018478호`},
    {certImage:`${cert4}`, certText:`특허 제 10-2069998호`},
    {certImage:`${cert5}`, certText:`특허 제 10-2069999호`},
    {certImage:`${cert6}`, certText:`특허 제 10-2405990호`},
]

export const addressData = [
    {title:'본사',
        info:[{
            address:'인천광역시 서구 파랑로 495, 1동 622~628호',
            tel: '(032)505-8055',
            fax: '(032)505-8056',
            email: 'zestech13@naver.com',
            latitude: 37.52444353728645,
            longitude: 126.6503152167466 }],
        },


    {title:'제스텍 연구소',
        info:[{
        address:'인천광역시 연수구 송도과학로32 M동 1803호',
        tel:'(032)505-8055',
        fax:'(032)505-8056',
        email:'zestech13@naver.com',
        latitude: 37.3818594284068,
        longitude: 126.662694187386}]
    },

    {title:'심팩 제조소',
        info: [{
            address:'인천광역시 서구 건지로 124번지(심팩인더스트리 內)',
            tel:'(032)505-8055',
            fax:'(032)505-8056',
            email:'zestech13@naver.com',
            latitude: 37.4982263481381,
            longitude: 126.656054306184}]
    },

    {title:'경상 지사',
        info: [{
            address: '대구광역시 수성구 알파시티 1로 42길 11,10층 1011호(대흥동)',
            tel: '(053)794-8055',
            fax: '(053)794-8056',
            email: 'zesone@naver.com',
            latitude: 35.838471266914,
            longitude: 128.682764063153}],
        },
    {title:'광주 지사',
        info: [{
            address: '광주광역시 북구 첨단과기로 208번길 43-22 A동 1503,1504호',
            tel: '(062)975-0433',
            fax: '(062)975-0434',
            email: 'zestechdev@naver.com',
            latitude: 35.22243970624261,
            longitude: 126.85171704868779
        }],
        }
]

export const newsData = [
    {title:'㈜제스텍 박필석 대표 - 4차 산업혁명과 만나 새로운 국가 경쟁력으로 떠오른 제조업, 대한민국 제조업 혁신 이끌어가는 ㈜제스텍',
        img: 'https://www.monthlypeople.com/news/photo/202309/648914_647591_2215.jpg',
        link:'https://www.monthlypeople.com/news/articleView.html?idxno=648914'
    },
    {
        title: '제스텍 호남대에 장학금 4000만원 기탁',
        img: 'http://cdn.veritas-a.com/news/photo/202305/458906_369451_2115.jpg',
        link:'http://www.veritas-a.com/news/articleView.html?idxno=458906'
    },
    {title: '유일로보틱스-제스텍, 공정에 특화된 인공지능 로봇 개발에 \'맞손\'',
        img: 'http://file.yeogie.com/img.Editor/202205/nKQKYsSPAX.jpg',
        link:'http://robotzine.co.kr/entry/260943'},

      {title: '한국공대, 주식회사 져스텍·제스텍과 기술이전계약 체결\n',
           img:'https://wimg.mk.co.kr/news/cms/202302/16/news-p.v1.20230216.182f6003b1614ec6b2ce3cb7883a8acf_P1.jpg',
           link:'https://www.mk.co.kr/news/society/10648244'},


    ]


export const solutionData = [
    {
        title: 'FAS',
        sub: '(Factory Admin System)',
        contents: `FAS는 공장 종합 관리 시스템으로 
        공장내에 있는 모든 활동 및 관리를 
        종합적으로 해주는 솔루션입니다. `,
        img: fasIn,
        link: '/solution/fas',
        bg: Images.fas.bg,
        mainImg: solution_fas,
        detail: [
            {
                moduleName: 'P-MS', fullName:'(Press Monitoring Service)', moduleImg: Images.fas.pms,
                moduleContent: `Press Monitoring Service는 가동중인 Press의 상태를 
            실시간으로 확인이 가능하며, 누적된 기계 상태를 통계 및 분석을 
            통해 관리 할 수 있는 솔루션입니다.`,
                moduleContentM:'Press Monitoring Service는 가동중인 Press의 상태를 실시간으로 확인이 가능하며, 누적된 기계 상태를 통계 및 분석을 통해 관리 할 수 있는 솔루션입니다.'
            },
            {
                moduleName: 'P-BAS', fullName:'(Press Before/After Service)', moduleImg: Images.fas.pbas,
                moduleContent: `Press Before/After Service는 가동중인 Press의 상태를 
            실시간으로 확인하며, 종합적인 Press의 예지보전 관리를 통해,
             기계 및 시스템 고장으로 인한 생산활동에 악영향을 주는 요인들을 
             사전에 관리하여 조치 및 대응을 해주는 서비스입니다.`,
                moduleContentM:'Press Before/After Service는 가동중인 Press의 상태를 실시간으로 확인하며, 종합적인 Press의 예지보전 관리를 통해, 기계 및 시스템 고장으로 인한 생산활동에 악영향을 주는 요인들을 사전에 관리하여 조치 및 대응을 해주는 서비스입니다.'
            },
            {
                moduleName: 'F-EMS', fullName:'(Factory Energy Management System)', moduleImg: Images.fas.fems,
                moduleContent: `에너지 관리 시스템으로, 공장내 보유한 장비 및 한전에서 제공받은 
                에너지를 통계 및 분석하여, 실질적으로 공장 내 에너지를 
                어떻게 사용하고 있는지, 관리 해주는 솔루션입니다.`,
                moduleContentM:'에너지 관리 시스템으로, 공장내 보유한 장비 및 한전에서 제공받은 에너지를 통계 및 분석하여, 실질적으로 공장 내 에너지를 어떻게 사용하고 있는지, 관리 해주는 솔루션입니다.'
            },
            {
                moduleName: 'C-MS', fullName:'(CNC Monitoring Service)', moduleImg: Images.fas.blurImage,
                moduleContent: `CNC Monitoring Service는 가동중인 CNC의 상태를 
                실시간으로 확인이 가능하며, 
                누적된 기계 상태를 통계 및 분석을 통해 관리 할 수 있는 솔루션입니다.`,
                moduleContentM:'CNC Monitoring Service는 가동중인 CNC의 상태를 실시간으로 확인이 가능하며, 누적된 기계 상태를 통계 및 분석을 통해 관리 할 수 있는 솔루션입니다.'
            },
            {
                moduleName: 'C-BAS', fullName:'(CNC Before/After Service)', moduleImg: Images.fas.blurImage,
                moduleContent: `CNC Before/After Service는 가동중인 CNC 의 상태를 
                실시간으로 확인하며, 종합적인 CNC의 예지보전 관리를 통해,
                 기계 및 시스템 고장으로 인한 생산활동에 악영향을 주는 요인들을 
                 사전에 관리하여 조치 및 대응해주는 서비스입니다.`,
                moduleContentM:'CNC Before/After Service는 가동중인 CNC 의 상태를 실시간으로 확인하며, 종합적인 CNC의 예지보전 관리를 통해,기계 및 시스템 고장으로 인한 생산활동에 악영향을 주는 요인들을 사전에 관리하여 조치 및 대응해주는 서비스입니다.'
            },
            {
                moduleName: 'R-MS', fullName:'(ROBOT Monitoring Service)', moduleImg: Images.fas.blurImage,
                moduleContent: `ROBOT Monitoring Service는 가동중인 ROBOT의 상태를 
                실시간으로 확인이 가능하며, 
                누적된 기계 상태를 통계 및 분석을 통해 관리할수있는 솔루션입니다.`,
                moduleContentM:'ROBOT Monitoring Service는 가동중인 ROBOT의 상태를 실시간으로 확인이 가능하며,누적된 기계 상태를 통계 및 분석을 통해 관리 할 수 있는 솔루션입니다.'
            },
            {
                moduleName: 'R-BAS', fullName:'(ROBOT Before/After Service)', moduleImg: Images.fas.blurImage,
                moduleContent: `ROBOT Before/After Service는 가동중인 ROBOT의 상태를 
                실시간으로 확인하며, 종합적인 ROBOT의 예지보전 관리를 통해,
                 기계 및 시스템 고장으로 인한 생산활동에 악영향을 주는 요인들을 
                 사전에 관리하여 조치 및 대응을 해주는 서비스입니다.`,
                moduleContentM:'ROBOT Before/After Service는 가동중인 ROBOT의 상태를 실시간으로 확인하며, 종합적인 ROBOT의 예지보전 관리를 통해, 기계 및 시스템 고장으로 인한 생산활동에 악영향을 주는 요인들을 사전에 관리하여 조치 및\n대응해주는 서비스입니다.'
            },
            {
                moduleName: 'A-MS', fullName:'(AUTO Monitoring Service)', moduleImg: Images.fas.blurImage,
                moduleContent: `AUTO Monitoring Service는 가동중인 AUTO의 상태를 
                실시간으로 확인이 가능하며, 
                누적된 기계 상태를 통계 및 분석을 통해 관리할수있는 솔루션입니다.`,
                moduleContentM:'AUTO Monitoring Service는 가동중인 AUTO의 상태를 실시간으로 확인이 가능하며, 누적된 기계 상태를 통계 및 분석을 통해 관리 할 수 있는 솔루션입니다.'
            },
            {
                moduleName: 'A-BAS', fullName:'(AUTO Before/After Service)', moduleImg: Images.fas.blurImage,
                moduleContent: `AUTO Before/After Service는 가동중인 AUTO의 상태를 
                실시간으로 확인하며, 종합적인 AUTO의 예지보전 관리를 통해,
                 기계 및 시스템 고장으로 인한 생산활동에 악영향을 주는 요인들을 
                 사전에 관리하여 조치 및 대응을 해주는 서비스입니다.`,
                moduleContentM:'AUTO Before/After Service는 가동중인 AUTO의 상태를 실시간으로 확인하며, 종합적인 AUTO의 예지보전 관리를 통해, 기계 및 시스템 고장으로 인한 생산활동에 악영향을 주는 요인들을 사전에 관리하여 조치 및 대응해주는 서비스입니다.'
            },
        ]
    },
    {title:'기업형 & 클라우드 솔루션',
        sub:'Business & Cloud Solution',
        link:'/solution/cloud',
        contents:` `,
        img:'',
        bg:Images.bizCloud.bg,
        mainImg:solution_cloud,
        detail:[
            {specName:'Callback API\n지원',
                specImg:Images.bizCloud.callback,
                specImgM:Images.bizCloud.callbackM},

            {specName:'Back-up API\n지원',
                specImg:Images.bizCloud.backup,
                specImgM: Images.bizCloud.backupM
            },
            {specName:'Data 가공 지원\n(분석 및 AI)',
                specImg:Images.bizCloud.dataApi,
                specImgM:Images.bizCloud.dataApiM
            },
            ]
    },
    {title:'AI 솔루션',
        sub:'Artificial Intelligence Solution',
        contents:`  `,
        img:'',
        link:'',
        mainImg:solution_ai},

]


export const featureArray = [
    {
        img: Icons.data,
        title: '생산 및 보전 데이터 처리',
        bg: Images.data,
        content: '제품 생산 데이터 뿐만 아니라,\n입/출력, 파라미터 정보 , 공정 상태 정보 등 100여가지 정보를 가지고 있으며, \n연동 시 해당 정보를 가져갈 수 있습니다.'
    },
    {
        img: Icons.module,
        title: '모듈화로 인한 보전 편리',
        bg: Images.module,
        content: '제품 교체 시 고장 난 모듈만\n교체할 수 있도록 구성되어 있습니다.'
    },
    {
        img: Icons.low,
        title: 'PLC 대비 비용 절감',
        bg: Images.low,
        content: '스마트 팩토리 시대에 필요한 요소를 구성하기 위한 PLC 구성 품 대비 저렴하게 구축할 수 있습니다.'
    }
]
