import React, {useEffect} from 'react';
import styled from "styled-components";
import {LocationPositionProps} from "../types/Fundamental";

declare global {
    interface Window {
        kakao: any;
    }
}

// 나머지 TypeScript 코드

const LocationMap:React.FC<LocationPositionProps> = ({latitude,longitude}) => {
    const { kakao } = window;
    useEffect(()=>{
        const mapContainer = document.getElementById('map'), // 지도를 표시할 div
            mapOption = {
                center: new kakao.maps.LatLng(latitude, longitude), // 지도의 중심좌표
                level: 5 // 지도의 확대 레벨
            };
        const map = new kakao.maps.Map(mapContainer, mapOption);

        const mapTypeControl = new kakao.maps.MapTypeControl()
        map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT)
        const zoomControl = new kakao.maps.ZoomControl()
        map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT)
        const marker = new kakao.maps.Marker({
            position: map.getCenter()
        })
        marker.setMap(map)

    })

    return (
        <div style={{width:'100%', display:'flex',justifyContent:'center', boxSizing:'border-box'}}>
            <MapComponent id={'map'}/>
        </div>
    );
};

export default LocationMap;

const MapComponent = styled.div`
	width: 100%;
    max-width: 608px;
	max-height: 376px;
	height: 100%;
	align-self: center;
	border: 1px solid gray;
`