import React from 'react';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SectionContainer from "../styles/SectionContainer";
import MainScrollSection from "../containers/main/MainScrollSection";
import {useLocation, useParams} from "react-router-dom";
import ProductScrollSection from "../containers/productinfo/ProductScrollSection";
import AboutUsScrollSection from "../containers/aboutUs/AboutUsScrollSection";
import ProductDetailScrollSection from "../containers/productdetail/ProductDetailScrollSection";
import SolutionScrollSection from "../containers/smartsolution/SolutionScrollSection";
import {specArray} from "../common/DataSet";
import MainPageScrollSection from "./MainPageScrollSection";
import CustomerServiceScrollSection from "../containers/cs/CustomerServiceScrollSection";
import MobileMenuComponent from "../components/menu/MobileMenuComponent";
import CSchat from "../components/chat/CSchat";



const MainPage = () => {
    // const location = useLocation();
    // const { id } = useParams();
    // const main = location.pathname.includes('main');
    // const product = location.pathname.includes('product') && !id;
    // const productDetail = location.pathname.includes('product/') && id;
    // const about = location.pathname.includes('about')
    // const solution = location.pathname.includes('solution')
    // const cs = location.pathname.includes('cs')
    //
    //
    // let detailProduct = null;
    //
    // if((productDetail && id) && specArray){
    //     detailProduct = specArray.find(product => product.link === `/product/${id}`);
    // }




    // @ts-ignore

    return (

                <SectionContainer children={
                    <React.Fragment>
                       <MainPageScrollSection/>
                        {/*{about && <AboutUsScrollSection/>}*/}
                        {/*{cs && <CustomerServiceScrollSection/>}*/}
                        {/*{solution && <SolutionScrollSection/>}*/}
                        {/*{productDetail ? (*/}
                        {/*    <ProductDetailScrollSection productList={detailProduct}/>*/}
                        {/*) : (*/}
                        {/*    product && <ProductScrollSection productList={detailProduct}/>*/}
                        {/*)}*/}

                        {/*/!*<Footer/>*!/*/}

                    </React.Fragment>
                }/>



    );
};

export default MainPage;



