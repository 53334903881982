import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {ContentFrame} from "../../styles/Styles";
import AboutUsSubHeroSection from "./hero/AboutUsSubHeroSection";
import AboutUsMainHeroSection from "./hero/AboutUsMainHeroSection";
import ManagementPhilosophy from "./philosophy/ManagementPhilosophy";
import InNumberSection from "./number/InNumberSection";
import CompanyHistorySection from "./history/CompanyHistorySection";
import CertificationSection from "./certification/CertificationSection";
import VideoSection from "./footage/VideoSection";
import CompanyLogoSection from "./company/CompanyLogoSection";
import CompanyBrochureDownload from "./brochure/CompanyBrochureDownload";
import AddressSection from "./address/AddressSection";
import Aos from 'aos';
import 'aos/dist/aos.css';
import ReactPageScroller from "react-page-scroller";
import Footer from "../../components/footer/Footer";
import {useRecoilState} from "recoil";
import {currentPageScroll, scrollPageBlock} from "../../common/RecoilState";
import DownButton from "../../components/buttons/DownButton";
import {useLocation} from "react-router-dom";


const AboutUsScrollSection = ({pageScroll}:any) => {

    const addressRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (pageScroll ==='address' && addressRef.current) {
            addressRef.current.scrollIntoView({ behavior: 'auto' });
        }
    }, [])


    return (
        <ContentFrame>
            <ScrollSnapContainer>
                                <AboutUsMainHeroSection/>
                                <AboutUsSubHeroSection/>
                                <ManagementPhilosophy/>
                                <InNumberSection/>
                                <CompanyHistorySection/>
                                <CertificationSection />
                                {/*<VideoSection/>*/}
                                {/*<CompanyLogoSection/>*/}
                                <CompanyBrochureDownload/>
                               <div ref={addressRef}>
                                 <AddressSection/>
                               </div>

            </ScrollSnapContainer>

        </ContentFrame>
    );
};

export default AboutUsScrollSection;

const ScrollSnapContainer = styled.div`
  //scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
    
    
  box-sizing: border-box;
  scroll-behavior: smooth;
  //scroll-snap-stop: always;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & > div {
    scroll-snap-align: start;
  }
`;
