import React from 'react';
import styled from "styled-components";
import {ContainerProps} from "../../types/Fundamental";
import arrow from '../../asset/images/icons/chevron-down.svg'
import {specArray} from "../../common/DataSet";
import {ScrollLink} from "react-scroll";
import {Link} from "react-scroll";


interface Props {
    compType?: string
    productList?: any;
    onScrollToComponent?: (index: number) => void;


}

const ProductPanel: React.FC<Props> = ({compType, productList, onScrollToComponent}) => {
    const [panel, setPanel] = React.useState<boolean>(false)
    const [selected, setSelected] = React.useState<number>(-1)
    const [helper, setHelper] = React.useState<any>({text:''})

    //
    // const panelArr = [
    //     {
    //         title: '제품 유형 보기',
    //         sub: [
    //             {
    //                 category: '프레스 컨트롤러', link: '/product/SPMS_V3',
    //                 detail: [
    //                     {name: 'SPMS_V3_BASE', link: ''},
    //                     {name: 'SPMS_V3_CPU', link: ''},
    //                     {name: 'SPMS_V3_Input', link: ''},
    //                     {name: 'SPMS_V3_Output', link: ''},
    //                     {name: 'SPMS_V3_Front', link: ''},
    //                     {name: 'SPMS_V3_Spare', link: ''},
    //                 ]
    //             },
    //             {category: '프레스 로드모니터', link: ''},
    //             {category: '프레스 미스피더 & 하사점 검출장치', link: ''},
    //             {category: '프레스 슬라이드카운터', link: ''},
    //             {category: '프레스 광선식 안정기', link: ''},
    //             {category: '안전 솔루션', link: ''},
    //         ]
    //     },
    // ]
    //
    // // @ts-ignore
    // const detailArray = panelArr[0].sub[0].detail.map(item => item.name);
    const nameTranslate = (name: any) => {
        if (name === 'misfeeder') {
            return '미스피더'
        } else if (name === '1ch') {
            return `하사점 검출장치\n1채널`
        } else if (name === '2ch') {
            return '하사점 검출장치\n2채널'
        } else if (name === 'intergration') {
            return '통합 하사점\n검출장치'
        } else if (name === 'pressure') {
            return '압전압식\n로드모니터(별치형)'
        } else if (name === 'safety') {
            return 'AI 안전 솔루션'
        } else {
            return name
        }
    }


    const renderProductType = () => {
        const currentPageProductName = window.location.pathname.split('/').pop();
        return (


            <Container text={panel?"":"제품 유형 보기"}
                style={{
                    width: panel ? '400px' : '200px',
                    transform: panel ? ' translateX(0px)' : 'translateX(100px)',
                    position: 'fixed',
                    top: '200px',
                    right: '100px',
                    transition: 'all 0.5s ease-in-out',
                    zIndex: 200,
                }}>

                <div className={'open'}
                     onClick={() => setPanel(!panel)}>
                    <img src={arrow}

                         style={{
                        zIndex: 100, pointerEvents: 'auto',
                        transform: panel ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'all 0.5s ease-in-out'
                    }} alt="arrow"/>
                </div>

                <PanelComp style={{
                    width: panel ? '328px' : '0px',
                    overflow: 'hidden', transition: 'all 0.5s ease-in-out'
                }}>
                    <div className={'title'}>{'제품 유형 보기'}</div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '280px',
                        alignItems: 'start',
                        width: '100%',
                        padding: '8px 20%'
                    }}>
                        {productList?.map((v: any, index: number) =>
                            <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                <div className={'circle'} key={index} style={{
                                    backgroundColor: selected === index || currentPageProductName === v.productName ? '#006DFF' : '#1E222D',
                                    border: selected === index || currentPageProductName === v.productName ? '1px solid #006DFF' : '1px solid #484848',
                                }}
                                     onClick={() => {
                                         setSelected(index);
                                         window.location.href = v.link
                                     }}
                                ></div>
                                <div key={index} className={'sub-title'}
                                     style={{textDecoration: selected === index || currentPageProductName === v.productName ? 'underline' : ''}}

                                     onClick={() => window.location.href = v.link}>
                                    {nameTranslate(v.productName)}</div>
                            </div>
                        )}

                    </div>
                </PanelComp>
            </Container>


        )
    }
    const renderProductList = () => {

        const currentProductComp = productList.productComponents[selected];
        // @ts-ignore
        return (

            <Container text={panel?"":"구성품 보기"}
                style={{
                    width: panel ? '400px' : '200px',
                    transform: panel ? ' translateX(0px)' : 'translateX(100px)',
                    position: 'fixed',
                    top: '200px',
                    right: '100px',
                    transition: 'all 0.5s ease-in-out',
                    zIndex: 200,
                }}>
                {/*<div style={{*/}
                {/*    fontSize: '14px',*/}
                {/*    color: '#fff',*/}
                {/*    width: '80px',*/}
                {/*    fontWeight: 'bold',*/}
                {/*    textShadow: '0px 3px 6px #1E222D',*/}
                {/*    position: 'relative',*/}
                {/*    right: '5px',*/}
                {/*    opacity: !panel ? 1 : 0,*/}
                {/*    zIndex: 300,*/}
                {/*    transition: 'all 0.5s ease-in-out',*/}
                {/*    transform: !panel ? 'translateX(0px)' : 'translateX(100px)',*/}
                {/*}}>*/}
                {/*    {'구성품 보기'}*/}
                {/*</div>*/}
                <div className={'open'} onClick={() => setPanel(!panel)}>
                    <img src={arrow} style={{
                        zIndex: 300,
                        cursor: 'pointer',
                        pointerEvents: 'auto',
                        transform: panel ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'all 0.5s ease-in-out'
                    }} alt="arrow"/>
                </div>
                <PanelComp
                    style={{
                        width: panel ? '328px' : '0px',
                        overflow: 'hidden', transition: 'all 0.5s ease-in-out'


                    }}>
                    <div className={'title'}>{'SPMS_V3 단품 바로보기'}</div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '280px',
                        alignItems: 'start',
                        width: '100%',
                        padding: '8px 20%',
                    }}>
                        {productList.productComponents?.map((v: any, i: number) =>
                            <Link to={`component-${i}`}
                                  spy={true}
                                  smooth={true}
                                  duration={700}
                                  offset={-150}
                                  onClick={() => {
                                      setSelected(i);
                                      onScrollToComponent && onScrollToComponent(i);
                                  }}>
                                <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                    <div
                                        className={'circle'}
                                        style={{
                                            backgroundColor: selected === i || currentProductComp === v.title ? '#006DFF' : '#1E222D',
                                            border: selected === i || currentProductComp === v.title ? '1px solid #006DFF' : '1px solid #484848',
                                        }}
                                        onClick={() => {
                                            setSelected(i);
                                            onScrollToComponent && onScrollToComponent(i);
                                        }}
                                    />
                                    <div
                                        key={i}
                                        className={'sub-title'}
                                        style={{textDecoration: selected === i || currentProductComp === v.title ? 'underline' : ''}}>
                                        {v.title}
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                </PanelComp>
            </Container>

        )
    }


    return (
        <>
            {compType === 'type' && renderProductType()}
            {compType === 'list' && renderProductList()}
        </>
    );
};
export default ProductPanel;





const Container = styled.div<ContainerProps>`
    //position: fixed;
    justify-content: center;
    align-items: center;
    display: flex;
    //top: 0;
    //right: 0px;

 
  


    @media (max-width: 1440px) {
        right: 100px;

    }


    .open {
        //position: absolute;
        //left:0;
        //top: 230px;
        cursor: pointer;
        width: 32px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
        border-radius: 16px 0 0 16px;
        background-color: rgba(255, 255, 255, 0.3);
        //border: 1px solid #000022;
        box-shadow: 0 3px 6px #00000029;
        
        
        &:hover {
            
            &::before {
                content: ${props => `"${props.text}"`};
                position: absolute;
                left: 0;
                display: flex;
                font-size: 14px;
                color: #fff;
                width: 80px;
                font-weight: bold;
                text-shadow: 0px 3px 6px #1E222D;
                transition: all 0.5s ease-in-out;
                opacity: ${props => props.text ? 1 : 0};
                
                 
            }
        }

    }


`

const PanelComp = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    z-index: 100;
    width: 328px;
    height: 100%;
    overflow: visible;
    //min-height: 616px;
    //max-height: 616px;
    background-color: #1E222D;
    border-radius: 16px 16px 16px 16px;
    box-shadow: 0 3px 6px #00000029;
    box-sizing: border-box;


    .title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        display: flex;
        width: 328px;
        align-self: center;
        height: 65px;
        color: #fff;
        border-radius: 16px 16px 0 0;
        background-color: #1E222D;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba(224, 224, 224, 0.5);
    }

    .sub-title {
        font-size: 16px;
        color: ${props => props.selected ? '#fff' : '#E0E0E0'};
        display: flex;
        align-items: center;
        height: 65px;
        justify-content: center;
        background-color: #1E222D;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-shadow: 0 3px 6px #00000029;
        cursor: pointer;
        border: 1px solid #484848;
        background-color: ${props => props.selected ? '#006DFF' : '#1E222D'};

        &:hover {
            background-color: #006DFF;
        }

    }


`


