import React, {useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import bg from "../../../asset/images/bg/AboutBrochure.webp";
import Buttons from "../../../components/buttons/Buttons";
import Aos from "aos";

const CompanyBrochureDownload = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 769px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    useEffect(() => {
            Aos.init({duration: 1500
            });
        }
        ,[])


    return (
        <MainContainer
            height={'100%'}
            backgroundImage={bg}
            justifyContent={'center'}>
            <div
                className={'picture'}
                style={{
                    padding: isDesktop ? '0 16%' : '0 6%',
                    justifyContent: 'center',
                    height: '60vh',
                    backgroundPosition: isDesktop ? 'center' : '80% 0%',
                    backgroundSize: 'cover'
                }}
            >
                <div style={{
                    maxWidth: '1232px', alignSelf: 'center',
                    margin: isDesktop ? '0% auto' : '0 6%',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div className={'hero-main'}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: isDesktop ? '60px' : '30px',
                                padding: '0',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <div
                                style={{
                                    width: isDesktop ? '816px' : '312px',
                                    height: isDesktop ? '472px' : '304px',
                                    background: 'rgba(255,255,255,0.4)',
                                    backdropFilter: 'blur(10px), brightness(1.5)',
                                    borderRadius: '24px',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    display: 'flex',
                                    boxSizing: 'border-box'
                                }}>
                                <div style={{
                                    fontSize: 'clamp(24px, 3vw, 48px)',
                                    fontWeight: 'bold',
                                    color: '#fff'
                                }}>
                                    {'기업 소개서 다운받기'}
                                </div>
                                <div style={{
                                    fontSize: 'clamp(24px, 3vw, 48px)',
                                    fontWeight: 'bold',
                                    color: '#fff',
                                    textAlign: 'center',
                                    marginBottom: isDesktop ? '60px' : '24px'
                                }}>
                                    {'Company Brochure Download'}
                                </div>
                                <div
                                    style={{display: 'flex', flexDirection: isDesktop ? 'row' : 'column', gap: '20px'}}>
                                    <Buttons iconWidth={isDesktop ? '48px' : '40px'}
                                             buttonType={'download'}
                                             width={isDesktop ? '272px' : '168px'}
                                             height={isDesktop ? '64px' : '48px'}
                                             fontSize={isDesktop ? '24px' : '16px'}
                                             text={'한국어'}
                                    />
                                    <Buttons iconWidth={isDesktop ? '48px' : '40px'}
                                             buttonType={'download'}
                                             width={isDesktop ? '272px' : '168px'}
                                             height={isDesktop ? '64px' : '48px'}
                                             fontSize={isDesktop ? '24px' : '16px'}
                                             text={'English'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainContainer>
);
}

export default CompanyBrochureDownload;
