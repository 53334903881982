import React, {useEffect} from 'react';
import {MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import Aos from "aos";
import NewsSwiperContainer from "../../../components/swipers/NewsSwiperContainer";
import PRswiperContainer from "../../../components/swipers/PRswiperContainer";
import {newsData} from "../../../common/DataSet";
import NewsroomSlideComp from "../../../components/slides/NewsroomSlideComp";
import PRSwiperContainer from "../../../components/swipers/PRswiperContainer";
import styled from "styled-components";
import Notiflix from "notiflix";
import {useTranslation} from "react-i18next";




const MainPRSection = () => {
    const {t}:any =useTranslation();

    const isDesktop = useMediaQuery({
        query: "(min-width: 1339px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)",
    })

    const tempValidate = (link: string) => {
        if (link === '') {
            Notiflix.Report.info('준비중...', '', 'OK');
        } else {
            window.location.href = link;
        }
    }

    return (
        <MainContainer
            style={{
                background: '#EDF5FF',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center',
                height: isDesktop ? '100%' : '100%',
                padding: isDesktop?'200px 16%':'80px 10%'
            }}>
            <div style={{maxWidth:'1232px',minWidth:'300px',width:'100%',alignSelf:'center'}}>
                <div>
                    <div style={{
                        fontSize: 'clamp(24px, 3vw, 24px)',
                        fontWeight: 'bold',
                        color: '#006DFF',
                    }}>
                        {t('자료실')}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', margin: '20px 0'}}>
                        <div style={{
                            fontSize: 'clamp(18px, 3vw, 24px)',
                            fontWeight: 'bold',
                            color: '#000'
                        }}>
                            {t('홍보자료')}
                        </div>
                        <div onClick={() => tempValidate('')}
                             style={{
                                 color: '#006DFF',
                                 fontSize: 'clamp(16px, 4.8vw, 16px)',
                                 display: !isDesktop ? 'none' : 'flex',
                                 justifySelf: 'end',
                                 whiteSpace: 'nowrap',
                                 cursor: 'pointer'
                             }}>
                            {t('홍보자료 더보기>')}
                        </div>
                    </div>
                </div>

                {isDesktop ?
                    <div style={{
                        boxSizing: 'content-box', padding: '0 10%',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                        <PRSwiperContainer/>
                    </div>
                    :
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '36px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '0px 5%',
                        marginBottom: '40px',
                    }}>
                        {newsData.slice(0, 2).map((data, index) => {
                                return (
                                    <NewsroomSlideComp title={data.title} image={data.img} link={data.link}/>
                                )
                            }
                        )}
                        <div onClick={() => tempValidate('')}

                             style={{
                                 color: '#006DFF',
                                 fontSize: 'clamp(16px, 4.8vw, 16px)',
                                 display: 'flex',
                                 justifySelf: 'end',
                                 alignSelf: 'flex-end',
                                 whiteSpace: 'nowrap',
                                 cursor: 'pointer'
                             }}>
                            {t('홍보자료 더보기>')}
                        </div>
                    </div>

                }
            </div>

        </MainContainer>
);
};

export default MainPRSection;

