import React from 'react';
import styled from "styled-components";
import {ContainerProps} from "../../types/Fundamental";
import {menuArr} from "../../common/DataSet";
import {clamp} from "react-number-format/types/utils";
import {useMediaQuery} from "react-responsive";
import Notiflix from "notiflix";
import FinancialStatements from "../pop/IRModal";
import {useTranslation} from "react-i18next";


interface MenuProps {
    compType?: string
    openModal?: () => void
}

const FooterMenuComponent: React.FC<MenuProps> = ({compType, openModal}) => {

    const {t}:any = useTranslation();
    const [modalOpen, setModalOpen] = React.useState(false);
    const isDesktop = useMediaQuery({minWidth: 1025})
    const columnGap = isDesktop ? 'clamp(40px,2vw,80px)' : 'clamp(20px,2vw,40px)';
    const tempValidate = (link: string, menu?: string) => {
        if (link === '') {
            Notiflix.Report.info('준비중...', '', 'OK');
        } else if (menu === 'IR') {
            setModalOpen(!modalOpen)
        } else {
            window.location.href = link;
        }
    }
    const renderDesktop = () => {
        return (

            <MenuContainer padding={'100px 0 100px 0'}>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4,1fr)',
                    gridAutoFlow: 'row',
                    // display:'flex',
                    // flexFlow:'row wrap',
                    // rowGap:'40px',
                    gridColumnGap: columnGap,
                    alignItems: 'baseline'
                }}>
                    {menuArr.map((item, index) =>
                        <div key={index} style={{
                            display: 'flex', flexDirection: 'column', gap: '10px',
                            minWidth: 'clamp(180px, 12.5vw, 230px)'
                            // minWidth:isDesktop? '230px':'300px'
                        }}>
                            <div className={'menu-title'}
                                 onClick={() => window.location.href = item.link}>{t(item.title)}</div>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                                {item?.subMenu?.map((v, i) =>
                                    <div key={i} className={'sub-menu'}
                                         onClick={ ()=>{
                                             tempValidate(v.link, v.menu)
                                         }}>
                                        {t(v.menu)}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </MenuContainer>

        )
    }
    const renderMobile = () => {
        return (
            <MenuContainer padding={'50px 0 50px 0'}>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'baseline'}}>
                    {menuArr.map((item, index) =>
                        <div key={index}
                             style={{display: 'flex', flexDirection: 'column', gap: '10px', minWidth: '280px'}}>
                            <div className={'menu-title'}
                                 onClick={() => window.location.href = item.link}>{t(item.title)}</div>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '2px', minWidth: '280px'}}>
                                {item?.subMenu?.map((v, i) =>
                                    <div key={i} className={'sub-menu'}
                                         onClick={ ()=> {
                                             tempValidate(v.link, v.menu)
                                         }}>
                                        {t(v.menu)}</div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </MenuContainer>
        )
    }

    return (
        <>
            {modalOpen &&
                <FinancialStatements closeModal={() => setModalOpen(!modalOpen)}/>
            }
            {compType === 'desktop' ? renderDesktop() : renderMobile()}
        </>


    );
};

export default FooterMenuComponent;

const MenuContainer = styled.div<ContainerProps>`

    background-color: #000000;
    display: flex;
    padding: ${props => props.padding};
    width: 100%;
    box-sizing: border-box;
    flex-direction: ${props => props.flexDirection};
    color: #E0E0E0;
    white-space: pre-line;
    line-height: 1.8;

    .menu-title {
        font-size: clamp(12px, 3.6vw, 20px);
        font-weight: 700;

        &:hover {
            cursor: pointer;
            color: #fff;
            text-decoration: underline;
        }
    }

    .sub-menu {
        font-size: clamp(12px, 3.6vw, 20px);
        font-weight: normal;
        color: #AAAAAA;
        cursor: pointer;

        &:hover {
            color: #006DFF;
            text-decoration: underline;
        }
    }


`
