import React, {useState} from 'react';
import styled from "styled-components";
import Buttons from "../buttons/Buttons";
import {useMediaQuery} from "react-responsive";
import {ContainerProps} from "../../types/Fundamental";
import Notiflix from "notiflix";

interface ProductSliderProps{
    solutionType?:string
    title?:string
    sub?:string
    content?:string
    image?:string
    mainImg?:string
    compType?:any
    backgroundColor?:string
    clickEvent?:any
    link?:any

}

const SmartSolutionSlideComp:React.FC<ProductSliderProps> = ({solutionType,title,sub,link,
                                                                 content,image,compType,mainImg, clickEvent,

                                                                 backgroundColor}) => {

    const [selected, setSelected] = useState(false);
    const isDesktop = useMediaQuery({
        query: "(min-width: 821px)",
    });


    const isMobile = useMediaQuery({
        query: "(max-width:1024px)"
    });

    const tempValidate = (link: string) => {
        if (link === '') {
            Notiflix.Report.info('준비중...', '', 'OK');
        } else {
            window.location.href = link;
        }
    }

    const renderDesktop = () => (
        <Container selected={selected}>
            <div style={{ display:'flex', flexDirection: 'row'}}>
            <div className={'text_wrapper'}
                 onClick={()=>setSelected(!selected)}>
                <div className={'text'} style={{display:'flex' ,width:'100%',
                    flexDirection:'column',justifyContent:'space-between',height:'100%'}}>
                     <div className={'text_title'} style={{ fontSize:'clamp(24px, 2vw, 32px)'}}>
                         {title}
                            <div className={'text_sub'} style={{fontWeight:'normal',marginBottom:'20px'}}>{sub}</div>
                            <div className={'text_sub'} style={{textAlign:'left'}}>{content}</div>
                     </div>
                    {image &&
                    <img src={image} alt=" " style={{
                        width: '328px' ,height: 'auto',
                        maxWidth: '100%', maxHeight: '100%', minWidth: '328px',
                        objectFit: 'contain', objectPosition: 'center' }}/>}
                    <div className={'text-sub'} onClick={()=>tempValidate(link)}
                         style={{ alignSelf:'flex-end',fontSize: '18px', color: '#fff',marginTop: '0px',textDecoration:'underline'}}>
                        {'자세히 보기 >'}
                    </div>
                  </div>
             </div>

                <div className={'image-wrapper'}>
                    <img src={mainImg} alt=" "/>
                </div>
            </div>


        </Container>
    );

    const renderMobile = () => (
        <Container selected={selected}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
               <div className={'m-image-wrapper'}>
                <img src={mainImg} alt="product" style={{
                    width: '100%', height: '240px',
                    objectFit: 'cover', objectPosition: 'center',
                }} />
               </div>

            <div className={'m-text_wrapper'}>
                <div className={'text'}>
                    <div className={'text_title'} style={{ fontSize:'clamp(20px, 2vw, 24px)' }}>{title}</div>
                    <div className={'text_sub'} style={{ fontSize: '14px' }}>{sub}</div>
                </div>
                <div className={'text-sub'} onClick={()=>tempValidate(link)}
                     style={{
                    fontSize: '14px', color: '#fff', marginBottom: '20px',
                    textAlign: 'right',
                }}>
                    {'더보기 >'}
                </div>
            </div>
        </div>
        </Container>
    );

    return (
           <>
               {compType === 'desktop' ? renderDesktop() : renderMobile()}
            </>
        );
    }


export default SmartSolutionSlideComp;

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  // width: ${props => props.width};
  // min-height: ${props => props.height};
  box-sizing: border-box;
  border-radius: 24px;
  
  

  .text_wrapper {
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 24px 80px 40px;
    width: 400px;
    height: auto;
    box-sizing: border-box;
    border-radius: 24px 0 0 24px;
    transition: all 0.3s ease-in-out;
    background-color: ${props => props.selected ? '#003A88' : '#1E222D'};
    
    &:hover {
      cursor: pointer;
      background-color: #006DFF;
    }
  }

  

  .m-text_wrapper {
    display: flex;
    padding: 0 24px;
    flex-direction: column;
    box-sizing: border-box;
    width: 264px;
    height: 176px;
    background-color: ${props => props.selected ? '#003A88' : '#1E222D'};
    border-radius: 0 0 24px 24px;

    &:hover {
      cursor: pointer;
      background-color: #006DFF;
    }
  }

  .text {
    color: #ffffff;
    &_title {
      font-weight: 800;
      margin-bottom: 14px;
      margin-top: 50px;
    }
    &_sub {
      font-size: 20px;
      font-weight: normal;
      white-space: pre-line;
    }
  }

  .image-wrapper {
    
    width: 624px;
    height: 544px;
    box-sizing: border-box;
    overflow: clip;
    border-radius: 0px 24px 24px 0px;
    transition: all 0.5s ease-in-out;
    
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
  }

  .m-image-wrapper {
    display: flex;
    width: 100%;
    height:240px;
    max-width: 264px;
    box-sizing: border-box;
    overflow: clip;
    background: #EDF5FF;
    border-radius: 24px 24px 0px 0px;
    transition: all 0.5s ease-in-out;
  }
  
  
  @media only screen and (max-width: 1024px) {
    .text_wrapper {
        width: 320px;
        height: 500px;
    }
    .image-wrapper {
        width: 500px;
        height: 500px;
    }
  }



`