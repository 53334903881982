import React from 'react';
import {ProductType} from "../../../types/Fundamental";
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";


interface Props {
    productList?: ProductType |any;
}

const DieHeight1chComponentSection:React.FC<Props> = ({productList}) => {

    const isDesktop = useMediaQuery({
        query: "(min-width: 1440px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    const nameTranslate = (name:any) => {
        if (name === '1ch') {
            return '하사점 검출장치 1채널'
        } else if (name === '2ch') {
            return '하사점 검출장치 2채널'
        } else
            return name
    }

    const renderDesktop = () => {
    return (
        <MainContainer
            style={{
                background: '#fff',
                padding: isDesktop ? '200px 16%' : '100px 10%',
                flexDirection: 'column',
                justifyContent: 'center',
                height: isDesktop ? '100%' : isMobile ? '100%' : '100%'
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div style={{
                    display: 'flex', fontSize: 'clamp(18px, 3vw, 24px)',
                    color: '#006DFF', fontWeight: 'bold', marginBottom: '40px'
                }}>
                    {`${nameTranslate(productList?.productName)} 부속품`}
                </div>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    height: '100%',
                    gap: '20px',
                    justifyContent: 'center'

                }}>
                    {productList?.productComponents?.map((v: any, index: number) => {
                        if (index <= 2) {
                            return (

                                <div style={{
                                    width: '30%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                    alignItems: 'center'
                                }}>
                                    <ImageContainer>
                                        <img src={v.img}
                                             style={{
                                                 width: '100%',
                                                 height: '100%',
                                                 objectFit: 'contain',
                                                 objectPosition: 'center',
                                                 boxSizing: 'content-box'
                                             }}/>
                                    </ImageContainer>
                                    <div style={{
                                        fontSize: 'clamp(16px, 2.5vw, 24px)',
                                        fontWeight: 'bold', color: '#1E222D'
                                    }}>
                                        {v.title}
                                    </div>
                                    {v.title.includes('2EA') && (
                                        <div style={{position: 'relative', zIndex: 1}}>
                                            <EaContainer>{'X2'}</EaContainer>
                                        </div>
                                    )}
                                    {v.title.includes('4EA') && (
                                        <div style={{position: 'relative', zIndex: 1}}>
                                            <EaContainer>{'X4'}</EaContainer>
                                        </div>
                                    )}
                                </div>

                            )
                                ;
                        } else {
                            return (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                    width: '40%',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <ImageContainer horizontal={true}>
                                        <img src={v.img}
                                             style={{
                                                 width: '100%',
                                                 height: '100%',
                                                 objectFit: 'contain',
                                                 objectPosition: 'center',

                                             }}/>
                                    </ImageContainer>
                                    <div style={{
                                        fontSize: 'clamp(16px, 2.5vw, 24px)',
                                        fontWeight: 'bold', color: '#1E222D'
                                    }}>
                                        {v.title}
                                    </div>
                                </div>


                            )
                        }
                    })
                    }
                </div>
            </div>
        </MainContainer>
    )
    }


    const renderMobile = () => {
        return (
            <MainContainer
                style={{
                    background: '#fff',
                    padding: '100px 10% 60px 10%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                }}>
                <div
                    style={{
                        display: 'flex',
                        fontSize: 'clamp(16px, 3vw, 24px)',
                        color: '#006DFF',
                        fontWeight: 'bold',
                        marginBottom: '80px'
                    }}>
                    {`${nameTranslate(productList?.productName)} 부속장치`}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '0px'}}>
                    {productList?.productComponents?.map((v: any, index: number) =>
                        <div
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                             }}>
                            <SpecContainer>
                                <div className={'title'} style={{fontSize: '16px', height: '48px'}}>
                                    {v.title.slice(1, 20)}
                                </div>
                            </SpecContainer>
                            <ImageContainer style={{border: 'none'}}>

                                {v.title.includes('방진고무') ?
                                    <img src={v.img} alt="" style={{
                                        objectFit: 'contain', left: '30%', position: 'absolute',
                                        width: '120px', height: '100%'
                                    }}/>
                                    :
                                    <img src={v.img} alt=""
                                         style={{objectFit: 'contain', width: '100%', height: '100%'}}/>
                                }

                            </ImageContainer>
                            {v.title.includes('2EA') && (
                                <div style={{position: 'relative', zIndex: 1}}>
                                    <EaContainer>{'X2'}</EaContainer>
                                </div>
                            )}
                            {v.title.includes('4EA') && (
                                <div style={{position: 'relative', zIndex: 1}}>
                                    <EaContainer>{'X4'}</EaContainer>
                                </div>
                            )}
                            <div style={{
                                marginBottom: '60px',
                                display: 'flex',
                                width: '100%',
                                maxWidth: '312px',
                                height: '1px',
                                backgroundColor: '#E0E0E0'
                            }}></div>

                        </div>
                    )}


                </div>
            </MainContainer>
        )
    }

    return (
        <>
            {isDesktop ? renderDesktop() : renderMobile()}

        </>
);
};

export default DieHeight1chComponentSection;

const ImageContainer = styled.div <{ horizontal?: boolean }>`
    display: flex;
    box-sizing: content-box;
    width: ${props => props.horizontal ? '504px' : '296px;'};
    height: ${props => props.horizontal ? '296px' : '408px;'};
    align-items: center;
    border: 1px solid #E0E0E0;
    position: relative;
    margin-left : 20px;
    
    @media (max-width: 768px) {
        width: ${props => props.horizontal ? '250px' : '200px;'};
        height: ${props => props.horizontal ? '150px' : '276px;'};
    }
    
    `

const EaContainer = styled.div`
    border-radius: 50%;
    background-color: #EDF5FF;
    color: #006DFF;
    width: 66px;
    height: 66px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(24px, 3vw, 30px);
    position: absolute; /* 절대 위치 설정 */
    top: -200px; /* 이미지 상단에 위치하도록 조정 */
    left: 80px;
    transform: translateX(-0%); /* X축 중앙 정렬을 위한 변환 */

    @media (max-width: 768px) {
        width: 44px;
        height: 44px;
        font-size: 20px;
        top: -138px;
        left: 50px;
    }
`;


const SpecContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    //width: 752px;
     
    justify-content: center;
    
    @media (max-width: 768px) {
        //width: 100%;
        
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
        width: 100%;
        min-width: 280px;
        max-width: 312px;
        height: 56px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 16px 0px;
        box-sizing: border-box;
        background-color: #484848;
        color: #fff;
        border-radius: 28px;
    }

    .sub-title {
        font-size: 20px;
        font-weight: bold;
        color: #1E222D;
        border: 1px solid #006DFF;
        border-radius: 20px;
        padding: 0 16px;
        text-align: center;
        width: 104px;
        height: 40px;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: center;
    }


`
