import React from 'react';
import {SwiperContainer} from "../../styles/Styles";
import NewsroomSlideComp from "../slides/NewsroomSlideComp";
// @ts-ignore
import {Swiper, SwiperOptions, SwiperSlide} from "swiper/react";
import {newsData} from "../../common/DataSet";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import styled from "styled-components";

const PRSwiperContainer = () => {
    const swiperRef = React.useRef(null);
    const swiperParams :SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        slidesPerView: 3,
        spaceBetween:0,
        loop: true,
        initialSlide: 0,
        autoplay: {
            delay: 2000,
        },
        breakpoints: {
            1441: {
                slidesPerView: 3,
                spaceBetween:20
            },
            }
        }





    return (
    <PrSwiperContainer>
                <Swiper ref={swiperRef} {...swiperParams}  className={'swiper-container'}>
                    {newsData.map((data,index)=>
                            <SwiperSlide key={index} className={'swiper-slide'}>
                                <NewsroomSlideComp title={data.title} image={data.img} link={data.link}/>
                            </SwiperSlide>
                        )
                    }

                </Swiper>
    </PrSwiperContainer>

    );
};

export default PRSwiperContainer;


const PrSwiperContainer = styled.div`
    //display: flex;
    //margin: 0 auto;
    //width: 100%;
    //justify-content: center;
    margin-top: 20px;
 
    
    .swiper-container{
        width: 100%;
        max-width: 1240px;
        max-height: 100%;
       
     
        justify-self: center;
        align-self: center;
        
        //@media (min-width: 1441px){
        //    max-width: 1300px;
        //}
        
        @media (max-width: 1440px) {
           
            width: 1000px;
            
            
        }

    }
    .swiper-slide{
        width: 100%;
        max-width:400px;
        height: 360px;
        align-items: center;
        justify-content: center;
        //display: flex;
        //flex-direction: column;
        //gap: 10px;
        //align-items: baseline;
        //transition: all 0.5s ease;
    }
    .swiper-wrapper{
        width: 100%;
        //max-width: 1920px;
        ////width: auto;
        //height: 360px;
        
    }
    
    .swiper-slide-active{
        width: 100%;
           max-width: 400px;
        height: 360px;
        //display: flex;
        //flex-direction: column;
      
        //align-items: baseline;
        //transition: all 0.5s ease;
    }
    
    
    `