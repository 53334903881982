import React, {useEffect} from 'react';
import {MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import bg from "../../../asset/images/bg/productMain.jpeg";
import Aos from "aos";
import 'aos/dist/aos.css';
import ProductPanel from "../../../components/panel/ProductPanel";

const ProductHeroSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 769px)",
    });

    // const isTablet = useMediaQuery({
    //     query: "(max-width: 1023px)",
    // });

    const isMobile = useMediaQuery({
        query: "(max-width: 768px)",
    });

    useEffect(() => {
            Aos.init({duration: 1000
            });
        }
        ,[])

    const backgroundStyle = {
        backgroundImage: `url(${bg})`,
        // padding: isDesktop ? '0 16%' : '0 6%',
        backgroundSize: isDesktop? 'cover':'cover',
        width: '100%',
        height: isMobile ? '100vh':'1080px',
        backgroundPosition: isDesktop ? 'center' : 'top',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',

    };

    return (
        <MainContainer
            justifyContent={'center'} alignItems={'center'}
            height={'100%'}>
            <div className={'picture'} style={
                backgroundStyle}>
                <div style={{
                    maxWidth: '1232px', alignSelf: 'center',
                    padding: isDesktop ? '20% 16%' :'0 5%',
                    margin: isDesktop ? '0% auto' : '0 5%',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div className={'hero-main'}>
                        <div data-aos={'fade-down'}>
                            <div className={'hero_text'}
                                 style={{
                                     display: '-webkit-box',
                                     WebkitLineClamp: 2,
                                     WebkitBoxOrient: 'horizontal',
                                     fontSize: 'clamp(32px, 5vw, 64px)',
                                     maxWidth: isDesktop ? '770px' : '300px',
                                     fontWeight: 'bold',
                                     overflow: 'visible',
                                     whiteSpace: isDesktop ? 'normal' : 'pre-line',
                                     letterSpacing: ' 0.0em',
                                 }}>
                                {isDesktop ?
                                    <>
                                        {'사용자 맞춤 최적의 솔루션으로 스마트하게 Upgrade!'}
                                    </>
                                    :
                                    <>
                                        {'사용자 맞춤 최적의\n솔루션으로 스마트하게 Upgrade!'}
                                    </>}
                            </div>
                        </div>
                        <div data-aos={'fade-up'} data-aos-delay={'500'}>
                            <div className={'hero_text'}
                                 style={{
                                     display: '-webkit-box',
                                     WebkitLineClamp: 3,
                                     marginTop: isDesktop ? '40px' : '20px',
                                     WebkitBoxOrient: 'horizontal',
                                     maxWidth: isDesktop ? '820px' : '312px',
                                     maxHeight: isDesktop ? '122px' : '96px',
                                     overflow: 'visible',
                                     whiteSpace: 'pre-line',
                                     fontWeight: '400',
                                     fontSize: 'clamp(16px, 3vw, 32px)',
                                     lineHeight: 1.3,
                                 }}>
                                {'현장 분석으로 사용자와 사용 환경을 이해하고 우리 업체에 맞는 최적의 솔루션 도입으로 공정을 개선합니다.'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </MainContainer>
    );
};

export default ProductHeroSection;

