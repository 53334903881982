import React, {useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import Aos from "aos";
import SolutionSwiperContainer from "../../../components/swipers/SolutionSwiperContainer";
import {solutionData} from "../../../common/DataSet";
import {SwiperSlide} from "swiper/swiper-react";
import SmartSolutionSlideComp from "../../../components/slides/SmartSolutionSlideComp";
import bg1 from "../../../asset/images/bg/product_cta_bg.png";
import Buttons from "../../../components/buttons/Buttons";
import bgM from "../../../asset/images/bg/product_cta_mobile.png";



const SolutionInfoSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1023px)",
    }
    );
    const isTablet = useMediaQuery({
        query: "(min-width: 769px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    }
    );


    useEffect(() => {
            Aos.init({duration: 1000
            });
        }
        ,[])

    const backgroundStyle = {
        backgroundSize: isDesktop?'cover':'auto 46vh',
        width: '100%',
        backgroundPosition: isDesktop ? 'center' : '60% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
    };

    return (
        <MainContainer justifyContent={'center'} height={'100%'}
                       flexDirection={'column'}>
            <div style={{
                maxWidth: '1920px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div
                    style={{
                        display: 'flex', justifyContent: 'center',
                        flexDirection: 'column',
                        padding: isDesktop ? '200px 18%' : '100px 10%',
                    }}>
                    <div style={{
                        fontWeight: 'bold', fontSize: 'clamp(18px, 4vw, 24px)', color: '#006DFF',
                        marginBottom: '40px',
                    }}>
                        {'스마트 솔루션'}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: isMobile ? '30px' : '60px'}}>
                        {solutionData.map((item, index) => {
                            return (
                                <div data-aos={!isDesktop ? '' : 'fade-up'} key={index}>
                                    <SmartSolutionSlideComp compType={isMobile ? 'mobile' : 'desktop'}
                                                            image={item.img} link={item.link} content={item.contents}
                                                            title={item.title} sub={item.sub} solutionType={item.title}
                                                            mainImg={item.mainImg}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>


        </MainContainer>
);
};


export default SolutionInfoSection;
