import React from 'react';
import styled from 'styled-components'
import Arrow from '../../asset/images/icons/chevron-left.svg'

interface Props {
    arrowDirection: string
    disabled?: boolean
    deleted?: boolean
    arrowMove?: boolean
    hoverDisabled ?:boolean
    clickEvent?: () => void
}

const ArrowButton:React.FunctionComponent<Props> = ({arrowDirection, hoverDisabled, arrowMove,
                                                        disabled,clickEvent, deleted}) => {
    return (
        <BaseComponent icon={Arrow} disabled={disabled} deleted={deleted} onClick={clickEvent} arrowMove={arrowMove} hover={hoverDisabled}
                       direction={arrowDirection === 'left'? {z:0, y:0} :
                           arrowDirection === 'right'? {z:0, y:180} :
                               arrowDirection === 'top'? {z:90, y:0} :
                                   arrowDirection === 'bottom'? {z:90, y:180} : {z:0, y:0}}/>
    );
};

export default ArrowButton;

interface ImageProps {
    icon: string
    direction: {z:number, y:number}
    disabled?: boolean
    deleted?: boolean
    arrowMove?: boolean
    hover?:boolean
}

const BaseComponent = styled.div<ImageProps>`
	display: ${props => props.deleted ? 'none' : 'block'};
	width: 24px;
	height: 24px;
	background-image: url('${props => props.icon}');
	cursor: pointer;
	transition: all 0.3s;
	opacity: ${props => props.disabled ? 0.3 : 1};
	transform: rotateZ(${props => props.direction.z}deg) rotateY(${props => props.direction.y}deg);

	animation: 1.5s ease-in-out infinite ${props => props.arrowMove ? 'moveArrow' : ''};
	@keyframes moveArrow {
		0% {
			transform: translateX(-8px) rotateZ(180deg);
		}
		50% {
			transform: translateX(2px) rotateZ(180deg);
		}
		100% {
			transform: translateX(-8px) rotateZ(180deg);
		}
	}

	&:hover {
		background-color: ${props=>props.hover ? 'rgba(0, 88, 255,0.05)' : "transparent"};
		border-radius: ${props=>props.hover ? "6px" : "0"};
		box-sizing: ${props=>props.hover ? 'border-box' :"auto"};
	}
`
