import React, {useEffect} from 'react';
import {MainContainer} from "../../../styles/Styles";
import bg1 from "../../../asset/images/bg/product_cta_bg.png";
import bgM from "../../../asset/images/bg/product_cta_mobile.png";
import bg2 from "../../../asset/images/bg/ProductCta1.png";
import Buttons from "../../../components/buttons/Buttons";
import {useMediaQuery} from "react-responsive";
import Aos from "aos";

const ProductCtaSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 820px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:430px)"
    });

    useEffect(() => {
            Aos.init({duration: 2000
            });
        }
        ,[])

    const backgroundStyle = {
        backgroundSize: isDesktop ? 'cover' : 'cover',
        width: '100%',
        backgroundPosition: isDesktop ? 'center' : '60% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
    };

    // const backgroundStyle2 = {
    //     backgroundSize: 'cover',
    //     width: '100%',
    //     maxWidth: '100%',
    //     backgroundPosition: isDesktop ? 'center' : '60% 50%',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundClip: 'content-box',
    //
    // };


    return (
        <MainContainer height={'100%'}
                       alignItems={'center'}
                       width={'100%'} justifyContent={'flex-start'}>
            {/*안전솔루션*/}
            <div className={'picture'} data-aos={'fade-in'}
                 style={{
                     ...backgroundStyle,
                     backgroundSize: 'cover',
                     flexDirection: !isDesktop ? 'column' : 'row',
                     backgroundImage: !isDesktop ? 'none' :
                         `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), 
                    url(${bg2})`,
                     height: !isDesktop ? 'auto' : '800px',
                 }}>
                {/*<div className={'hero-main'}>*/}
                <div style={{
                    maxWidth: '1232px',
                    alignSelf: 'center',
                    display: 'flex',
                    padding: isDesktop ? '0px 16%' : '0px 6%',
                    margin: '0% auto',
                      width: '100%'

                }}>
                        <div style={{display: 'flex', flexDirection: 'column',
                            gap: isDesktop ? '60px' : '30px',}}>
                            <div style={{
                                fontWeight: 'bold',
                                fontSize: 'clamp(18px, 5vw, 24px)',
                                color: '#006DFF'
                            }}>
                                {'안전 솔루션'}
                            </div>
                            <div className={'hero_text'}
                                 style={{
                                     fontWeight: 'bold',
                                     fontSize: 'clamp(24px, 5vw, 48px)',
                                     zIndex: 2,
                                     transition: 'font-size 2s',
                                     whiteSpace: 'pre-line'
                                 }}>
                                {`인체감지 센서를 활용한\n안전한 작업 환경 구축`}
                            </div>
                            <div style={{alignSelf: isMobile ? 'center' : 'flex-start'}}>
                                <Buttons buttonType={'blue-main'} width={isDesktop ? '320px' : '216px'}
                                         height={isDesktop ? '64px' : '48px'}
                                         fontSize={isDesktop ? '24px' : '16px'}
                                         clickEvent={() => {
                                             window.location.href = '/product/safety'
                                         }} text={'자세히 보기 >'}/>
                            </div>
                        </div>
                        {/*</div>*/}
                </div>
                {!isDesktop &&
                    <div className={'picture'} style={{position: 'relative', width: '100%', boxSizing: 'border-box'}}>
                        <div style={{
                            width: '100%',
                            height: '30%',
                            minHeight: '200px',
                            position: 'absolute',
                            top: '0px',
                            zIndex: 2,
                            background: 'linear-gradient(to top, transparent, #000000C6, #000000)', // 검정색에서 투명으로 변하는 그라디언트
                        }}/>
                        {/* 이미지 */}
                        <img src={bg2} style={{
                            width: '100%',
                            height: 'auto',
                            minHeight: '368px',
                            objectFit: 'cover',
                            objectPosition: isMobile ? '80% 50%' : 'center',
                            position: 'relative',
                            bottom: '40%',
                            zIndex: 1,
                            left: '50%', // 이미지를 가운데 정렬하기 위해 추가
                            transform: 'translateX(-50%)',
                        }}/>
                    </div>}
            </div>


            {/*cta버튼*/}

            <div data-aos={'fade-in'}
                 className={'picture'} style={{
                backgroundImage: isMobile ? `url(${bgM})` : `url(${bg1})`,
                height: !isDesktop ? '320px' : '600px',
                justifyContent: 'center',
                boxSizing: 'border-box',
                backgroundSize: 'cover',
                width:'100%',
                filter: isDesktop?'contrast(0.9)':'none',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <div className={'hero-main'} style={{padding: isDesktop ? '0 16%' : '0 4%'}}>
                    <div style={{zIndex: 2,
                        display: 'flex', flexDirection: 'column',
                        gap: isDesktop ? '60px' : '30px', padding: '0', alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <div className={'hero_text'}
                             style={{
                                 display: '-webkit-box',
                                 WebkitLineClamp: 3,
                                 WebkitBoxOrient: 'horizontal',
                                 fontSize: 'clamp(24px, 5vw, 56px)',
                                 fontWeight: 'bold',
                                 // maxWidth: isDesktop ? '770px' : (isTablet ? '500px' : '200px'),
                                 overflow: 'visible',
                                 textAlign: 'center',
                                 whiteSpace: 'pre-line',
                                 letterSpacing: ' 0.0em',
                             }}>
                            {'프레스 관련 제품 및 솔루션\n공정개선까지 주식회사 제스텍'}
                        </div>

                        <Buttons buttonType={'blue-main'} width={isDesktop ? '320px' : '216px'}
                                 height={isDesktop ? '64px' : '48px'}
                                 fontSize={isDesktop ? '24px' : '16px'} clickEvent={() => {
                            window.location.href = '/cs'
                        }}
                                 text={'맞춤 서비스 신청하기 >'}/>


                    </div>
                </div>

            </div>
        </MainContainer>
    );
};

export default ProductCtaSection;
