import React from 'react';
import styled from "styled-components";
import arrow from '../../asset/images/icons/chevron-down.svg'
import {useEffect} from "react";
import Images from "../../styles/Images";


interface Props {
    persistWeight?: number
    menuArray: string[] | any[]
    fontWeight?: string | number
    clickEvent?: (data: string) => void
    top?: string | number
    left?: string | number

    style?: any
    width?: string
    height?: number
    disabled?: boolean
    menuName?: string
    addNoneSelectMenu?: boolean
    selectValue?: string | number
    type?: string
    maxWidth?: string
}

const DropMenu: React.FunctionComponent<Props> = ({
                                                      menuArray,
                                                      fontWeight,
                                                      top,
                                                      left,

                                                      height,
                                                      clickEvent,
                                                      style,
                                                      persistWeight,
                                                      width,
                                                      disabled,
                                                      addNoneSelectMenu,
                                                      selectValue,
                                                      type,
                                                      maxWidth
                                                  }) => {
    const [openMenu, setOpenMenu] = React.useState(false)
    const [listIndex, setListIndex] = React.useState<number>(0)
    const dropMenuRef = React.useRef<any>(null)

    const dropMenuArray = JSON.parse(JSON.stringify(menuArray))
    if (addNoneSelectMenu) {
        dropMenuArray.unshift('선택안함')
    }
    React.useEffect(() => {
        setListIndex(dropMenuArray.findIndex((v: any) => v === selectValue))
    }, [menuArray])

    useEffect(() => {
            const handleClickOutside = (e: any) => {
                if (dropMenuRef.current && !dropMenuRef.current.contains(e.target)) {
                    setOpenMenu(false)
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
        , [dropMenuRef])
    return (
        <BaseComponent ref={dropMenuRef} onClick={() => setOpenMenu(!openMenu)}
                       onMouseDown={(e) => e.stopPropagation()}
                       top={top} left={left} height={height}
                       style={{
                           ...style,
                           border: '1px sold #D7DBEC',
                           zIndex: openMenu ? 100 : 49,
                           height: `${!height ? openMenu ? ((dropMenuArray.length + 1) * 32) + 'px' : '32px' : openMenu ? ((dropMenuArray.length + 1) * height) + 'px' : height + 'px'}`,
                           minWidth: width ?? '160px',
                           maxWidth: maxWidth
                       }}>
            <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                backgroundColor: '#003A88',
            }}>
                <div style={{
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    paddingLeft: '10px',
                    color: '#fff'

                }}>
                    {dropMenuArray[listIndex]}</div>
                <div style={{
                    display: 'flex', width: '40px', height: '40px',
                    alignItems: 'center', justifyContent: 'center',
                    transform: 'translateY(-2px)'
                }}>
                    <img style={{
                        transform: openMenu ? 'rotateX(180deg)' : 'rotateX(0deg)',
                        transition: 'transform 0.25s',
                        alignSelf: 'center',
                    }} src={Images.icons.arrow} alt=""/>
                </div>
            </div>
            <div className={'spread_menu'}>
                {dropMenuArray.map((value: string, index: number) => (
                    <div key={value} onClick={() => {
                        setListIndex(index)
                        //@ts-ignore
                        clickEvent(value)
                    }} style={{
                        borderTop: '1px solid #D7DBEC',
                        fontSize: '14px', fontWeight: fontWeight, height: `${height ?? 32}px`
                    }}>{value}</div>
                ))}
            </div>
        </BaseComponent>

    );
};
export default DropMenu;
const BaseComponent = styled.div<{
    top?: string | number,
    left?: string | number,
    height?: number,
    backgroundColor?: string,
    customStyle?: any
    selected?: boolean

}>`
    min-height: ${props => props.height ?? 32}px;
    line-height: ${props => props.height ?? 32}px;
    background-color: transparent;
    ${props => props.customStyle ?? {}}
    top: ${props => props.top ?? '8px'};
    left: ${props => props.left ?? '16px'};
    border-radius: 4px;
    align-items: center;
    box-sizing: border-box;
    max-width: 160px;
    padding: 0;
    align-self: center;
    position: absolute;
    overflow: hidden;
    font-weight: bold;
    user-select: none;
    transition: all 0.25s;
    cursor: pointer;


    img {
        cursor: pointer;
        user-select: none;
    }

    .spread_menu {
        font-weight: 600;
        font-size: 16px;
        overflow-y: scroll;
        background-color: #1E222D;
        color: #AAAAAA;
        text-align: center;
        //max-height: 200px;

        & > div {
            position: relative;
            z-index: 90;
            box-sizing: border-box;
            cursor: pointer;
            padding: 0 8px;

            &:hover {
                background-color: rgba(0, 88, 255, 0.05);
                //border-bottom: 1px solid #d7dbec;
                color: #0058ff;
                font-weight: 700;
            }
        }
    }
`
