import React, {useContext, useEffect} from 'react';
import ProductSlideComp from "../../../components/slides/ProductSlideComp";
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import ProductViewMoreSwiperContainer from "../../../components/swipers/ProductViewMoreSwiperContainer";
import Aos from "aos";
import {clamp} from "react-number-format/types/utils";


interface Props {
    productList?: any;

}
const ProductViewMoreSection:React.FC<Props> = ({productList}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 1023px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });
    useEffect(() => {
            Aos.init({duration: 1500
            });
        }
        ,[])

  // const ProductContext = React.createContext(productList);


    return (

        <MainContainer
            style={{
                background: 'linear-gradient(180deg, #000000 0%, #1E222D 100%)',
                flexDirection: 'column',
                justifyContent: 'center',
                height: isDesktop ? '100%' : '100vh',
            }}>
            <div style={{ maxWidth: '1920px',alignSelf:'center',width:'100%',overflow:'hidden'}}>
            <div style={{
                padding: isDesktop ? '140px 18% 0 18%' : '80px 8% 0 8%',
                fontSize: 'clamp(18px,4vw, 24px)',
                fontWeight: 'bold',
                color: '#006DFF'
            }}>
                {'제품 더보기'}
            </div>
            <div style={{display:'flex',padding: isMobile ? '20px 0 40px 0' : '40px 0% 200px 0%',alignItems:'center',justifyContent:'center'}}>
                {/*//슬라이더 컴포넌트*/}
                <ProductViewMoreSwiperContainer productList={productList}/>
            </div>
            </div>
        </MainContainer>

    );
};

export default ProductViewMoreSection;
