import React from 'react';
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";
import {ContainerProps} from "../../types/Fundamental";
import FooterMenuComponent from "../menu/FooterMenuComponent";
import logo from '../../asset/images/logo_white.svg'
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t}:any = useTranslation();

    const isDesktop = useMediaQuery({
        query : "(min-width: 1025px)"
    })
    const isMobile = useMediaQuery({
        query : "(max-width: 1024px)"
    });


    return (
        <Container padding={isDesktop ? '0 16% 256px 16%' : '20px 8% 128px 8%'} height={isDesktop ? '100%' : '100%'}>
                <div style={{
                    width: '100%',
                    justifySelf: 'center',
                    alignSelf: 'center',
                    maxWidth: '1232px'
                }}>
                {isDesktop &&
                    <React.Fragment>
                        <FooterMenuComponent compType={'desktop'} />
                        <Info flexDirection={'column'}>
                            <div className={'company-name'} style={{fontWeight: 'bold', color: '#fff'}}>
                                {t('(주)제스텍')}
                            </div>
                            <div className={'company-info'} style={{
                                marginTop: '30px', marginBottom: '60px',
                                display: 'flex'
                            }}>
                                {t(`사업자 등록번호 : 122-86-32183 ㅣ 대표 : 박필석 
                      인천광역시 서구 파랑로 495, 1동 622~628호 
                      TEL : 032-505-8055 
                      FAX : 032-505-8056 
                      Email : zestech@zestech.co.kr`)}
                            </div>

                            <div className={'company-info'} style={{
                                display: 'flex', flexDirection:
                                    "column", height: '100%', width: '100%', padding: '40px 0 40px 0'
                            }}>
                                <div style={{height: 'auto', width: 'auto', whiteSpace: 'pre-line', color: '#fff'}}>
                                    {`COPYRIGHT(C) by ZESTECH CO.,LTD All Rights Reserved.`}
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    alignItems: 'baseline'
                                }}>
                                    <div style={{
                                        width: '250px',
                                        marginTop: '20px',
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(4,1fr)'
                                    }}>
                                        {['f', 'B', 'N', 'M'].map((item) => (
                                            <Social>
                                                {item}
                                            </Social>
                                        ))}
                                    </div>
                                    <img src={logo} style={{width: '120px', height: '43px'}} alt="logo"/>
                                </div>

                            </div>
                        </Info>
                    </React.Fragment>

                }
            </div>

                {isMobile &&
                    <React.Fragment>

                        <FooterMenuComponent compType={'mobile'}/>
                        <Info flexDirection={'column'}>
                            <div className={'company-name'} style={{fontWeight: 'bold', color: '#fff'}}>
                                {t('(주)제스텍')}
                            </div>
                            <div className={'company-info'} style={{display: 'flex'}}>
                                {t(`사업자 등록번호 : 122-86-32183 ㅣ 대표 : 박필석 
                      인천광역시 서구 파랑로 495, 1동 622~628호 
                      TEL : 032-505-8055 
                      FAX : 032-505-8056 
                      Email : zestech@zestech.co.kr`)}
                            </div>

                            <div className={'company-info'} style={{
                                height: 'auto',
                                width: 'auto',
                                whiteSpace: 'pre-line',
                                color: '#fff',
                                marginTop: '32px'
                            }}>
                                {`COPYRIGHT(C) by ZESTECH CO.,
                        LTD All Rights Reserved.`}
                            </div>
                            <div style={{
                                display: 'flex',
                                marginTop: '20px',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center'
                            }}>
                                <div style={{
                                    height: '100%',
                                    marginTop: '40px',
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(4,1fr)',
                                    gap: '16px'
                                }}>
                                    {['f', 'B', 'N', 'M'].map((item) => (
                                        <Social>
                                            {item}
                                        </Social>
                                    ))}
                                </div>

                            </div>
                            <img src={logo} style={{
                                display: 'flex',
                                alignSelf: 'end',
                                marginTop: '60px',
                                width: '80px',
                                height: '28.47px'
                            }} alt="logo"/>

                        </Info>

                    </React.Fragment>}


        </Container>


);
};

export default Footer;

const Container = styled.div
    <ContainerProps>`
        position: relative;
    bottom:  0;
    width: 100%;
    min-width: 300px;
    height: ${props => props.height};
    background-color: #000000;
     display: flex;
     flex-direction: column;

  //background: transparent linear-gradient(90deg, rgba(0, 0, 0, 0.53) 0%, rgba(0, 0, 0, 0.27) 56%, rgba(0, 0, 0, 0) 89%, rgba(0, 0, 0, 0.2) 100%) 0% 0% no-repeat padding-box; /* 투명하게 수정 */
    box-sizing: border-box;
    align-items: center;
    padding: ${props => props.padding};
`

const Info = styled.div<ContainerProps>`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction:${props => props.flexDirection};
    
    color:#E0E0E0;
    white-space: pre-line;
    line-height: 1.8;
    .company-name{
        font-size: 32px;
    }
    .company-info{
        font-size: clamp(10px, 3vw, 24px);
        color: #aaa;
    }
    `

const Social = styled.div`
    display: flex;
    width:48px;
    height:48px;
    background-color: rgba(237, 245, 255, 0.3);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
     color:rgba(255, 255, 255, 0.5);
    
    
    //@media (max-width: 768px){
    //    width: 32px;
    //    height: 32px;
    //    font-size: 16px;
    //}
    
    `
