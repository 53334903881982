import {useMediaQuery} from "react-responsive";
import {specArray} from "./DataSet";
import {createContext} from "react";

export const useResponsive = (mobileWidth?:number,tabWidth?: number,desktopWidth?: number) => {
    const isDesktop = useMediaQuery({
        query: `(min-width: ${desktopWidth?? 1440}px)`,

    });

    const isTablet = useMediaQuery({
        query:  `(max-width: ${tabWidth??768}px)`,
    });

    const isMobile = useMediaQuery({
        query:  `(max-width: ${mobileWidth??430}px)`
    });

    return {isDesktop,isTablet,isMobile}
}




//specArray의 데이터를 받아서 해당하는 데이터를 리턴해주는 함수

export const useSpecArray = (type:string) => {
    const data = specArray.filter((item) => item.category === type);
    return data;
}


export const GlobalRef = createContext <any>(null);