import React, {useEffect} from 'react';
import {MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import Aos from "aos";
import {ProductType} from "../../../types/Fundamental";
import Images from "../../../styles/Images";


interface Props {
    productList?: ProductType |any;
}

const V3SpecSection:React.FC<Props> = ({productList}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    useEffect(() => {
            Aos.init({duration: 1500
            });
        }
        ,[])



    return (
        <MainContainer
            style={{
                background: '#EDF5FF',
                padding: isDesktop ? '200px 16% 100px 16%' : '100px 10%',
                flexDirection: 'column',
                justifyContent: isDesktop?'flex-start':'center',
                height: isDesktop ? '100%' :isMobile?'100%':'100%',
            }}>
            <GridContainer>
                    <>
                    <div className={'table-wrapper'}>
                         <div  className={'title'}>{'제품 기능'}</div>
                          <div className={'spec-table'}
                               data-aos={isDesktop?'fade-left':''}
                               style={{gridTemplateRows: isDesktop?`repeat(${productList?.productSpec.length/2}, 40px)`:'20px'}}>
                                {productList?.productSpec?.map((v:any,index:number)=>
                                    <>
                                        <div className={'spec-content'}>{v.specName}</div>
                                        <div className={'spec-content'} style={{textAlign:'right'}}>{v.value}</div>
                                    </>
                                  )}
                            </div>

                        <div   className={'title'} style={{marginTop:'120px'}}>{'제품 옵션(Spare Slot을 이용 Max 2 Slot)'}</div>
                        <div className={'spec-table'}  data-aos={isDesktop?'fade-left':''}  style={{gridTemplateRows:isDesktop?`repeat(${productList?.productSpec.length/2}, 40px)`:'20px'}}>
                            {productList?.productOption.map((v:any,index:number)=>
                                <>
                                    <div className={'spec-content'}>{v.optionName}</div>
                                    <div className={'spec-content'} style={{textAlign:'right'}}>{v.value}</div>
                                </>
                            )}
                        </div>
                    </div>
                </>

                {/*이미지 박스 */}
                <div className={'image-table'} data-aos={isDesktop?'fade-up':''}>
                    <div  style={{mixBlendMode:'multiply'}}>
                    <img  className={'img'} src={Images.v3.layoutFront}
                          alt={'product'}/>
                    </div>
                    <div   style={{mixBlendMode:'multiply'}}>
                    <img  className={'img'} src={Images.v3.layoutBack}
                          alt={'layout'} style={{mixBlendMode:'multiply'}}/>
                    </div>
                   <div>
                    <div data-aos={isDesktop?'fade-left':''} className={'spec-content'} style={{textAlign:isDesktop?'right':'center',color:'#707070',fontSize:'clamp(12px, 2.5vw, 16px)',}}>{'* Specifications are subject to change without notice.'}</div>

                   </div>
                </div>
            </GridContainer>

            {/*{!isDesktop?*/}
            {/*    <div style={{display:'flex',justifyContent:'center',marginTop:'120px',flexDirection:'column',gap:'40px'}}>*/}
            {/*        <div data-aos={'fade-up'} style={{display:'flex',flexDirection:'column',gap:'16px',mixBlendMode:'multiply'}}>*/}
            {/*            <img className={'img'} src={productList?.productImg[0].comp} alt={'product'} style={{mixBlendMode:'multiply'}}/>*/}
            {/*            <div className={'spec-content'} style={{textAlign:'right',color:'#707070',fontSize:'16px'}}>{'* 000 별도'}</div>*/}
            {/*        </div>*/}
            {/*        <div data-aos={'fade-up'} style={{display:'flex',flexDirection:'column',gap:'16px',mixBlendMode:'difference'}}>*/}
            {/*            <img className={'img'} src={productList?.productImg[0].circuit} alt={'product'} style={{mixBlendMode:'difference'}}/>*/}
            {/*            <div className={'spec-content'} style={{textAlign:'right',color:'#707070',fontSize:'16px'}}>{'* 000 별도'}</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*        :*/}

            {/*   <div style={{justifyContent:'center',alignItems:'center', display:'grid',gridTemplateColumns:'1fr 1fr',gap:'96px',marginTop:'120px'}}>*/}
            {/*    <div data-aos={'fade-up'} style={{display:'flex',flexDirection:'column',gap:'16px',mixBlendMode:'multiply'}}>*/}
            {/*        <img className={'img'} src={productList?.productImg[0].comp} alt={'product'} style={{mixBlendMode:'multiply'}}/>*/}
            {/*        <div className={'spec-content'} style={{textAlign:'right',color:'#707070',fontSize:'16px'}}>{'* 000 별도'}</div>*/}
            {/*    </div>*/}
            {/*    <div data-aos={'fade-up'} style={{display:'flex',flexDirection:'column',gap:'16px',mixBlendMode:'difference'}}>*/}
            {/*        <img className={'img'} src={productList?.productImg[0].circuit} alt={'product'} style={{mixBlendMode:'difference'}}/>*/}
            {/*        <div className={'spec-content'} style={{textAlign:'right',color:'#707070',fontSize:'16px'}}>{'* 000 별도'}</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}


            {/*    }*/}



        </MainContainer>
    );
};

export default V3SpecSection;

const GridContainer = styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns:472px 504px;
    box-sizing: border-box;
    grid-row-gap: 120px;
    grid-column-gap: 280px;
    width: 100%;
    
    @media (max-width: 1440px) {
       
        grid-row-gap: 80px;
        grid-column-gap: 200px;
        
        
    }
   
    
  
   .title{
     font-weight: bold;
     font-size:clamp(14px, 2.5vw, 24px);
     color:#006DFF;
      
   }
  
   .table-wrapper{
     width: 472px;
     box-sizing: border-box;
     //grid-template-columns: 472px;
     //grid-column-gap: 256px;
     display: grid;
       
      
   }
  
   .spec-table{
     width: 100%;
     margin-top: 16px;
     display: grid;
     grid-column-gap: 40px;
     grid-row-gap: 10px;
     grid-template-columns: 1fr 1fr;
       
   } 
  
      .image-table{
        display: flex;
          flex-direction: column;
         align-self: flex-end;
        grid-row-gap: 16px;
        margin-top: 80px;
      }

  .img{
    width:504px;
    height:320px;
    object-fit: contain;
 
  }
 
  
  .spec-content{
    font-weight: normal;
    font-size:  clamp(12px, 2.5vw, 24px);
    white-space: nowrap;
    
     
  }
  
 


  @media (max-width: 1339px) {
    grid-template-columns: 1fr;  
    grid-row-gap: 10px; 
      
    .table-wrapper {
      width: 100%;
    }
    .spec-table {
      width: 100%;
        grid-column-gap: 0px;
        grid-row-gap: 8px;
    }
    .image-table {
      grid-template-rows: repeat(3, auto); /* 이미지 테이블의 높이 자동 조정 */
      grid-row-gap: 16px;
      margin-top: 40px; /* 상단 여백 줄임 */
    }
    .img {
      width: 100%;
      height: auto; /* 이미지 높이 자동 조정 */
    }
  }
 
  
    
    `
