import React, {useEffect} from 'react';
import {MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import productImg from "../../../asset/images/products/V3/V3_main.png";
import Aos from "aos";
import {ProductType} from "../../../types/Fundamental";
import ProductPanel from "../../../components/panel/ProductPanel";

interface Props {
    productList?: ProductType | any;
    imgWidth?: string;
    imgHeight?: string;
    top?: string;
    left?: string;

}

const ProductDetailMainSection: React.FC<Props> = ({productList}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 769px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width: 430px)"
    });


    useEffect(() => {
            Aos.init({
                duration: 1000
            });
        }
        , [])

    const textLength = (text: string) => {
        if (text.length > 5) {
            return '420px'
        } else {
            return '100%'
        }
    }

    const nameTranslate = (name: any) => {
        if (name === 'misfeeder') {
            return '미스피더'
        } else if (name === '1ch') {
            return `하사점 검출장치\n1채널`
        } else if (name === '2ch') {
            return '하사점 검출장치\n2채널'
        } else if (name === 'intergration') {
            return '통합 하사점\n검출장치'
        } else if (name === 'pressure') {
            return '압전압식\n로드모니터(별치형)'
        } else if (name === 'safety') {
            return 'AI 안전 솔루션'
        } else {
            return name
        }
    }


    return (
        <MainContainer height={isDesktop ? '1080px' : isMobile ? '100%' : '100%'} width={'100vw'}
                       padding={isDesktop ? '100px 16%' : isMobile ? '100px 6%' : '100px 6%'}
                       justifyContent={'center'}
                       style={{background: 'linear-gradient(136deg, #EDF5FF 0%, #7A8AA0 100%)'}}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div data-aos={'fade-right'} style={{
                    fontWeight: 'normal',
                    margin: '20px 0px',

                    whiteSpace: 'pre-wrap',
                    fontSize: 'clamp(14px, 2vw, 18px)',
                    color: isDesktop ? '#006DFF' : '#000'
                }}>

                    {productList?.productName === '1ch' ?
                        `제품소개 > ${productList?.category} >\n하사점 검출장치 1채널` :
                        productList?.productName === '2ch' ?
                            `제품소개 > ${productList?.category} >\n하사점 검출장치 2채널` :
                            productList?.productName === 'intergration' ?
                                `제품소개 > ${productList?.category} >\n통합 하사점 검출장치` :
                                productList?.productName === 'pressure' ?
                                    `제품소개 > ${productList?.category} >\n압전압식 로드모니터` :
                                    `제품소개 > ${productList?.category} > ${nameTranslate(productList?.productName)}`}
                </div>
                {isDesktop ?
                    <React.Fragment>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '40px',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            {/*제품 박스*/}
                            <ImageContainer>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'relative',
                                    zIndex: '4',
                                    boxSizing: 'border-box'
                                }}>
                                    {/*제품명*/}
                                    <div className={'hero_text'}
                                         style={{
                                             fontWeight: 'bold',
                                             marginTop: '20px',
                                             fontSize: 'clamp(32px, 4vw, 64px)',
                                             zIndex: '3',
                                             textAlign: 'left',
                                             color: '#fff',
                                             textShadow: '0px 3px 6px #00000029',
                                             position: 'relative',
                                             wordBreak: 'break-word',
                                             lineHeight: '1.2',
                                             whiteSpace: 'pre-line',
                                             maxWidth: productList?.productName === 'pressure' ? '100%' :
                                                 textLength(nameTranslate(productList?.productName))
                                         }}>
                                        {productList?.productName === 'pressure' ?
                                            `압전압식\n로드모니터(별치형)` :
                                            productList?.productName === 'safety' ?
                                                `AI\n안전솔루션` :
                                                `${nameTranslate(productList?.productName)}`}
                                    </div>
                                    {/*제품 서브명*/}
                                    {productList?.subName && productList?.productName !== 'misfeeder' &&
                                        <div className={'hero_text'} style={{
                                            fontWeight: 'normal',
                                            zIndex: '3',
                                            marginTop: '8px',
                                            textAlign: 'left',
                                            position: 'relative',
                                            whiteSpace: isDesktop ? 'nowrap' : 'pre-line',
                                            fontSize: '24px',
                                            color: '#fff'
                                        }}>
                                            {`${productList?.subName}`}
                                        </div>
                                    }
                                    {/*제품 설명*/}
                                    {productList?.info &&
                                        <div className={'hero_text'} style={{
                                            fontWeight: 'normal',
                                            zIndex: '3',
                                            marginTop: '8px',
                                            textAlign: 'left',
                                            position: 'relative',
                                            fontSize: '18px',
                                            color: '#fff'
                                        }}>
                                            {`${productList?.info}`}
                                        </div>
                                    }
                                    {/*제품 상세 설명*/}
                                    <div style={{
                                        color: '#fff',
                                        fontSize: 'clamp(20px, 2vw, 25px)',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        zIndex: '3',
                                        marginTop: isDesktop ? '60px' : '20px',
                                        maxWidth: isDesktop ? '580px' : '280px',
                                        WebkitBoxOrient: 'horizontal',
                                        overflow: 'visible',
                                        whiteSpace: 'pre-line',
                                        position: 'relative',
                                        lineHeight: 1.6,
                                    }}>
                                        {`${productList?.copyWrite}`}
                                    </div>
                                    {/*제품 인증 */}
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '16px',
                                        marginTop: isDesktop ? '20px' : '20px'
                                    }}>
                                        {productList?.certification &&
                                            productList?.certification.map((item: any, index: number) =>
                                                <div style={{
                                                    fontSize: '16px', color: '#fff', fontWeight: 'normal',
                                                    borderRadius: '24px',
                                                    textAlign: 'center',
                                                    border: '1px solid #fff', width: '100px', lineHeight: '48px'
                                                }}>
                                                    {`${item}`}</div>)}
                                    </div>
                                    {/*제품 누끼 컷*/}
                                    <img className={'img'} src={productList?.mainImg} alt={''} style={{
                                        left:
                                            productList?.productName === 'pressure' ?
                                                '80%'
                                                :
                                                productList?.productName === 'safety' ||
                                                productList.productName.includes('ch') ?
                                                    !isDesktop ? '200px' : '350px' : '250px',

                                    }}/>
                                </div>
                            </ImageContainer>

                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <ImageContainer>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '0px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                    <div style={{
                                        fontWeight: 'bold', fontSize: 'clamp(36px, 5vw, 40px)', color: '#fff',
                                        whiteSpace: 'pre-line',
                                        textShadow: '0px 3px 6px #00000029',
                                        maxWidth: '300px',
                                        wordBreak: 'break-word',
                                        lineHeight: '1.2',
                                    }}>
                                        {productList?.productName === 'pressure' ?
                                            `압전압식\n로드모니터(별치)` :
                                            productList?.productName === 'safety' ?
                                                `AI\n안전솔루션` :
                                                `${nameTranslate(productList?.productName)}`}
                                    </div>
                                    {productList?.subName && productList?.productName !== 'misfeeder' &&
                                        <div style={{
                                            fontWeight: 'normal',
                                            fontSize: 'clamp(14px, 2vw, 24px)',
                                            color: '#fff'
                                        }}>
                                            {`${productList?.subName}`}
                                        </div>}
                                </div>
                                {/*{productList?.info &&*/}
                                {/*    <div style={{*/}
                                {/*        fontWeight: 'normal', marginTop: '10px',*/}
                                {/*        fontSize: 'clamp(16px, 3vw, 24px)', color: '#fff'*/}
                                {/*    }}>*/}
                                {/*        {`${productList?.info}`}*/}
                                {/*    </div>}*/}
                                {productList?.mobileCopyWrite &&
                                    <div style={{
                                        color: '#fff',
                                        fontSize: 'clamp(16px, 3vw, 24px)',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 3,
                                        marginTop: '20px',
                                        WebkitBoxOrient: 'horizontal',
                                        overflow: 'visible',
                                        whiteSpace: 'pre-line',
                                        lineHeight: 1.6,
                                    }}>
                                        {`${productList?.mobileCopyWrite}`}
                                    </div>}

                                <img className={'img'} src={productList?.mainImg} alt={''}/>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    position: 'absolute',
                                    bottom: '6%',
                                    left: '8%',
                                }}>
                                    {productList?.certification &&
                                        productList?.certification.map((item: any, index: number) =>
                                            <div style={{
                                                fontSize: '14px', color: '#fff', fontWeight: 'normal',
                                                borderRadius: '24px',
                                                textAlign: 'center',
                                                border: '1px solid #fff', width: '80px', lineHeight: '40px'
                                            }}>
                                                {`${item}`}</div>)}
                                </div>
                            </div>
                        </ImageContainer>
                        {/*<div style={{display:'flex',flexFlow:'wrap',gap:'20px',marginTop:'20px'}}>*/}
                        {/*    <div style={{fontSize:'12px',color:'#999999'}}>{`${productList?.certification}`}</div>*/}
                        {/*    <div style={{fontSize:'12px',color:'#999999'}}>{`${productList?.certification}`}</div>*/}
                        {/*</div>*/}

                    </React.Fragment>}
            </div>
        </MainContainer>
);
};

export default ProductDetailMainSection;

const ImageContainer = styled.div`
width: 1104px;
height: 720px;
border-radius: 80px;
background: #006DFF;
position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 0 80px;
    //min-height: auto;
    margin-top: 20px;
    transition: all 0.5s ease-in-out;


    &::before {
        content: '';
        width: 100%;
        height: 20%;
        background: #081E5A;
        position: absolute;
        z-index: 1;
        bottom: -60px;
        filter: blur(40px);
        opacity: 0.5;
        -webkit-filter: blur(40px); /* 사파리용 웹킷 필터 추가 */
        -webkit-opacity: 0.5; /* 사파리용 웹킷 투명도 속성 추가 */
        transform: translateZ(0); /* 사파리 GPU 가속 활성화 */
    }


    img {
        //width: 100%;
        //height: 100%;
        object-fit: contain;
        position: absolute;
        left: 400px;
        top: -120px;
        width: 1000px;
        height: 737px;


        overflow: visible;
        z-index: 0;
        filter: brightness(1.2) grayscale(0.3);
        transition: all 0.7s ease-in-out;

        &:hover {
            transform: scale(1.1);
            filter: brightness(1.3);
        }
    }


    @media (max-width: 1440px) {
        width: 820px;
        height: 600px;

        img {
            width: 700px;
            height: 540px;
            left: 200px;
            top: -50px;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        position: relative;
        max-width: 700px;
        min-width: 304px;
        max-height: 840px;
        min-height: 504px;
        align-items: flex-start;
        left: 12%;
        padding: 60px;
        border-radius: 32px;
        box-sizing: border-box;

        img {
            width: 100%;
            height: 100%;
            max-width: 800px;
            min-width: 700px;
            object-fit: contain;
            top: calc((100% - 227px) / 2);
            left: -30%;
            position: relative;
        }
    }
    @media (max-width: 500px) {
        width: 100%;
        height: 100%;
        max-width: 404px;
        max-height: 532px;
        position: relative;
        box-sizing: border-box;
        left: 12%;
        padding: 50px;

        img {
            width: 370px;
            height: 227px;
            object-fit: contain;
            top: calc((100% - 227px) / 2);
            left: -40%;
            position: relative;;
        }
    }

    @media (max-width: 375px) {
        width: 314px;
        height: 442px;
        position: relative;
        box-sizing: border-box;
        left: 12%;
        padding: 40px;
    }

    @media (max-width: 320px) {
        width: 280px;
        height: 432px;
        position: relative;
        box-sizing: border-box;
        left: 12%;
        padding: 40px;

        img {
            width: 313px;
            height: 227px;
            object-fit: contain;
            top: calc((100% - 227px) / 2);
            left: -39%;
            position: relative;
        }
    }
    

`
