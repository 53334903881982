import React, {useEffect, useRef, useState} from 'react';
import {ContentFrame} from "../../styles/Styles";
import styled from "styled-components";
import ProductHeroSection from "./hero/ProductHeroSection";
import ProductTypeSection from "./productInfo/ProductTypeSection";
import ProductIInfoSection from "./productInfo/ProductIInfoSection";
import CustomInquirySection from "./custom/CustomInquirySection";
import SmartSolutionSection from "./solution/SmartSolutionSection";
import ProductCtaSection from "./hero/ProductCtaSection";
import Aos from "aos";
import ReactPageScroller from "react-page-scroller";
import {useMediaQuery} from "react-responsive";
import Footer from "../../components/footer/Footer";
import ProductPanel from "../../components/panel/ProductPanel";
import {specArray} from "../../common/DataSet";
import {animateScroll as scroll} from "react-scroll/modules";

interface Props {
    productList?: any;
    pageScroll?: any;

}
const ProductScrollSection:React.FC<Props> = ({productList,pageScroll}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageChange = (number: number) => {
        setCurrentPage(number);
    }




    // useEffect(() => {
    //     Aos.init({ duration: 1000
    //     });
    // },[])

    const isMobile = useMediaQuery({
            query: "(max-width:768px)"
        }
    )



    const scrollToComponent = (index: number) => {
        // @ts-ignore
        scroll.scrollTo( `component-${index}`, {
            duration: 700,
            smooth: 'easeInOutQuart',
        });
    };




    return (
        <ContentFrame>
            {/*<ReactPageScroller pageOnChange={handlePageChange}*/}
            {/*                   blockScrollUp={currentPage === 0}*/}
            {/*                   customPageNumber={currentPage} animationTimer={700}>*/}
                <ScrollSnapContainer>
                    {!isMobile&&
                        <ProductPanel compType={'type'}
                                      productList={specArray}
                                      onScrollToComponent={scrollToComponent}/>}
                    <ProductHeroSection/>
                    <ProductIInfoSection renderType={pageScroll} productList={productList}/>

                 <ProductCtaSection/>
                {/*<Footer/>*/}
                </ScrollSnapContainer>
            {/*</ReactPageScroller>*/}
        </ContentFrame>
    );
};

export default ProductScrollSection;


const ScrollSnapContainer = styled.div`
  //scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  //scroll-snap-stop: always;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & > div {
    scroll-snap-align: start;
  }
`;
