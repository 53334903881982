import React from 'react';
import {ProductType} from "../../../types/Fundamental";
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";


interface Props {
    productList?: ProductType |any;
}
const DieHeight1chSpecSection:React.FC<Props> = ({productList}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    return (
        <MainContainer
            style={{
                background: '#EDF5FF',
                padding: isDesktop ? '200px 20% 100px 20%' : '100px 5%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: isDesktop ? '100%' : isMobile ? '100%' : '100%',
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'
            }}>
                <TitleContainer>
                    {'미스피더 2채널'}
                </TitleContainer>

                <div data-aos={!isDesktop ? '' : 'fade-up'} style={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: isDesktop ? '120px' : '20px',
                    position: 'relative',
                    marginTop: isMobile ? '20px' : '40px',
                    marginBottom: isMobile ? '80px' : '120px'
                }}>
                    <FlowContainer>
                        {'언더피드'}
                    </FlowContainer>
                    <FlowContainer>
                        {'오버피드'}
                    </FlowContainer>
                    <FlowContainer>
                        {'통과검출'}
                    </FlowContainer>
                    <FlowContainer style={{marginTop: '0px'}}>
                        {'근접센서'}
                    </FlowContainer>
                    <div style={{
                        width: isMobile ? '1px' : '100%',
                        height: isMobile ? '80%' : '1px',
                        backgroundColor: '#006DFF',
                        marginTop: isDesktop ? '40px' : '30px',
                        zIndex: '0',
                        position: 'absolute',
                        left: isMobile ? '50%' : '0',
                        maxWidth: '900px',
                    }}/>
                </div>

                {productList?.productSpec.map((v: any, index: number) =>
                    <div style={{
                        display: 'flex',
                        flexDirection: !isDesktop ? 'column' : 'row',
                        alignItems: 'flex-start',
                        gap: '46px',
                        marginTop: isDesktop ? '80px' : '40px',
                        width: '100%'
                    }}>
                        {v.specName === '최대 검출 가능 판재 두께' ? (
                            // 2줄로 나누기
                            <SpecContainer style={{
                                borderRadius: '42px',
                                height: '100%',
                                padding: '12px 0px',
                                lineHeight: '1.2',
                                whiteSpace: 'pre-wrap',
                                textAlign: 'center'
                            }}>
                                {'최대 검출 가능\n판재 두께'}
                            </SpecContainer>
                        ) : (
                            // 1줄로 나타내기
                            <SpecContainer>
                                {v.specName}
                            </SpecContainer>
                        )}
                        <div style={{
                            fontSize: 'clamp(16px, 2.5vw, 20px)',
                            color: '#1E222D',
                            whiteSpace: 'pre-line',
                            lineHeight: '1.8'
                        }}>
                            {v.value}
                        </div>
                    </div>
                )}
            </div>
        </MainContainer>
    );
};

export default DieHeight1chSpecSection;

const TitleContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    background-color: #484848;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 71px;
    min-height: 48px;
    max-height: 71px;
    max-width: 976px;
    min-width: 200px;
    font-weight: 700;
    font-size: 25px;

    @media (max-width: 768px) {
        height: 48px;
    }

`

const FlowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 154px;
    height:70px;
    background-color:#fff;
    border:1px solid #006DFF;
    color: #006DFF;
    font-size: clamp(14px, 2vw, 20px);
    z-index: 1;

    @media (max-width: 768px) {
        height: 48px;
        width: 120px;
    }
`

const SpecContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width:196px;
    max-width: 100%;
    min-width:196px;
    height:44px;
    background-color:#006DFF;
    color : #fff;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    white-space: nowrap;

    @media (max-width: 768px) {
        height: 56px;
        border-radius: 28px;
        width: 312px;
`
