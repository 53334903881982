import React, {useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import {MainContainer, SwiperContainer} from "../../../styles/Styles";
import {ProductType} from "../../../types/Fundamental";
import Buttons from "../../../components/buttons/Buttons";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import Aos from "aos";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';





interface Props {
    productList?: ProductType |any;
    onScrollToComponent?: (index: number) => void;
}

const V3ComponentSection:React.FC<Props> = ({productList,onScrollToComponent}) => {

    // const swiperRef = React.useRef(null);
    // const swiperParams = {
    //     slidesPerView: 4,
    //     spaceBetween: 0,
    //     breakpoints: {
    //         280: {
    //             slidesPerView: 3,
    //             spaceBetween: 20,
    //         },
    //         375:{
    //             slidesPerView:3,
    //             spaceBetween: 0,
    //         },
    //         425:{
    //             slidesPerView:3,
    //             spaceBetween: 0
    //         },
    //         768: {
    //             slidesPerView: 3,
    //             spaceBetween: 0,
    //         },
    //         1024: {
    //             slidesPerView: 5,
    //             spaceBetween: 0,
    //         },
    //         1200: {
    //             slidesPerView: 7,
    //             spaceBetween: 10,
    //         },
    //     }
    // }

    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    // const isTablet = useMediaQuery({
    //     query: "(max-width: 1024px)",
    // });
    //
    // const isMobile = useMediaQuery({
    //     query: "(max-width:430px)"
    // });

    const [selectedComponent, setSelectedComponent] = React.useState(0);


    useEffect(() => {
            Aos.init({
                duration: 700
            });
        }
        , [])

    const renderDesktop = () => {


        return(
            <MainContainer style={{
                background: '#fff',
                padding: '200px 20%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}>
                <div style={{ maxWidth: '1232px',
                    alignSelf: 'center',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div style={{display: 'flex', gap: '20px', justifyContent: 'center'}}>
                        {productList?.productComponents?.map((v: any, index: number) => (
                            <ScrollLink
                                key={index}
                                to={`component-${index}`}
                                spy={true}
                                smooth={true}
                                duration={700}
                                offset={-150}
                            >
                                <Buttons
                                    buttonType={'comp-name'}
                                    text={v.title}
                                    selected={selectedComponent === index}
                                    clickEvent={() => {
                                        setSelectedComponent(index);
                                        // scrollToComponent(index);
                                        onScrollToComponent && onScrollToComponent(index);
                                    }}
                                    width={'clamp(150px, 10vw, 200px)'}
                                    height={'clamp(40px, 5vw, 56px)'}
                                    fontSize={'clamp(14px,1vw,20px)'}
                                    margin={'0 0 40px 0'}
                                    padding={'0px 24px'}
                                />
                            </ScrollLink>
                        ))}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '140px',
                            marginTop: '100px',
                        }}
                    >
                        {productList?.productComponents?.map((v: any, index: number) => (
                            <div
                                key={index}
                                data-aos={'fade-up'}
                                data-aos-delay={index * 20}
                                id={`component-${index}`}
                                style={{
                                    display: 'flex',
                                    gap: '40px',
                                    alignItems: 'center',
                                }}
                            >
                                <ImageContainer>
                                    <img src={v.img} alt=""
                                         style={{objectFit: 'cover', overflowClipBox: 'content-box'}}/>
                                </ImageContainer>
                                <SpecContainer>
                                    <div className={'title'}>
                                        {v.title.slice(0, 8)}
                                        <span style={{color: '#5D9BEF'}}>{v.title.split('_')[2]}</span>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                                        <div className={'sub-title'}>{'Size'}</div>
                                        <div style={{marginLeft: '20px'}}>{v.size}</div>
                                    </div>
                                    <div style={{display: 'flex', marginTop: '20px', alignItems: 'baseline'}}>
                                        <div className={'sub-title'}>{'온도특성'}</div>
                                        <div style={{marginLeft: '20px'}}>{v.temperature}</div>
                                    </div>
                                </SpecContainer>
                            </div>
                        ))}
                    </div>
                </div>
            </MainContainer>
    );
    };


    const renderMobile = () => {
        return (
            <MainContainer
                style={{
                    background: '#fff',
                    padding: '100px 10%',
                    flexDirection: 'column',
                    height: '100%',
                }}>
                {/*<div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>*/}
                    {/*<SwiperContainer>*/}
                    {/*    <Swiper ref={swiperRef} {...swiperParams} style={{width:'480px',height:'48px'}}>*/}
                    {/*        {productList?.productComponents?.map((v: any, index: number) =>*/}
                    {/*            <SwiperSlide key={index} style={{width: '160px', height: '48px'}}>*/}
                    {/*                <Buttons buttonType={'comp-name'} text={'SPMS_V3'} fontWeight={'600'}*/}
                    {/*                         width={'150px'} height={'40px'} fontSize={'16px'} margin={'0px auto'}*/}
                    {/*                         padding={'0px 20px'}/>*/}
                                {/*</SwiperSlide>*/}
                {/*            )}*/}
                {/*        </Swiper>*/}
                {/*    </SwiperContainer>*/}
                {/*</div>*/}
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '0px'}}>
                    {productList?.productComponents?.map((v: any, index: number) =>
                        <div
                            style={{display: 'flex',flexDirection:'column', gap: '20px',justifyContent:'center',alignItems:'center', marginTop:'0px'}}>
                            <SpecContainer style={{alignItems:'baseline',width:'300px', height:'100%'}}>
                                <div className={'title'} style={{fontSize:'16px',height:'48px'}}>{v.title.slice(0, 8)}
                                    <span style={{color: '#5D9BEF'}}>
                                {v.title.split('_')[2]}
                                </span>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className={'sub-title'} style={{fontSize:'14px'}}>{'Size'}</div>
                                    <div style={{marginLeft: '20px', fontSize:'14px'}}>{v.size}</div>
                                </div>
                                <div style={{display: 'flex', marginTop: '10px', alignItems: 'center'}}>
                                    <div className={'sub-title'} style={{fontSize:'14px'}}>{'온도특성'}</div>
                                    <div style={{marginLeft: '20px',fontSize:'14px'}}>{v.temperature}</div>
                                </div>
                            </SpecContainer>
                            <ImageContainer>
                                <img src={v.img} alt="" style={{objectFit: 'contain',width:'100%',height:'100%'}}/>
                            </ImageContainer>
                            <div style={{display:'flex',width:'100%',maxWidth:'312px', height:'1px',backgroundColor:'#E0E0E0'}}></div>

                        </div>
                    )}
                </div>
            </MainContainer>


        )
    }

    return (
        <>
            {isDesktop ? renderDesktop() : renderMobile()}
        </>


    );
};

export default V3ComponentSection;
//
// const ScrollSnapContainer = styled.div`
//     scroll-snap-type: y mandatory;
//     overflow-y:  scroll;
//     height: 100vh;
//     width: 100%;
//     box-sizing: border-box;
//     scroll-behavior: smooth;
//     scroll-snap-stop: always;
//
//      &::-webkit-scrollbar {
//         display: none;
//      }
//
//   &::-webkit-scrollbar-track {
//     background: transparent; /* 스크롤바 트랙 배경을 투명으로 설정 */
//   }
//
//   &::-webkit-scrollbar-thumb {
//     background: transparent; /* 스크롤바 썸 배경을 투명으로 설정 */
//   }
// `;

const ImageContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 440px;
    height: 336px;
    
    @media (max-width: 1024px) {
        width: 312px;
        height: 232px;
    
    }
   
`

const SpecContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 752px;
    height: 336px;
    justify-content: center;

    .title {
        font-size: clamp(14px, 1vw, 20px);
        font-weight: 600;
        margin-bottom: 20px;
        min-width: 300px;
        max-width: 312px;
        height: 56px;
        border: 1px solid #006DFF;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 16px 0px;
        box-sizing: border-box;
        background-color: #484848;
        color: #fff;
        border-radius: 28px;
    }

    .sub-title {
        font-size: clamp(14px, 1vw, 20px);
        font-weight: bold;
        color: #1E222D;
        border: 1px solid #006DFF;
        border-radius: 20px;
        padding: 0 16px;
        text-align: center;
        width: 104px;
        height: 40px;
        white-space: nowrap;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: center;
    }


`
