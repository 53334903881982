import React, {useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import Aos from "aos";
import {MainContainer} from "../../../styles/Styles";
import Images from "../../../styles/Images";
import styled from "styled-components";

const SolutionFasSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:430px)"
    });

    useEffect(() => {
            Aos.init({
                duration: 1000
            });
        }
        , [])

    const solutionArr =[
        {name:'P-BAS',img:[Images.smartFas.pbas1,Images.smartFas.pbas2,Images.smartFas.pbas3]},
        {name:'P-MS',img:[Images.smartFas.pms1,Images.smartFas.pms2,Images.smartFas.pms3]},
        {name:'F-EMS',img:[Images.smartFas.fems1,Images.smartFas.fems2,Images.smartFas.fems3]},
    ]

    return (
        <MainContainer
            style={{
                background: '#E0E0E0',
                padding: isDesktop ? '200px 16% 100px 16%' : '100px 5%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: isDesktop ? '100%' : isMobile ? '100%' : '100%',
            }}>
            {solutionArr.map((item,index)=>
                <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'100px'}}>
                    <Name style={{height:isDesktop?'40px':'32px'}}>
                        {item.name}
                    </Name>
                    <div style={{display:'flex',flexDirection:isDesktop?'row':'column'}}>
                    {Object.values(item.img).map((img,index)=>

                           <img src={img} alt={''} style={{ width: 'calc(400/1920 * 100vw)',marginTop: isDesktop?'32px':'20px',minWidth:'312px'}}/>

                    )}
                    </div>
                </div>
                )}

        </MainContainer>
    );
};

export default SolutionFasSection;


const Name = styled.div`
    font-size: clamp(14px, 4vw, 30px);
    font-weight: 600;
    color:#006DFF;
    text-align: center;
    width: calc(400/1920 * 100vw);
    min-width: 140px;
    background: #fff;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #006DFF;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
    

`
