import React, {useEffect} from 'react';
import styled from "styled-components";
import {ButtonProps} from "../../types/Fundamental";
import Aos from "aos";
import 'aos/dist/aos.css';
import {useMediaQuery} from "react-responsive";
import dayjs from "dayjs";





interface Props {
    year: any;
    data: any;
    compType?: string;
}



const TimeLineComponent:React.FC<Props> = ({year,data,compType}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const currentYear = 2022;


    useEffect(() => {
            Aos.init({
                duration: 1000
            });
        }
        , [])

    return (

        <div style={{display:'flex', gap:'60px',alignItems:'baseline'}}>
            <Years data-aos={'fade-right'} backgroundColor={currentYear === year ? '#006DFF' :''}>
                {year}
            </Years>
            <div style={{display:'flex',flexDirection:'column',gap:'40px',position:'relative'}}>
                {data.map((item:any,index:any) => {
                        const lineLength = item.text.length * 150;
                        return (
                            <History key={index}>
                                <Line height={lineLength}></Line>
                                <div data-aos={'fade-up'} className={'history_year'}>{item.date}</div>
                                {item.text.map((v:any,i:any) =>
                                    <div data-aos={'fade-up'}
                                         data-aos-delay={index * 100}
                                         key={i}>
                                        <div className={'history_title'}>{v}</div>
                                    </div>
                                )}
                            </History>
                        )
                    }
                )}

            </div>
        </div>

    );
};

export default TimeLineComponent;




const Years =styled.div<ButtonProps>`
  box-sizing: border-box;
  width: ${props => props.width ?? 120}px;
  height: ${props => props.height ?? 64}px;
  background-color: ${props => props.backgroundColor};
  border-radius: 36px;
  display: flex;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  align-items: center;
  position: relative;
  padding: 30px;
  z-index: 2;

  &::before {
    content: '';
    width: 27px;
    height: 4px;
    position: absolute;
    right: -30px;
    box-sizing: border-box;
    opacity: ${props => props.backgroundColor === '#006DFF' ? '1' : '0'};
    background-color: ${props => props.backgroundColor === '#006DFF' ? '#006DFF' : '#1E222D'};
  }

  &::after {
    content: '';
    position: absolute;
    right: -40px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${props => props.backgroundColor === '#006DFF' ? '#006DFF' : '#1E222D'};
    top: 50%;
    transform: translateY(-50%);
  }
`


const History = styled.div `
display: flex;
flex-direction: column;
  gap:10px;
  

  .history_year{
    color:#707070;
    font-size: clamp(18px, 1.5vw, 24px);
    font-weight: bold;
  }
  
  .history_title{
    color:#fff;
    font-size: clamp(16px, 1.5vw, 24px);
    font-weight: normal;
    white-space: nowrap;
  }
  
  .history_circle{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #1E222D;
    top: 50%;
  }
`

interface LineProps {
    height?: any;
}

const Line = styled.div<LineProps>`
    display: flex;
    width: 2px;
    height: ${props => props.height ?? 100}px;
    background-color: #1E222D;
    position: absolute;
    margin-top: 20px;
    z-index: 1;
    left:-30px;
    
 `

