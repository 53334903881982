import React from 'react';
import styled from "styled-components";
import checkicon from "../../asset/images/icons/Group 16843.svg";
import Buttons from "../buttons/Buttons";

interface ConfirmPopProps {
    text?: string;
    clickEvent?: () => void;
}

const ConfirmPop: React.FC<ConfirmPopProps> = ({ text, clickEvent }) => {
    return (
        <Container>
            <div className={'bg'}>
                <div className={'pop'}>
                    <div style={{ display:'flex', flexDirection: 'column',alignItems: 'center',gap: '40px', justifyContent:'center', flex: '1' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',gap:'35px'}}>
                            <img src={checkicon} alt="checkicon" style={{ width: '72px', height: '72px'}} />
                            <div className={'text'}>{text}</div>
                        </div>
                        <Buttons buttonType={'blue-sub'} text={'확인'}
                                 clickEvent={clickEvent} fontSize={'14px'} width={'240px'}
                                 height={'48px'} borderRadius={'16px'}/>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default ConfirmPop;

const Container = styled.div`
  .bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
  }

  .pop {
    width: 280px;
    height: 312px;
    background-color: #fff;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    box-shadow: 0px 3px 6px #00000029;
    flex-direction: column;
    align-items: center;
    transition: all 0.4s ease-in-out;
  }

  .text {
    font-size: 14px;
    text-align: center;
    white-space: pre-line;
  }
`;