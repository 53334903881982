import React from 'react';
import {MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import Images from "../../../styles/Images";

interface Props {
    productList?: any;
}

const SLMSComponentSection:React.FC<Props> = ({productList}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:430px)"
    });

    return (
        <MainContainer
            style={{
                background: '#fff',
                padding: isDesktop ? '200px 16%' : '100px 10%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: isDesktop ? '100%' : isMobile ? '100%' : '100%'
            }}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div style={{
                    display: 'flex', fontSize: 'clamp(18px ,2.5vw, 24px)',
                    color: '#006DFF', fontWeight: 'bold', marginBottom: '40px', width: '100%'
                }}>
                    {'설치 예시'}
                </div>
                {isDesktop ?
                    <img src={Images.slms.example} style={{width: '100%', height: '100%', objectFit: 'contain'}}/>
                    :
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <img src={Images.slms.install1} style={{maxWidth: '245px'}}/>
                        <img src={Images.slms.arrow}/>
                        <img src={Images.slms.install2} style={{maxWidth: '245px'}}/>
                    </div>
                }
            </div>

        </MainContainer>
);
};

export default SLMSComponentSection;
