import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {Autoplay, Navigation, Pagination, Scrollbar} from 'swiper/modules';
import {SwiperContainer} from "../../styles/Styles";
import {useMediaQuery} from "react-responsive";
// @ts-ignore
import {SwiperOptions} from 'swiper/types';
import {certificationData, newsData} from "../../common/DataSet";
import {ContainerProps} from "../../types/Fundamental";
import styled from "styled-components";

const CertificationSwiperContainer = () => {
    const swiperRef = React.useRef(null);
    const swiperParams: SwiperOptions = {
        slidesPerView: 2,
        modules: [Navigation, Pagination, Scrollbar,Autoplay],
        spaceBetween: 10,
        centeredSlides: false,
        slideToClickedSlide: true,
        speed: 1000,
        initialSlide: 0,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
        loop: true,
        pagination: {
            type: 'progressbar',
            progressbarFillClass: 'swiper-pagination-progressbar-fill',
            progressbarOpposite: false,

        },
        breakpoints: {
            280: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            425: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 10,
            }
        }

    }


    return (
        <SwiperContainer>
            <Swiper ref={swiperRef} {...swiperParams} className={'swiper-container-certification'} style={{width: '440px'}}>
                {certificationData.map((value, index) => (
                    <SwiperSlide className={'swiper-slide-certification'}
                                 key={index}>
                        <React.Fragment>
                            <CertPaper style={{backgroundImage: `url(${value.certImage})`}} text={value.certText}/>
                        </React.Fragment>
                    </SwiperSlide>
                ))}

            </Swiper>
            <div className={'swiper-pagination-progressbar'}></div>
        </SwiperContainer>
    );
};

export default CertificationSwiperContainer;


const CertPaper = styled.div<ContainerProps>`
    max-width: 192px;
    min-width: 200px;
    width: 100%;
    height: 100%;
    max-height: 404px;
    min-height: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;


    &::before {
        content: '${props => props.text}';
        position: absolute;
        bottom: 0;
        width: 100%;
        max-height: 100%;
        height: 0;
        background-color: rgba(36, 70, 150, 0.1);
        color: #fff;
        transition: all 0.3s;
        box-sizing: border-box;
        padding: 0 24px;
        overflow: hidden;
        line-height: 24px;
    }

    &:hover {
        &::before {
            content: '${props => props.text}';
            position: absolute;
            top:  50%;
            width: 100%;
            z-index: 1;
            height: 48px;
            background-color: rgba(30, 34, 45, 0.7);
            border-radius: 24px;
            color: #fff;
            font-size: clamp(14px, 1.5vw, 24px);
            font-weight: bold;
            text-align: center;
            box-sizing: border-box;
        
            transition: all 0.3s;
            overflow: hidden;
            line-height: 48px;
            
        }
    }
`