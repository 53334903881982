import React from 'react';
import Images from "../../../styles/Images";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";

const SafetySpecSection = () => {

    const isDesktop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    });


    const renderDesktop = () => {
        return (
            <MainContainer backgroundColor={'#fff'}
                           alignItems={'center'}
                           padding={'100px 16%'}
                           height={'100%'}>
                <div style={{
                    maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '100px',
                        justifyContent: 'center', width: '100%'
                    }}>
                        <img src={Images.safety.method} alt={'safety'}
                             style={{
                                 width: '100%',
                                 maxWidth: '1463px',
                                 minWidth: '300px',
                                 height: 'auto',
                                 objectFit: 'contain'
                             }}/>
                        <div style={{
                            fontSize: 'clamp(14px, 2.5vw, 20px)',
                            color: '#1e222d',
                            fontWeight: '600',
                            display: 'flex',
                            marginTop: '20px'
                        }}>
                            {'▲ AI 안전솔루션 CCTV 촬영 화면 예시'}
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        gap: !isDesktop ? '60px' : '100px',
                        flexDirection: !isDesktop ? 'column' : 'row',
                        marginTop: '0px',
                        width: '100%',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '100px',
                        }}>
                            <img src={Images.safety.notebook} alt={'safety'}
                                 style={{
                                     width: '100%',
                                     height: '100%', objectFit: 'contain'
                                 }}/>
                            <div style={{
                                fontSize: 'clamp(14px, 2.5vw, 20px)',
                                color: '#1e222d',
                                fontWeight: '600',
                                display: 'flex',
                                marginTop: '20px'
                            }}>
                                {'▲ AI 안전솔루션 시스템 연동 및 알람'}
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '100px',
                        }}>
                            <img src={Images.safety.site} alt={'safety'}
                                 style={{
                                     width: '100%',
                                     height: '100%', objectFit: 'contain'
                                 }}/>
                            <div style={{
                                fontSize: 'clamp(14px, 2.5vw, 20px)',
                                color: '#1e222d',
                                fontWeight: '600',
                                display: 'flex',
                                marginTop: '20px'
                            }}>
                                {'▲ AI 안전솔루션 설치 환경'}
                            </div>
                        </div>
                    </div>
                    <img src={Images.safety.solution} alt={'safety'}
                         style={{width: '100%', height: 'auto', objectFit: 'contain'}}/>


                    {/*</div>*/}
                </div>
            </MainContainer>
    )
    }

    const renderMobile = () => {
        return (
            <MainContainer
                style={{
                    background: '#F8F8F8',
                    padding: '100px 10% 100px 10%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>

                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '0px'}}>
                    <MobileSpecContainer>
                        <div className={'title'} style={{fontSize: '16px', height: '48px'}}>
                            {'AI 안전솔루션 CCTV 촬영 화면 예시'}
                        </div>
                    </MobileSpecContainer>
                    <ImageContainer style={{border: 'none'}}>
                        <img src={Images.safety.method} alt=""
                             style={{objectFit: 'contain', width: '100%', height: '100%'}}/>
                    </ImageContainer>
                    <div style={{
                        marginBottom: '60px',
                        display: 'flex',
                        width: '100%',
                        maxWidth: '312px',
                        height: '1px',
                        backgroundColor: '#E0E0E0'
                    }}></div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '0px'}}>
                    <MobileSpecContainer>
                        <div className={'title'} style={{fontSize: '16px', height: '48px'}}>
                            {'AI 안전솔루션 시스템 연동 및 알람'}
                        </div>
                    </MobileSpecContainer>
                    <ImageContainer style={{border: 'none'}}>
                        <img src={Images.safety.notebook} alt=""
                             style={{objectFit: 'contain', width: '100%'}}/>
                    </ImageContainer>
                    <div style={{
                        marginBottom: '60px',
                        display: 'flex',
                        width: '100%',
                        maxWidth: '312px',
                        height: '1px',
                        backgroundColor: '#E0E0E0'
                    }}></div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '0px'}}>
                    <MobileSpecContainer>
                        <div className={'title'} style={{fontSize: '16px', height: '48px'}}>
                            {'AI 안전솔루션 설치 환경'}
                        </div>
                    </MobileSpecContainer>
                    <ImageContainer style={{border: 'none'}}>
                        <img src={Images.safety.site} alt=""
                             style={{objectFit: 'contain', width: '100%', height: '100%'}}/>
                    </ImageContainer>
                    <div style={{
                        marginBottom: '60px',
                        display: 'flex',
                        width: '100%',
                        maxWidth: '312px',
                        height: '1px',
                        backgroundColor: '#E0E0E0'
                    }}></div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '0px'}}>

                    <MobileSpecContainer>
                        <div className={'title'} style={{fontSize: '16px', height: '48px'}}>
                            {'AI 안전솔루션 Process'}
                        </div>
                    </MobileSpecContainer>
                    <ImageContainer style={{border: 'none', marginBottom: '200px'}}>
                        <img src={Images.safety.process} alt=""
                             style={{objectFit: 'contain', width: '100%', height: '100%'}}/>
                    </ImageContainer>
                </div>


                <div style={{display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '0px'}}>
                    <div style={{position: 'relative', width: '360px', height: '880px'}}>
                        <div style={{position: 'absolute', top: '150px', left: '220px', zIndex: 2}}>
                            <NameContainer style={{width: '81px'}}>
                                {'SPMS'}
                            </NameContainer>
                        </div>
                        <div style={{
                            zIndex: 3, whiteSpace: 'pre-line', color: '#929292',
                            fontSize: '15px',
                            position: 'absolute', top: '220px', left: '120px'
                        }}>
                            {'※ Object 탐지 시\n프레스 안전 동작 제어'}
                        </div>
                        <div style={{position: 'absolute', top: '380px', left: '24px', zIndex: 2}}>
                            <NameContainer style={{width: '132px'}}>
                                {'AI 안전솔루션\nCCTV'}
                            </NameContainer>
                        </div>
                        <div style={{position: 'absolute', top: '530px', left: '200px', zIndex: 2}}>
                            <NameContainer style={{width: '120px'}}>
                                {'ZST Cloud'}
                            </NameContainer>
                        </div>
                        <div style={{position: 'absolute', top: '760px', left: '80px', zIndex: 2}}>
                            <NameContainer style={{width: '196px'}}>
                                {'PAS\n(Press Admin System)'}
                            </NameContainer>
                        </div>
                        <img src={Images.safety.line} alt="" style={{
                            position: 'absolute',
                            objectFit: 'contain',
                            width: '360px',
                            marginBottom: '100px'

                        }}/>
                    </div>
                </div>


            </MainContainer>
        )
    }

    return (
        <>
            {isDesktop ? renderDesktop() : renderMobile()}
        </>

    );
};

export default SafetySpecSection;


const ImageContainer = styled.div`
    display: flex;
    box-sizing: content-box;
    width: 296px;
    height: 408px;
    align-items: center;
    border: 1px solid #E0E0E0;
    position: relative;

    @media (max-width: 768px) {
        width: 312px;
        height: 200px;
    }

`

const SpecContainer = styled.div<{ row?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    background-color: ${props => props.row ? '#E0E0E0' : '#006DFF'};
    color: ${props => props.row ? '#707070' : '#fff'};
    border-radius: 20px;
    font-size: clamp(12px, 2vw, 20px);
    padding: 0 48px;
    white-space: nowrap;

    @media only screen and (max-width: 768px) {
        width: 120px;
        padding: 0 20px;
    }

    @media only screen and (max-width: 425px) {
        width: 80px;
        padding: 0 8px;
    }

    @media only screen and (max-width: 280px) {
        width: 80px;
        padding: 0 4px;
    }

`;


const MobileSpecContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    //width: 752px;

    justify-content: center;

    @media (max-width: 768px) {
        //width: 100%;

    }

    .title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 20px;
        width: 100%;
        min-width: 280px;
        max-width: 312px;
        height: 56px;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 16px 0px;
        box-sizing: border-box;
        background-color: #484848;
        color: #fff;
        border-radius: 28px;
    }

    .sub-title {
        font-size: 20px;
        font-weight: bold;
        color: #1E222D;
        border: 1px solid #006DFF;
        border-radius: 20px;
        padding: 0 16px;
        text-align: center;
        width: 104px;
        height: 40px;
        box-sizing: border-box;
        align-items: center;
        display: flex;
        justify-content: center;

`

const DetailContainer = styled.div<{ isNotice: boolean }>`

    background-color: ${props => props.isNotice ? '#EDF5FF' : 'none'};
    border-radius: ${props => props.isNotice ? '104px' : 'none'};
    width: 100%;
    max-width: 1232px;
    height: 207px;
    display: flex;
    justify-content: center;
    padding: 8% 250px;
    flex-direction: column;
    box-sizing: border-box;

    @media only screen and (max-width: 1440px) {
        padding: 8% 60px;
        border-radius: 56px;
    }

    .title {
        font-size: clamp(14px, 2vw, 20px);
        font-weight: 700;
        color: #006DFF;


    }

    .content {
        font-size: clamp(14px, 2vw, 20px);
        color: #1E222D;
        margin-top: 20px;
        white-space: pre-line;
        line-height: 1.5;
    }

 
}

`


const NameContainer = styled.div`

    white-space: pre-line;
    line-height: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 6px #0000001A;
    border-radius: 27px;
    font-size: 16px;
    color: #006dff;
    padding: 8px 10px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;



`
