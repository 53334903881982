import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useMediaQuery} from "react-responsive";

interface LooperProps {
    speed?: number;
    direction?: string;
    children?: React.ReactNode;

}
const Looper:React.FC<LooperProps> = ({speed,direction,children}) => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 821px)",
    }
    );
    const [looperInstances, setLooperInstances] = useState(1);
    const outerRef = useRef<HTMLDivElement>(null);
    const innerRef = useRef<HTMLDivElement>(null);


    const setupInstances = useCallback(() => {
        if (!innerRef?.current || !outerRef?.current) return;

        const { width } = innerRef.current.getBoundingClientRect();
        const { width: parentWidth } = outerRef.current.getBoundingClientRect();
        const instanceWidth = width / innerRef.current.children.length;

        if (width < parentWidth + instanceWidth) {
            setLooperInstances(looperInstances + Math.ceil(parentWidth / width));
        }
    }, [looperInstances]);

    useEffect(() => {
        setupInstances();
    }, []);

    function resetAnimation() {
        if (innerRef?.current) {
            innerRef.current.setAttribute("data-animate", "false");

            setTimeout(() => {
                if (innerRef?.current) {
                    innerRef.current.setAttribute("data-animate", "true");
                }
            }, 0);
        }
    }

    useEffect(() => {
        resetAnimation();
    }
    , [looperInstances]);

    useEffect(() => {
        window.addEventListener("resize", setupInstances);
        return () => {
            window.removeEventListener("resize", setupInstances);
        };
    }, []);

    return (
        <InfiniteLooper>
            <div className="looper" ref={outerRef}>
                <div className="looper__innerList" ref={innerRef}>
                    {[...Array(looperInstances)].map((_, ind) => (
                        <div
                            key={ind}
                            className="looper__listInstance"
                            style={{
                                animationDuration: `${speed}s`,
                                animationDirection: direction === "right" ? "reverse" : "normal",
                            }}
                        >
                            {children}
                        </div>
                    ))}
                </div>
            </div>
        </InfiniteLooper>
    );
};

export default Looper;


const InfiniteLooper = styled.div`
   @keyframes slideAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 1200px;
   overflow: hidden;
  
  
  @media (max-width: 820px) {
    width: 100%;
    height:  100%;
    overflow: hidden;
    max-width: 720px;
  }
}

.looper__innerList {
  display: flex;
  width: 100%;
  backdrop-filter: blur(10px);
}

.looper__listInstance {
  display: flex;
  width: max-content;

  animation: slideAnimation linear infinite;
}

  .looper__innerList[data-animate="true"] .looper__listInstance {
    animation: slideAnimation linear infinite;
  }

  .looper__listInstance {
    display: flex;
    width: max-content;

    animation: none;
  }
  `
