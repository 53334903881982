import React from 'react';
import styled from 'styled-components';
import DropMenu from "../buttons/DropMenu";
import {useMediaQuery} from "react-responsive";
import dayjs from "dayjs";
import Images from "../../styles/Images";
import { Document, Page, pdfjs } from 'react-pdf';

const annaouncementPDF = require('../../asset/pdf/11th.pdf');




const FinanceModal =({closeModal}: any) => {
    const isMobie = useMediaQuery({query: '(max-width: 430px)'});
    const [listIndex, setListIndex] = React.useState<number>(0)
    const years = Array.from({length: 1}, (_, i) => {
        return dayjs().subtract(i + 1, 'year').format('YYYY년');
    });

    const [pdfNum, setPdfNum] = React.useState<number>(1)
    const pdfRef = React.useRef<HTMLDivElement>(null);




    // pdf 이미지로 보여 주기
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    const handleDownloadPdf = () => {
        window.open(annaouncementPDF);
    };

    const onDocumentLoadSuccess = () => {
        setPdfNum(1); // PDF 파일이 한 페이지만 있으므로 페이지 수를 1로 고정
    }

    // const hideTextLayer = () => {
    //     const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    //     textLayers.forEach(layer => {
    //         // @ts-ignore
    //         layer.style.display = 'none';
    //     });
    // };


    return (
        <ModalBG>
            <BaseComp>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    position: 'relative',
                }}>
                    <div style={{display: 'flex', fontSize: 'clamp(18px,3vw,24px)', fontWeight: 800, color: '#000'}}>
                        {'연도별 재무제표'}</div>
                    <div style={{width: '24px', height: '24px'}}>
                        <img src={Images.icons.xButton} onClick={closeModal}
                             alt="xButton" style={{width: '16px', height: '16px', cursor: 'pointer'}}/>
                    </div>

                    {
                        isMobie?
                    <div style={{position:'absolute', top:'40px', right:0, borderRadius:'4px', fontWeight:700, display:'flex', justifyContent:'center',
                        backgroundColor:'#003A88', color:'#fff', width:'128px', height:'40px', alignItems:'center'}}
                    onClick={handleDownloadPdf}
                    >{'재무제표 다운로드'}</div>
                          :
                          <div style={{position:'absolute', top:'40px', left:0, borderRadius:'4px', fontWeight:700, display:'flex', justifyContent:'center',
                              backgroundColor:'#003A88', color:'#fff', width:'128px', height:'40px', alignItems:'center', cursor:'pointer'}}
                               onClick={handleDownloadPdf}
                          >{'재무제표 다운로드'}</div>
                    }

                    {isMobie ?
                        <DropMenu menuArray={years} selectValue={years[listIndex]}
                                  width={'120px'} height={40} top={'40px'} left={'0%'}
                                  clickEvent={(value) => setListIndex(years.indexOf(value))}/>
                        :
                        <DropMenu menuArray={years} selectValue={years[listIndex]}
                                  width={'120px'} height={40} top={'40px'} left={'76%'}
                                  clickEvent={(value) => setListIndex(years.indexOf(value))}/>}


                </div>
                {listIndex === 0 ?
                    <DocComp
                      // onClick={handleDownloadPdf} ref={pdfRef}
                    >
                        <Document className={'pdf-document'}
                            file={annaouncementPDF} onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page renderTextLayer={false}  renderAnnotationLayer={false}
                                pageNumber={pdfNum} width={isMobie?400:580} height={isMobie?680:800}/>
                        </Document>
                    </DocComp>
                    :
                    <div style={{
                        width: '580px',
                        marginTop: '60px',
                        display: 'flex',
                        border: '1px solid #D7DBEC',
                        height: '800px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fff'
                    }}>
                        {'준비중입니다'}
                    </div>}
            </BaseComp>
        </ModalBG>

    );
};

export default FinanceModal;


const ModalBG = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
`

const BaseComp = styled.div<{ open?:boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 30px 50px 30px 50px;
    box-sizing: border-box;
    border-radius:8px;
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 1000px;
    min-width: 300px;
    min-height: 500px;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    //border-radius: 16px;
    


    @media (max-width: 430px) {
        padding: 30px 20px 30px 20px;
    }
`

const DocComp = styled.div`
    width: 100%;
    height: 100%;
    max-width: 580px;
    max-height: 820px;
    background-color: #fff;
    margin-top: 60px;
    border: 1px solid #D7DBEC;
    overflow-y: auto;
    display: flex;
    justify-content: center;
 

    @media (max-width: 430px) {
        margin-top: 80px;
        width: 400px;
        height: 600px;
        overflow-y: auto;
    }

    .pdf-document {
        width:580px;
        height:820px;
        
        @media (max-width: 430px) {
            width: 400px;
            height: 680px;
        }
    }
    
 
    
  
    

   
  


`

