import React from 'react';
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";
import bg from "../../../asset/images/photo_1.png";
import Buttons from "../../../components/buttons/Buttons";
import {useMediaQuery} from "react-responsive";
import {useTranslation} from "react-i18next";

const MainServiceSection = () => {
    const {t}: any = useTranslation();
    const isDesktop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 820px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:430px)"
    });

    return (
        <MainContainer height={!isMobile ? '800px' : '480px'}
                       backgroundImage={bg} justifyContent={'start'}>
            <div className={'picture'} style={{
                display: 'flex',
                width: '100%', height: '100%', backgroundSize: 'cover',
                backgroundPosition: isDesktop ? 'center' : (isTablet ? 'center' : 'left')
            }}>
                <div style={{
                    maxWidth: '1232px', alignSelf: 'center',
                    padding: !isMobile?'0 16%':'0 6%',
                    margin: !isMobile?'0 auto':'0 6%',
                    justifyContent: 'center', width: '100%'
                }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column', gap: '64px'
                        }}>
                            <div className={'hero_text'} style={{
                                fontWeight: '700',
                                fontSize: 'clamp(24px, 6vw, 56px)',
                                zIndex: '2',
                                lineHeight: '1.3',
                                width: '100%',
                                textAlign: 'left',
                                whiteSpace: 'pre-line'
                            }}>

                                {isDesktop ?
                                    <>
                                        {t(`산업 현장 및 사용자 경험에 적합한
                     스마트한 서비스를 
                     제스텍에서 경험해보세요.`)} </>
                                    : <>
                                        {t(`산업 현장 및 사용자 경험에
                            적합한 스마트한 서비스를
                            제스텍에서 경험해보세요.`)}
                                    </>}
                            </div>
                            <Buttons buttonType={'blue-main'} width={isDesktop ? '320px' : '216px'}
                                     height={isDesktop ? '64px' : '48px'}
                                     fontSize={isDesktop ? '24px' : '16px'}
                                     clickEvent={() => window.location.href = '/cs'}
                                     text={t('맞춤 서비스 신청하기 >')}/>
                        </div>
                    </div>
                </div>

        </MainContainer>
    );
};

export default MainServiceSection;


