import React from 'react';
import styled from "styled-components";
import Images from "../../styles/Images";
import dayjs from "dayjs";
import {useMediaQuery} from "react-responsive";
import FinanceModal from "./FinanceModal";



const IRModal = ({closeModal}: any) => {

    const isMobie = useMediaQuery({query: '(max-width: 430px)'});

    const years = Array.from({length: 11}, (_, i) => {
        return dayjs().subtract(i + 1, 'year').format('YYYY년');
    });

    const [listIndex, setListIndex] = React.useState<number>(0)
    const [viewNotice, setViewNotice] = React.useState<boolean>(false)

    return (
        <ModalBG>
            <BaseComp>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                }}>
                    <div style={{display:'flex',fontSize:'clamp(20px,3vw,32px)', fontWeight: 800, color: '#fff',alignItems:'baseline'}}>
                        {'IR'}
                        <div style={{marginLeft:'8px',fontSize:'clamp(12px,3vw,14px)', fontWeight: 400, color: '#fff',opacity:'0.7'}}>
                            {'Investor relations'}
                        </div>
                    </div>
                    <div style={{width:'24px',height:'24px'}}>
                    <img src={Images.icons.xButton} onClick={closeModal}
                         alt="xButton" style={{width: '16px', height: '16px', cursor: 'pointer'}}/>
                    </div>
                </div>
                <div style={{display:'flex',justifyContent:'center',height:'100%',alignItems:'center',gap:'20px'}}>
                    <MenuNameComp onClick={()=>setViewNotice(!viewNotice)}>
                        {'연도별\n재무제표'}
                    </MenuNameComp>
                    {/*<MenuNameComp onClick={()=>setViewNotice(!viewNotice)}>*/}
                    {/*    {'연도별\n재무제표'}*/}
                    {/*</MenuNameComp>*/}
                    {/*<MenuNameComp onClick={()=>setViewNotice(!viewNotice)}>*/}
                    {/*    {'연도별\n재무제표'}*/}
                    {/*</MenuNameComp>*/}

                </div>
                {viewNotice &&
                    <FinanceModal closeModal={ ()=>setViewNotice(false)}/>
                }

            </BaseComp>
        </ModalBG>
    );
};

export default IRModal;

const ModalBG = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
`
const BaseComp = styled.div<{open?:boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    padding: 30px 50px 30px 50px;
    box-sizing: border-box;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    max-width: 960px;
    max-height: 700px;
    min-width: 300px;
    min-height: 300px;
    background-color: rgba(30, 34, 45, 0.9);
    box-shadow: 0px 3px 6px #00000029;
    //border-radius: 16px;
    @media (max-width: 430px) {
        height: 300px;
        padding: 30px 20px 30px 20px;
    }
`

const MenuNameComp = styled.div<{disabled?:boolean}>`
    width: 200px;
    height: 200px;
    background-color: #000;
    box-sizing: border-box;
    color: #fff;
    font-size: clamp(20px,3vw,32px);
    font-weight: 600;
    padding:16px;
    white-space: pre-line;
    cursor:pointer;
    position: relative;
    z-index: 1;
    
    @media (max-width: 430px) {
        width: 150px;
        height: 100px;
        padding:8px;
    }


    &:before {
        background-color: #003A88;
        position: absolute;
        content: '';
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0px;
        z-index: -1;
        transition: width 0.3s, height 0.3s;  
    }

    &:hover:before {
        height: 100%;
    }
    
`


