import React from 'react';
import {useMediaQuery} from "react-responsive";

import {MainContainer} from "../../../styles/Styles";
import Images from "../../../styles/Images";
import SafetySpecSection from "./SafetySpecSection";



const SafetyHeroSection:React.FC = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1440px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 1023px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:425px)"
    });

    const backgroundStyle = {
        padding: isDesktop ? '100px 16%' : '60px 6%',
        background: 'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(224, 224, 224, 1))',
        backgroundSize: 'cover',
        width: '100%',
        backgroundPosition: isDesktop ? 'center' : 'left',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
        // safari에서 보여지도록 하려면 아래와 같이 웹킷 접두사를 사용.
        WebkitBackgroundClip: 'border-box',
    };


    return (
        <MainContainer backgroundImage={backgroundStyle.background}
                       padding={backgroundStyle.padding}
                       justifyContent={'center'}
                       alignItems={'center'}
                       height={isMobile?'100%':'100%'}>

                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',
                     width:'100%'}}>
                    <img src={Images.safety.icThumb } alt={'safety'} style={{width:isDesktop?'189px':'133px',height:isDesktop?'189px':'133px',objectFit:'cover'}}/>
                    <div style={{
                             display: '-webkit-box',
                             WebkitLineClamp: 2,
                             WebkitBoxOrient: 'horizontal',
                             fontSize: 'clamp(24px, 4.8vw, 40px)',
                             fontWeight: 'bold',
                             overflow: 'visible',
                             marginTop: isDesktop ? '30px' : '10px',
                             color: '#1E222D',
                             whiteSpace:'pre-line',
                             letterSpacing: ' 0.0em',
                             textAlign: 'center',
                             lineHeight: 1.3,
                         }}>
                        {'제스텍 '}
                        <span style={{textDecoration:'underline',textUnderlineOffset:'5px'}}>{'AI 안전솔루션'}</span>
                        {'의'}
                        <br/>
                        <span style={{color:'#006dff'}}>{'장점'}</span>
                    </div>
                    <div style={{
                        marginTop: isDesktop ? '40px' : '30px',
                        color: '#1E222D',
                        fontSize: 'clamp(18px, 2.5vw, 32px)',
                        display: 'flex',
                        fontWeight: '600',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'horizontal',
                        alignItems: 'center',
                        overflow: 'visible',
                        whiteSpace: 'pre-line',
                        lineHeight: 1.5,
                        flexDirection: 'column',
                        gap: isDesktop?'100px':'40px',
                        textAlign: 'center'
                    }}>
                        <img src={Images.safety.eye} alt={'safety'} style={{
                            width: '60px',
                            height: '60px', objectFit: 'cover'
                        }}/>
                        <div>{'사람이 일일이 지켜보고 서 있을 수 없기 때문에\n'}
                            <span style={{color: '#006dff', fontWeight: '600'}}>AI 카메라가 ‘사람의 눈’</span>을
                            대신합니다.
                        </div>

                        <img src={Images.safety.sensor} alt={'safety'} style={{
                            width: '60px',
                            height: '60px', objectFit: 'cover'
                        }}/>
                        <div>{'안전 규칙에 위반되는 상황들을 알고리즘으로 만들어 판독하고\n'}
                            <span style={{color: '#006dff', fontWeight: '600'}}>‘위험 상황’을 즉시 감지</span>
                            합니다.
                        </div>
                        <img src={Images.safety.alarm} alt={'safety'} style={{
                            width: '60px',
                            height: '60px', objectFit: 'cover'
                        }}/>
                        <div>{'사물과 사람을 구분하여 위험 경고를 보내줍니다.'}</div>

                        <img src={Images.safety.control} alt={'safety'} style={{
                            width: '60px',
                            height: '60px', objectFit: 'cover'
                        }}/>
                        <div> 별도
                            <span style={{color: '#006dff', fontWeight: '600'}}>
                               {'PLC 설치 없이'}</span>
                            {' (주)제스텍의 SPMS\n(스마트 프레스 모니터링 시스템)을\n통해 경고에 맞춰 제어 가능합니다.'}
                        </div>
                    </div>
                </div>


        </MainContainer>
    );
};

export default SafetyHeroSection;