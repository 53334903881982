import React from 'react';
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";
import Images from "../../../styles/Images";

const SafetyServiceSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    });

    const tableArr = [
        {title: '네트워크 공사비', wire: 'O', wireless: 'X'},
        {title: '월평균 유지비', wire: '평균 100,000원/월', wireless: '대당 120,000원/월'},
        {title: '유지보수 관리', wire: '어려움', wireless: '쉬움'},
        {title: '확장성', wire: '어려움', wireless: '쉬움'},
    ]

    const createTable = () => {
        return (
            <div style={{
                marginTop: '100px',
                display: 'grid',
                gridTemplateColumns: !isDesktop ? 'auto auto auto' : '258px 311px 311px',
                width: !isDesktop ? '100%' : '988px',
                gap: isMobile ? '8px' : '10px',
                gridColumnGap: isMobile ? '10px' : '20px',
                alignItems: 'center',
                justifyContent: 'center'

            }}>
                {/* 헤더 */}
                <div style={{gridColumn: '1/2'}}> {/* 공백칸에 해당하는 부분 */}</div>
                <SpecContainer style={{gridColumn: '2/3'}}>
                    {'유선'}
                </SpecContainer>
                <SpecContainer style={{gridColumn: '3/4'}}>
                    {'무선'}
                </SpecContainer>
                {/* 테이블 내용 */}
                {tableArr.map((v, i) => (
                    <React.Fragment key={i}>
                        <div
                            style={{width: '100%', height: '1px', backgroundColor: '#707070', gridColumn: '1/4'}}></div>
                        <SpecContainer style={{gridColumn: '1/2'}} row={true}>
                            {v.title}
                        </SpecContainer>
                        <div style={{
                            gridColumn: '2/3',
                            textAlign: 'center',
                            lineHeight: isMobile ? '30px' : '50px',
                            fontSize: 'clamp(14px, 2.5vw, 20px)',
                            color: '#707070'
                        }}>
                            {v.wire}
                        </div>
                        <div style={{
                            gridColumn: '3/4',
                            textAlign: 'center',
                            lineHeight: isMobile ? '30px' : '50px',
                            fontSize: 'clamp(14px, 2.5vw, 20px)',
                            color: '#707070'
                        }}>
                            {v.wireless}
                        </div>
                    </React.Fragment>
                ))}
            </div>
        )
    }

    const createMobileTable = () => {
        return (
            <GridTable>
                <div className="fixed_side">
                    <div style={{display:'flex'}}>
                    <img src={Images.safety.icArrow} alt="arrow" style={{position:'relative',left:'20px',top:'-3px',width: '20px', height: '20px'}}/>
                    <div style={{fontSize:'12px',color:'#D0D0D0',whiteSpace:'pre-line',textAlign:'right',lineHeight:'1.6'}}>
                        {'넘겨서\n비교해보세요'}
                    </div>
                    </div>
                    <SpecContainer>{'유선'}</SpecContainer>
                    <SpecContainer>{'무선'}</SpecContainer>
                </div>
                <div className="scroll_wrapper">
                    <div className="header">
                        <div>{'네트워크 공사비'}</div>
                        <div>{'월평균 유지비'}</div>
                        <div>{'유지보수 관리'}</div>
                        <div>{'확장성'}</div>
                    </div>

                        <div className="row">
                            {tableArr.map((v, i) =>
                            <div>{v.wire}</div>
                             )}
                        </div>
                            <div className="row">
                                {tableArr.map((v, i) =>
                            <div>{v.wireless}</div>
                                )}
                       </div>
                </div>



            </GridTable>

        )
    }

    const serviceArray = [
        {
            name: '서비스 지원',
            content: ['객체인식(사람) 검출 모델 업데이트.',
                '재 학습 데이터 저장(로컬PC)및 원격 데 이터 수집 지원.',
                'Fas 연동 시 Camera 모니터링 지원.',
                '사내 Camera 모니터링 시스템 구축 (구성금액 별도.)',
                '무선 서비스(구성금액 별도.)*'],
            note: '*1대 카메라 연결 당 120,000원/월(5년 약정 기준)'
        },
        {
            name: '알려드립니다',
            content: ['안전솔루션 Network 기본 구성은 유선입니다.',
                '사내 사용중인 유선 망 활용.',
                '기업 고정 IP 별도 신청 권고(영상 트래픽으로 전산망 충돌 방지).',
                '네트워크공사비 별도.']
        }]





    const renderDesktop = () => {
        return (
            <MainContainer
                style={{
                    background: '#fff',
                    padding: '100px 16% 100px 16%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                <div style={{
                    maxWidth: '1232px', alignSelf: 'center', margin: !isMobile ? '0 auto' : '0 6%',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        marginTop: '100px',
                        flexDirection: !isDesktop ? 'column' : 'row',
                        alignSelf: 'start',
                        gap: '20px'
                    }}>
                        <SpecContainer>
                            {'서비스 지원'}
                        </SpecContainer>
                        <div style={{
                            fontSize: 'clamp(14px, 2.5vw, 20px)',
                            marginBottom: isMobile ? '80px' : '200px',
                            // marginTop:'20px',
                            textAlign: 'left',
                            color: '#1e222d',
                            marginLeft: isMobile ? '0px' : '30px',
                            fontWeight: '500',
                            whiteSpace: 'pre-line',
                            lineHeight: '1.8'
                        }}>
                            {`객체인식(사람) 검출 모델 업데이트. 
                            재 학습 데이터 저장(로컬PC)및 원격 데이터 수집 지원. 
                            ㆍFas 연동 시 Camera 모니터링 지원.
                            ㆍ사내 Camera 모니터링 시스템 구축(구성금액 별도.) 
                            ㆍ무선 서비스(구성금액 별도.)
                             * 1대 카메라 연결 당 120,000원/월(5년 약정 기준)`}
                        </div>
                    </div>

                    <DetailContainer isNotice={true}>
                        <div className={'title'}>
                            {'안전솔루션 Network 기본 구성은 유선입니다.'}
                        </div>
                        <div className={'content'}>
                            {`- 사내 사용중인 유선 망 활용. 
                              - 기업 고정 IP 별도 신청 권고(영상 트래픽으로 전산망 충돌 방지). 
                              - 네트워크공사비 별도.`}
                        </div>
                    </DetailContainer>
                    {createTable()}
                </div>
            </MainContainer>

    )
    }

    const renderMobile = () => {
        return (
            <MainContainer
                style={{
                    background: '#fff',
                    padding: '80px 5% 80px 5%',
                    flexDirection: 'column',
                    justifyContent: 'center',

                    height: '100%',
                }}>
                <div style={{
                    display: 'flex',
                    marginTop: '100px',
                    flexDirection: !isDesktop ? 'column' : 'row',
                    alignSelf: 'start',
                    gap: '20px'
                }}>
                    {serviceArray.map((v, i) =>
                        <>
                            <SpecContainer style={{width: '143px', height: '56px', fontWeight: 'bold', borderRadius: '28px'}}>
                                  {v.name}
                             </SpecContainer>

                            {v.content.map((item, index) => (

                            <div style={{ flexDirection: 'column',
                                display: 'flex',
                                marginTop: '0px'}}>
                                <div style={{display:'flex', alignItems: 'center',gap: '10px',}}>
                                <div style={{
                                    boxSizing: 'border-box',
                                    background: !isDesktop ? '#006dff' : '#000',
                                    width: '6px',
                                    height: '6px',
                                    borderRadius: '50%',
                                }}></div>
                                <div style={{
                                    width: '100%',
                                    lineHeight: '1',
                                    fontSize: 'clamp(18px, 2.5vw, 20px)',
                                    whiteSpace: 'pre-line'
                                }}>
                                    {/*//2번째 인덱스의 첫번째 요소일 경우*/}
                                    {i === 1 && index === 0 ?
                                        <div style={{fontWeight: '600', color: '#006dff'}}>{item}</div>
                                        : <div style={{color: '#1e222d'}}>{item}</div>}

                                </div>
                                </div>
                                {v.note && index === v.content.length - 1 &&
                                    <div style={{
                                        fontSize: '15px',
                                        display:'flex',
                                        width:'100%',
                                        alignItems:'center',
                                        marginTop: '16px',
                                        color: '#929292',
                                        fontWeight: '500'
                                    }}>
                                        {v.note}
                                    </div>}
                            </div>
                            ))}
                        </>
                    )}

                </div>
                {createMobileTable()}

            </MainContainer>
        )
    }


    return (
        <>
            {isDesktop ? renderDesktop() : renderMobile()}
        </>
    );
};

export default SafetyServiceSection;


const SpecContainer = styled.div <{ row?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    background-color: ${props => props.row ? '#E0E0E0' : '#006DFF'};
    color: ${props => props.row ? '#707070' : '#fff'};
    border-radius: 20px;
    font-size: clamp(20px, 2vw, 20px);
    padding: 0 48px;
    white-space: nowrap;

    @media only screen and (max-width: 768px) {
        width: 120px;
        padding: 0 20px;
    }

    @media only screen and (max-width: 425px) {
        width: 80px;
        padding: 0 8px;
    }

    @media only screen and (max-width: 280px) {
        width: 80px;
        padding: 0 4px;
    }

`;


const DetailContainer = styled.div<{ isNotice: boolean }>`
    background-color: ${props => props.isNotice ? '#EDF5FF' : 'none'};
    border-radius: ${props => props.isNotice ? '104px' : 'none'};
    width: 100%;
    max-width: 1232px;
    height: 207px;
    display: flex;
    justify-content: center;
    padding: 8% 250px;
    flex-direction: column;
    box-sizing: border-box;
    
    @media only screen and (max-width: 1440px) {
        padding: 8% 60px;
        border-radius: 56px;
    }

    .title {
        font-size: clamp(14px, 2vw, 20px);
        font-weight: 700;
        color: #006DFF;


    }
    .content{
        font-size: clamp(14px, 2vw, 20px);
        color: #1E222D;
        margin-top: 20px;
        white-space: pre-line;
        line-height: 1.5;
    }
    
    `


const GridTable = styled.div`
    display: grid;
    grid-template-columns: 100px 100px 100px 1fr;
    grid-template-rows: 48px 48px 48px;
    height: 400px;
    width: 100%;
    margin-top:80px;
    
    //@media only screen and (max-width: 768px) {
    //    width: 400px;
    //}
    @media only screen and (max-width: 425px) {
        width: 330px;
    }

    &::-webkit-scrollbar {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
    }

    .fixed_side {
        position: sticky;
        width:100%;
        box-sizing: border-box;
        align-items: center;
         justify-content: center;
        height: 220px;
        display: grid;
        gap: 10px;
        top: 0;
        background-color: #fff;
        z-index: 2; /* 헤더 위에 나타나도록 z-index 설정 */
       
    }

    .header {
        display: grid;
        width: 800px;
        align-items: center;
        height:42px;
        margin-left: 20px;
        grid-template-columns: 200px 200px 200px 200px;
        background-color: #F2F2F2;
        white-space: nowrap;
        text-overflow: ellipsis;
        
        
        @media only screen and (max-width: 375px) {
            width: 600px;
            grid-template-columns: 150px 150px 150px 150px;
        }
        
        
        
        
        &>div {
            display: grid;
            height: 42px;
            box-sizing: border-box;
            //border-right: 1px solid #707070;
            align-items: center;
            justify-content: center;
            color:#707070;
            font-size: 18px;
            font-weight: 600;
            
        }
    }
    
    .scroll_wrapper {
        width: 800px;
        height: 300px;
        align-items: center;
        
        overflow-x: scroll;
        grid-template-rows: repeat(auto-fill, 48px);  
        
        @media only screen and (max-width: 768px) {
            width: 600px;
        }
        @media only screen and (max-width: 425px) {
            width: 350px;
        }
        @media only screen and (max-width: 414px) {
            width: 300px;
        }
        @media only screen and (max-width: 375px) {
            width: 260px;
        }
        @media only screen and (max-width: 320px) {
            width: 230px;
        }
    }

    .row {
        display: grid;
        margin-top: 40px;
        margin-left: 20px;
        grid-template-columns: 200px 200px 200px 200px;
        height: 44px;
        align-items: center;
        background-color: #fff;
        white-space: nowrap;
        width :800px;

        @media only screen and (max-width: 375px) {
            width: 600px;
            grid-template-columns: 150px 150px 150px 1fr;
        }
         
        
        &>div {
           
            display: grid;
            height: 44px;
            align-items: center;
            color:#707070;
            font-size: clamp(16px, 2.5vw, 18px);
            font-weight: 600;
            justify-content: center;
        }
    }
`;
