import React from 'react';
import styled from "styled-components";
import {ButtonProps} from "../../types/Fundamental";
import down from "../../asset/images/icons/Rectangle 376.png";

const Buttons:React.FC<ButtonProps> = ({  margin,padding,buttonType, fontSize,clickEvent,
                                           width,height,text,borderRadius,minWidth,maxWidth,
                                           disabled,selected,iconWidth}) => {

    const getButtonStyles = (buttonType: string | undefined, fontSize: string, minWidth: string | undefined, padding: string | undefined) => {
        switch (buttonType) {
            case 'blue-main':
                return {
                    color: '#fff',
                    borderRadius: '32px',
                    fontSize: fontSize ?? '24px',
                    fontWeight: 600,
                    minWidth: minWidth ?? 'auto',
                    backgroundColor: '#006DFF',
                    padding: padding ?? '0 16px',
                };
            case 'blue-sub':
                return {
                    color: '#fff',
                    borderRadius: '28px',
                    fontSize: fontSize?? '20px',
                    fontWeight: 600,
                    backgroundColor: '#006DFF',
                    padding: padding ?? '0 16px',
                };
            case 'comp-name':
                return {
                    color: selected? '#fff':'#006DFF',
                    textDecoration: selected?'underline':'none',
                    borderRadius: '28px',
                    fontSize: fontSize?? '20px',
                    fontWeight: selected? 500:400,
                    backgroundColor: selected?'#006DFF':'#EDF5FF',
                    padding: padding ?? '0 16px',
                    minWidth: minWidth ?? 'auto',
                };
            case 'black-main':
                return {
                    color: '#fff',
                    borderRadius: '32px',
                    fontSize: fontSize ?? '24px',
                    fontWeight: 600,
                    backgroundColor: '#1E222D',
                };
            case 'black-sub':
                return {
                    color: '#006DFF',
                    borderRadius: '24px',
                    fontSize:fontSize ?? '20px',
                    fontWeight: 400,
                    backgroundColor: '#1E222D',
                };
            case 'download':
                return {
                    color: '#fff',
                    borderRadius: '36px',
                    fontSize: fontSize??'24px',
                    fontWeight: 600,
                    backgroundColor: '#1E222D',
                };
             case 'active':
                return {
                    color: selected? '#fff' : '#707070',
                    borderRadius: '32px',
                    fontSize: fontSize ?? '24px',
                    fontWeight: 600,
                    minWidth: minWidth ?? 'auto',
                    backgroundColor: selected? '#006DFF' : '#1E222D',

                }
             case 'disabled':
                 return {
                     color: '#fff',
                     borderRadius: '32px',
                     fontSize: fontSize ?? '24px',
                     fontWeight: 600,
                     backgroundColor: '#003A88',
                 }

            default:
                return {
                    color: '#fff',
                    borderRadius: '32px',
                    fontWeight: 600,
                    fontSize: fontSize ?? '24px',
                    backgroundColor: '#006DFF',
                };
        }
    };

    const getHoverStyle = (buttonType: string | undefined) => {
        switch (buttonType) {
            case 'blue-main':
            case 'blue-sub':
                return {
                    color: '#fff',
                    backgroundColor: '#043f93',
                };
            case 'comp-name':
                return {
                    color: '#fff',
                    backgroundColor: '#006DFF',
                };
            case 'black-main':
            case 'black-sub':
            case 'download':
                return {
                    color: '#fff',
                    backgroundColor: '#393d54',
                };
            default:
                return {
                    color: '#fff',
                };
        }
    };

    return (
        <StyledButton
            buttonStyle={getButtonStyles(buttonType, fontSize, minWidth, padding)}
            hoverEvent={getHoverStyle(buttonType)}
            onClick={clickEvent}
            width={width}
            height={height}
            padding={padding}
            borderRadius={borderRadius}
        >
            {buttonType !== 'download' ? (
                text
            ) : (
                <DownloadButtonContent>
                    <div>{text}</div>
                    <img src={down} alt="download"  style={{width:iconWidth}}/>
                </DownloadButtonContent>
            )}
        </StyledButton>
    );
};



   export default Buttons;


const StyledButton = styled.div<ButtonProps>`
    user-select: none;
    box-sizing: border-box;
    width: ${(props) => props.width ?? 'auto'};
    height: ${(props) => props.height ?? '48px'};
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    border-radius: ${(props) => props.borderRadius ?? '32px'};
    font-size: ${(props) => props.buttonStyle.fontSize ?? '24px'};
    font-weight: ${(props) => props.buttonStyle.fontWeight};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    line-height: 1.2;
    box-sizing: border-box;
    text-decoration: ${(props) => props.buttonStyle.textDecoration};
    text-underline-offset: 5px;
    white-space: nowrap;
    padding: ${(props) => props.padding ?? '0 16px'};
    margin: ${(props) => props.margin};
    border: ${(props) => props.buttonStyle.border};
    color: ${(props) => props.buttonStyle.color};
    background-color: ${(props) => props.buttonStyle.backgroundColor};
    transition: all 0.1s ease;

    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.hoverEvent.backgroundColor};
        color: ${(props) => props.hoverEvent.color};
        font-weight: ${(props) => props.hoverEvent.fontWeight};
    }
`;

const DownloadButtonContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  div {
    margin-left: 20px;
    align-self: center;
    position: relative;
    width: calc(100% - 32px);
  }
  img {
    position: relative;
    right: -10px;
    width: 40px;
    max-height: 100%;
  }
`;


