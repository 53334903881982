import React, {useEffect, useState} from 'react';
import {ContentFrame} from "../../styles/Styles";
import styled from "styled-components";
import SolutionHeroSection from "./hero/SolutionHeroSection";
import SolutionInfoSection from "./solution/SolutionInfoSection";
import SolutionSuccessSection from "./success/SolutionSuccessSection";
import SolutionCtaSection from "./hero/SolutionCtaSection";
import ReactPageScroller from "react-page-scroller";
import Aos from "aos";
import {useMediaQuery} from "react-responsive";
import Footer from "../../components/footer/Footer";
import SolutionTableSection from "./table/SolutionTableSection";
import SolutionFasSection from "./fas/SolutionFasSection";

const SolutionScrollSection = () => {


    // const handlePageChange = (number: number) => {
    //     setCurrentPage(number);
    // }

    // useEffect(() => {
    //     Aos.init({
    //     });
    // },[])

    const isMobile = useMediaQuery({
        query: "(max-width:768px)"
    })



    return (
        <ContentFrame>
                <ScrollSnapContainer>
                    <SolutionHeroSection/>
                    <SolutionInfoSection/>
                    <SolutionTableSection/>
                    {/*<SolutionSuccessSection/>*/}
                    <SolutionFasSection/>
                     <SolutionCtaSection/>
                 </ScrollSnapContainer>
        </ContentFrame>

    );
};

export default SolutionScrollSection;

const ScrollSnapContainer = styled.div`
  //scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  //scroll-snap-stop: always;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & > div {
    scroll-snap-align: start;
  }
`;