import React, {useEffect} from 'react';
import {MainContainer} from "../../../styles/Styles";
import Buttons from "../../../components/buttons/Buttons";
import {productKeywords} from "../../../common/DataSet";
import Aos from "aos";
import {useMediaQuery} from "react-responsive";
import styled from "styled-components";
import Looper from "../../../components/looper/Looper";
import {useTranslation} from "react-i18next";

const SubProductSection = () => {
    const {t}: any = useTranslation();
    const isDesktop = useMediaQuery({
        query: "(min-width: 821px)",
    });

    const isTablet = useMediaQuery({
        query: "(min-width: 280px) and (max-width: 820px)"
    })

    const isMobile = useMediaQuery({
        query: "(max-width: 280px)"
    })

    // useEffect(() => {
    //             Aos.init({duration: 1000});
    //         }
    //         ,[])
    const renderButtonRow = (start: number, end: number) => {
        return (

            <ButtonGroup>
            <div className={'button-row'}>
                {productKeywords.slice(start, end).map((item, index) => (
                    <React.Fragment key={index}>
                        <Buttons
                            buttonType={'black-sub'}
                            fontSize={isDesktop ? '24px' : '16px'}
                            padding={'0 30px'}
                            width={isDesktop ? item.keyword.length * 20 % + 'px' : 'auto'}
                            height={'48px'}
                            text={t(item.keyword)}
                        />
                    </React.Fragment>
                ))}
            </div>
            </ButtonGroup>

        );
    };

    return (
        <MainContainer padding={isDesktop ? '300px 16%' : '100px 8%'} justifyContent={'center'} overflow={'hidden'}
                       height={'100%'}>
            <div style={{maxWidth: '1232px', alignSelf: 'center', width: '100%'}}>
                <div className={'hero_text'}
                    // data-aos={'fade-up'}
                     style={{
                         display: '-webkit-box',
                         WebkitLineClamp: 3,
                         WebkitBoxOrient: 'horizontal',
                         fontSize: 'clamp(24px, 3.4vw, 48px)',
                         // fontSize:isDesktop?'48px':(isTablet?'32px':'24px'),
                         maxWidth: isDesktop ? '934px' : (isTablet ? '560px' : '300px'),
                         fontWeight: 'bold',
                         overflow: 'visible',
                         whiteSpace: isDesktop ? 'pre-line' : 'normal', wordWrap: 'break-word',
                         letterSpacing: ' 0.0em',
                         lineHeight: 1.3,
                         marginBottom: '40px',
                     }}>
                    {t(`ICT 개발을 위한 H/W 밎 S/W 전문 연구인력으로 100% 자체개발 프레스 컨트롤러를 개발하여 국내 제조산업의 성장을 위해 앞장섭니다.`)}
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    {isDesktop ?
                        <ButtonShadow>
                            <Looper speed={12} direction={'left'}>
                                {renderButtonRow(0, 5)}
                            </Looper>
                            <Looper speed={12} direction={'right'}>
                                {renderButtonRow(5, 12)}
                            </Looper>
                        </ButtonShadow>

                        :
                        <React.Fragment>
                            <ButtonShadow>
                                <Looper speed={7} direction={'left'}>
                                    {renderButtonRow(0, 4)}
                                </Looper>
                                <Looper speed={9} direction={'right'}>
                                    {renderButtonRow(4, 8)}
                                </Looper>
                                <Looper speed={8} direction={'left'}>
                                    {renderButtonRow(8, 12)}
                                </Looper>
                            </ButtonShadow>
                        </React.Fragment>
                    }
                </div>
            </div>

        </MainContainer>
);
};

export default SubProductSection;

const ButtonShadow = styled.div`
   .looper {
    position: relative;
     
    &:before {
      content: "";
      position: absolute;
      top: 0;
        left: -10px;
      z-index: 1;
      width: 30px;
      height: 120px;
      background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -10px;
      z-index: 1;
      width: 30px;
      height: 120px;
      background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    }
     
  
  }
    `

  const ButtonGroup = styled.div`
    display: flex; 
    align-self: center;
    flex-wrap: wrap;
    margin: 6px;

    .button-row{
      display: flex;
      gap: 20px;
      
   }
 

  @media (max-width: 820px) {
    gap: 10px;
   
    
    //@keyframes slide {
    //    0% {
    //        transform: translateX(50%);
    //    }
    //}
    //@keyframes slide-reverse {
    //    0% {
    //        transform: translateX(-50%);
    //    }
    //}
  }
  
    `
