import React, {useEffect, useState} from 'react';
import ReactPageScroller, {SectionContainer} from 'react-page-scroller';
import MainHeroSection from "../containers/main/hero/MainHeroSection";
import SubHeroSection from "../containers/main/hero/SubHeroSection";
import MainProductSection from "../containers/main/product/MainProductSection";
import SubProductSection from "../containers/main/product/SubProductSection";
import MainSolutionSection from "../containers/main/solution/MainSolutionSection";
import MainPRSection from "../containers/main/resource/MainPRSection";
import MainDocsSection from "../containers/main/resource/MainDocsSection";
import MainServiceSection from "../containers/main/solution/MainServiceSection";
import Aos from "aos";
import 'aos/dist/aos.css';
import {useMediaQuery} from "react-responsive";
import Footer from "../components/footer/Footer";
import CSchat from "../components/chat/CSchat";
import styled from "styled-components";
import DownButton from "../components/buttons/DownButton";
import {motion} from "framer-motion";

const MainPageScrollSection = () => {

  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (number: number) => {
    setCurrentPage(number);

  }

    // useEffect(() => {
    //         Aos.init({ duration: 1000
    //         });
    //     },[])

   const isMobile = useMediaQuery({
        query: "(max-width:768px)"
   }
    )

    // const handleScroll = (e: any) => {
    //     console.log(e.target.scrollTop);
    // }



    return (
        // <ReactPageScroller pageOnChange={handlePageChange} containerHeight={isMobile?'100dvh':'100dvh'}
        //                    customPageNumber={currentPage} animationTimer={700}>
        <ScrollSnapContainer>
            <MainHeroSection/>
            {/*<SubHeroSection/>*/}
            <MainProductSection/>
            <SubProductSection/>
            <MainSolutionSection/>
            <MainPRSection/>
            {/*<MainDocsSection/>*/}
            <MainServiceSection/>
            {/*{!footer && <DownButton/>}*/}
        </ScrollSnapContainer>
    );
}


export default MainPageScrollSection;


const ScrollSnapContainer = styled.div`
  //scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
 
    

  //scroll-snap-stop: always;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  & > div {
    scroll-snap-align: start;
  }
`;

