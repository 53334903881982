import React, {useEffect} from 'react';
import ProductSwiperContainer from "../../../components/swipers/ProductSwiperContainer";
import {MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import Aos from "aos";
import SlickTest from "../../../components/slides/SlickTest";
import {useTranslation} from "react-i18next";


const MainProductSection = () => {
    const {t}:any =useTranslation();
    const isDesktop = useMediaQuery({
        query: "(min-width: 821px)",
    });

    // const isMobile = useMediaQuery({
    //     query: "(max-width:416px)"
    // })
    //
    // const isTablet = useMediaQuery({
    //     query: "(max-width: 768px)",
    // });


    useEffect(() => {
            Aos.init({duration: 1000});
        }
        ,[])



    return (
        <MainContainer justifyContent={'center'} height={isDesktop ? '100%' : '100vh'}>
            <div style={{maxWidth: '1920px', alignSelf: 'center', width: '100%',overflow:'hidden'}}>
                <div
                    data-aos={'fade-right'}
                    style={{
                        padding: isDesktop ? '100px 18%' : '40px 10%', marginTop: '20px',
                        lineHeight: '23px', marginBottom: isDesktop ? '40px' : '0px',
                        fontWeight: 'bold', fontSize: 'clamp(18px, 4vw, 24px)', color: '#006DFF'
                    }}>
                    {t('제품 소개')}
                </div>
                {/*<SlickTest />*/}

                    <ProductSwiperContainer categoryName={'전체'}/>
            </div>

        </MainContainer>
);
};

export default MainProductSection;

// const Container = styled.div`
//
//
//       width: 100%;
//     max-width: 1920px;
//     height: 1080px
//
//     `
