import React, {useEffect} from 'react';
import {MainContainer} from "../../../styles/Styles";
import monitor from "../../../asset/images/Monitor.png";
import Buttons from "../../../components/buttons/Buttons";
import {useMediaQuery} from "react-responsive";
import Aos from "aos";
import {useTranslation} from "react-i18next";

const MainSolutionSection = () => {
    const {t}:any =useTranslation();

    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isMobile = useMediaQuery({
        query: "(min-width:280px) and (max-width:1024px)"
    })




    // useEffect(() => {
    //         Aos.init({duration: 1200});
    //     }
    //     ,[])

    return (
        <MainContainer backgroundColor={'#006DFF'}  padding={isDesktop?'200px 16%':'100px 4%'}
                       height={!isDesktop?'100vh':'100%'} alignItems={'center'}
                    justifyContent={'center'} flexDirection={'column'}>
            <div className={'hero-main'}
                style={{display:'flex', width:'100%',maxWidth:'1232px', alignSelf:'center',
                    position:'relative',
                alignItems:'center'}}>
                <div style={{display:'flex',flexDirection:'column',gap:'60px'}}>
                <div className={'text'} style={{width:'350px',fontWeight:'bold',zIndex:'2',
                    fontSize:'clamp(32px, 4vw, 64px)', whiteSpace:'pre-line',
                    color:'#FFFFFF'}}>
                    <div  style={{fontSize:'clamp(16px, 4vw, 24px)',
                        marginBottom:'20px'}}>{t(`설비 및 시스템의 전사적 관리`)}</div>
                    {isMobile ?
                        <div>{t(`실시간 현장 모니터링\nFAS 시스템`)}</div>
                        :
                        <div>{t(`실시간\n현장 모니터링\nFAS 시스템`)}</div>}
                </div>
                    <div style={{zIndex:'2'}}>
                      <Buttons margin={'63px 0'} buttonType={'black-main'}
                               clickEvent={()=>{window.location.href='/solution/fas'}}
                               width={isDesktop?'208px':'168px'} height={isDesktop?'64px':'48px'}
                               fontSize={isDesktop?'24px':'16px'}
                               text={t('보러가기 >')}/>
                    </div>
                </div>
                {/*<div data-aos={'fade-left'} data-aos-offset={'500'}*/}
            <div style={{width:'100%',height:'auto',justifyContent:'center',display:'flex',
                        alignItems:'center', maxWidth:'844px',
                        position:!isMobile?'relative':'absolute', marginLeft:'100px',
                        right:isMobile?'-20%':'unset',top:isMobile?'80%':'unset'
            }}>
                <img src={monitor} alt="product" style={{width:'100%',height:'100%',
                  minWidth: isDesktop?'380px' : 'unset',
                  objectFit:'cover'}}/>
            </div>
            </div>
        </MainContainer>
    );
};

export default MainSolutionSection;
