import React from 'react';
import SectionContainer from "../styles/SectionContainer";
import CustomerServiceScrollSection from "../containers/cs/CustomerServiceScrollSection";
import SolutionScrollSection from "../containers/smartsolution/SolutionScrollSection";
import ProductDetailScrollSection from "../containers/productdetail/ProductDetailScrollSection";
import {solutionData, specArray} from "../common/DataSet";
import ProductScrollSection from "../containers/productinfo/ProductScrollSection";
import {useParams} from "react-router-dom";
import SolutionDetailScrollSection from "../containers/solutiondetail/SolutionDetailScrollSection";

const SmartSolutionPage = () => {

 const { id } = useParams();
    return (
        <SectionContainer children={

                <React.Fragment>
                    {id?
                        <SolutionDetailScrollSection
                            solutionList={solutionData.find(solution => solution.link === `/solution/${id}`)}/>:

                        <SolutionScrollSection/>}







            </React.Fragment>
        }/>
    );
};

export default SmartSolutionPage;
