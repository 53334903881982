import React from 'react';
import SectionContainer from "../styles/SectionContainer";
import AboutUsScrollSection from "../containers/aboutUs/AboutUsScrollSection";
import CustomerServiceScrollSection from "../containers/cs/CustomerServiceScrollSection";

const CustomerServicePage = () => {
    return (
        <SectionContainer
            children={
            <React.Fragment>
                <CustomerServiceScrollSection/>
            </React.Fragment>
        }/>
    );
};

export default CustomerServicePage;