import React from 'react';
import {useMediaQuery} from "react-responsive";
import Aos from "aos";
import {MainContainer, SwiperContainer} from "../../../styles/Styles";
import Buttons from "../../../components/buttons/Buttons";
import {addressData} from "../../../common/DataSet";
import styled from "styled-components";
import address from "../../../asset/images/icons/Group 485.png";
import phone from "../../../asset/images/icons/Union 1.png";
import fax from "../../../asset/images/icons/Group 486.png";
import email from "../../../asset/images/icons/Group 487.png";
import LocationMap from "../../../api/LocationMap";
// @ts-ignore
import {SwiperOptions} from "swiper/types";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";
import {GlobalRef} from "../../../common/Functions";
import {useNavigate} from "react-router-dom";


// interface AddressSectionProps {
//     clickToMenu?: () => void;
// }


const AddressSection= () => {

    const ref = React.useRef(null);

    const [info,setInfo] = React.useState<number>(0);
    const isDesktop = useMediaQuery({
        query: "(min-width: 1440px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 717px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:416px)"
    });

    React.useEffect(() => {
            Aos.init({duration: 1000} );
        }, []
    );

    const infoDetail=(icon:any,text:any)=>{
        return (
             <InfoContainer>
                <div style={{display:'flex',gap:'24px',alignItems:'center'}}>
                    <div className={'icon'}>
                        <img src={icon} alt={''}></img>
                    </div>
                    <div className={'text'}>{text}</div>
                </div>
            </InfoContainer>
        )
    }

    const swiperRef = React.useRef(null);
    const swiperParams: SwiperOptions = {
        modules: [Navigation, Pagination],
        initialSlide: 0,
        // slidesPerView: 5,
        slideClass: 'swiper-slide-buttons',
        slideActiveClass: 'swiper-slide-buttons-active',
        slideToClickedSlide: true,
        breakpoints: {

            1023: {
                slidesPerView: 5,
                spaceBetween:0,
            },
            768: {
                slidesPerView: 5,
                spaceBetween:20,
            },
            717: {
                slidesPerView: 4,
                spaceBetween:5,
            },
            425: {
                slidesPerView: 3,
                spaceBetween:10,
            },
            375: {
                slidesPerView: 3,
                spaceBetween:10,
            },
            320: {
                slidesPerView: 2,
                spaceBetween:0,
            },
            // 280: {
            //     slidesPerView:3,
            //     spaceBetween:40,
            // }
            }
        }


    return (
        <GlobalRef.Provider value={ref}>
            <MainContainer backgroundColor={'#000'} padding={isDesktop ? '200px 16%' : '80px 6%'}
                           justifyContent={'center'} height={'100%'}>
                <div style={{
                    maxWidth: '1232px', alignSelf: 'center',
                    margin: isDesktop ? '0% auto' : '0 6%',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: isDesktop ? '30px' : '20px',
                        width: '100%'
                    }}>
                        <div className={'hero_text'}
                             style={{
                                 fontWeight: 'bold',
                                 fontSize: 'clamp(20px, 3vw, 48px)',
                                 zIndex: '2',
                                 textAlign: 'left',
                                 color: '#fff',
                                 whiteSpace: 'nowrap'
                             }}>
                            {'찾아오시는 길'}
                        </div>
                        {isDesktop ?
                            <div style={{display: 'flex', gap: '20px', width: '100%'}}>
                                {addressData.map((item, index) =>
                                    <Buttons buttonType={'active'} width={item.title.length > 5 ? '200px' : '180px'}
                                             selected={info === index}
                                             fontSize={'clamp(14px, 3vw, 24px)'}
                                             clickEvent={() => {
                                                 setInfo(index)
                                             }}
                                             height={isDesktop ? '64px' : '48px'} text={item.title}/>
                                )}
                            </div>
                            :
                            <SwiperContainer>
                                <Swiper ref={swiperRef} {...swiperParams}
                                        style={{width: '100%', height: '60px'}}>
                                    {addressData.map((item, index) =>
                                        <SwiperSlide className={'swiper-slide-buttons'} key={index}
                                                     style={{width: '120px'}}>
                                            <Buttons buttonType={'active'} width={'120px'}
                                                     selected={info === index}
                                                     fontSize={'clamp(14px,3vw,16px)'}
                                                     clickEvent={() => {
                                                         setInfo(index)
                                                     }}
                                                     height={'48px'} text={item.title}/>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            </SwiperContainer>
                        }
                        <div style={{display: 'flex', flexDirection: 'column', gap: '40px'}}>
                            {addressData.map((item, index) =>
                                info === index &&
                                item.info.map((infoItem, infoIndex) =>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: isDesktop ? 'row' : 'column',
                                        gap: isDesktop ? '40px' : '0px',
                                        alignItems: 'flex-start',
                                        justifyContent: isDesktop ? 'space-between' : 'center'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: isDesktop ? '20px' : '8px',
                                            marginTop: isDesktop ? '20px' : '10px'
                                        }}>
                                            {infoDetail(address, infoItem.address)}
                                            {infoDetail(phone, infoItem.tel)}
                                            {infoDetail(fax, infoItem.fax)}
                                            {infoDetail(email, infoItem.email)}
                                        </div>
                                        <MapComp>
                                            <LocationMap latitude={infoItem.latitude} longitude={infoItem.longitude}/>
                                        </MapComp>
                                    </div>
                                )
                            )
                            }
                        </div>
                    </div>
                </div>

            </MainContainer>
        </GlobalRef.Provider>

);
};

export default AddressSection;


const InfoContainer = styled.div`
display: flex;
gap: 8px;

.icon{
    width:24px;
    height:24px;
}
.text{
    font-size:clamp(12px, 3vw, 24px);
      color: #fff;
      white-space: nowrap;
      
      
      @media only screen and (max-width: 1024px) 
        {font-size:clamp(12px, 3vw, 24px);
         white-space: pre-line;
    }
    `

const MapComp = styled.div`
    width: 608px;
    height: 376px;
    max-width: 100vw;
    max-height: 100%;
    align-self: center;
    display: flex;
    margin-top: 20px;
    min-width:  300px;
    min-height: 300px;
    box-sizing: border-box;
    background: #fff;

  @media only screen and (max-width: 820px) {
     width: 360px;
      height: 320px;
  }
  @media only screen and (max-width: 375px) {
        width: 300px;
        height: 300px;
  }
  
    
    `
