import React, {useEffect, useState} from 'react';
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import styled from "styled-components";
import {philosophyArray} from "../../../common/DataSet";
import Aos from "aos";
import {clamp} from "react-number-format/types/utils";
import { Link, Events, scrollSpy, scroller } from "react-scroll";
import {animateScroll as scroll} from "react-scroll/modules";

const ManagementPhilosophy = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1600px)",
    });

    // const isTablet = useMediaQuery({
    //     query: "(max-width: 1024px)",
    // });

    const isMobile = useMediaQuery({
        query: "(max-width: 1024px)"
    }
    )

    const [activeSection, setActiveSection] = useState(0);


    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const sectionOffsets = philosophyArray.map((item) => {
            const sectionElement = document.getElementById(item.title);
            return sectionElement ? sectionElement.offsetTop : 0;
        });

        const sectionHeights = sectionOffsets.map((offset, index) => {
            if (index === sectionOffsets.length - 1) {
                return Infinity;
            }
            return sectionOffsets[index + 1] - offset;
        });

        const currentSection = sectionOffsets.findIndex((offset, index) => {
            const isSectionActive =
                scrollPosition >= offset - 400 &&
                scrollPosition < offset + sectionHeights[index] - 200;
            return isSectionActive;
        });
        setActiveSection(currentSection);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);




    return (
        <MainContainer backgroundColor={'#000000'} height={isMobile ? '100%' : '1080px'}
                       justifyContent={'center'}
                       padding={isDesktop ? '20px 16%' : '100px 6%'}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center',

                margin: isDesktop ? '0% auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div style={{
                    fontWeight: 'bold', fontSize: 'clamp(18px 3vw 24px)',
                    color: '#006DFF',
                }}>{'경영이념'}</div>
                <div className={'hero_text'}
                     style={{
                         fontWeight: 'normal',
                         marginTop: '24px',
                         fontSize: isMobile ? '16px' : '24px',
                         zIndex: '2',
                         textAlign: 'left',
                         color: '#fff',
                         whiteSpace: isDesktop ? 'pre-line' : 'pre-line'
                     }}>
                    {`도전과 혁신의 정신으로 사람과 기술을 연결하여 함께 성장하는 사회를 만들어갑니다.`}
                </div>

                <div style={{
                    display: 'flex',
                    flexDirection: !isMobile ? 'row' : 'column', gap: isMobile ? '100px' : '20px',
                    marginTop: '60px', alignItems: 'center',
                    justifyContent: 'center', width: '100%',
                    boxSizing: 'border-box'
                }}>


                    {philosophyArray.map((item, index) =>
                        <Link key={index}
                              activeClass="active"
                              to={item.title}
                              spy={true}
                              smooth={true}
                              duration={500}
                              offset={-100}
                              onSetActive={() => setActiveSection(index)}>

                            <IconContainer isActive={!isDesktop ? activeSection === index : false}
                                           id={item.title}>
                                <img src={item.img} className={'img'}
                                     style={{position: 'absolute', right: '-10px', top: '0px', zIndex: '0'}}/>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: isDesktop ? '24px' : '18px',
                                    alignItems: 'baseline'
                                }}>
                                    <div style={{
                                        fontWeight: 700,
                                        fontSize: 'clamp(32px,2vw,42px)',
                                        zIndex: '1',
                                        color: '#fff', whiteSpace: isMobile ? 'nowrap' : 'pre-line'
                                    }}>{item.title}</div>
                                    <div style={{
                                        fontWeight: 600,
                                        fontSize: 'clamp(14px 1vw 20px)', color: '#fff', whiteSpace: 'pre-line',
                                        height: '90px',
                                        overflow: 'hidden', textOverflow: 'ellipsis'
                                    }}>{item.content}</div>
                                </div>
                            </IconContainer>
                        </Link>
                    )}

                </div>
            </div>
        </MainContainer>

);
};


export default ManagementPhilosophy;

const IconContainer = styled.div
    <{ isActive: boolean }>`
display: flex;
flex-direction: column;
justify-content: end;
position: relative;
align-items: center;
    width: 100%;
    height: 520px;
    max-width: 296px;
    padding: 20px;
    border-radius: 16px;
    
    box-shadow: 0px 3px 6px #00000029;
    box-sizing: border-box;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    background: ${props => props.isActive ? 'linear-gradient(136deg, #edf5ff 0%, #006dff 100%);' : '#1E222D'};
    
  
    

    &:hover {
        background: linear-gradient(136deg, #edf5ff 0%, #006dff 100%);
    }
  
  .img{
    width: 200px;
    height: 200px;
    object-fit: contain;
    object-position: center;
    
  }
 

    @media only screen and (max-width: 1600px) {
        //296px 비율 유지 하면서 축소 
        width: calc(296px * 0.8);
        height: calc(520px * 0.7);
       
        .img{
            width: 128px;
            height: 128px;
        }

    }
  
  @media only screen and (max-width:1024px) {
    width: 296px;
    height:256px;
    .img{
      width: 128px;
      height: 128px;
    }
      
  }
 `
