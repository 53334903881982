import React from 'react';
import SectionContainer from "../styles/SectionContainer";
import CustomerServiceScrollSection from "../containers/cs/CustomerServiceScrollSection";
import ProductScrollSection from "../containers/productinfo/ProductScrollSection";
import ProductDetailScrollSection from "../containers/productdetail/ProductDetailScrollSection";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {specArray} from "../common/DataSet";

const ProductPage = () => {
    const {id} = useParams();
    const location = useLocation();




    return (
        <SectionContainer children={
            <React.Fragment>
                {id ?
                    <ProductDetailScrollSection
                        productList={specArray.find(product => product.link === `/product/${id}`)}/>
                    :
                    location.pathname === '/product/controller' ?
                        <ProductScrollSection pageScroll={'controller'}/>
                        :
                        location.pathname === '/product/device' ?
                            <ProductScrollSection pageScroll={'device'}/>
                            :
                        <ProductScrollSection pageScroll={'all'}/>

                }

            </React.Fragment>
        }/>
    );
};

export default ProductPage;
