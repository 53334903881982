import React from 'react';
import SectionContainer from "../styles/SectionContainer";
import AboutUsScrollSection from "../containers/aboutUs/AboutUsScrollSection";
import {useLocation, useNavigate} from "react-router-dom";
import AddressSection from "../containers/aboutUs/address/AddressSection";


const AboutUsPage = () => {
    const location = useLocation();



    return (
        <SectionContainer
            children={
            <React.Fragment>
                {location.pathname === '/about/address' ?
                    <AboutUsScrollSection pageScroll={'address'}/>
                    :
                    <AboutUsScrollSection/>
                }
            </React.Fragment>
        }/>

    );
};

export default AboutUsPage;
