import React from 'react';
import {createHeroMainText, createHeroSubText, MainContainer} from "../../../styles/Styles";
import {useMediaQuery} from "react-responsive";
import bg from "../../../asset/images/bg/AboutMainBG.webp";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutUsMainHeroSection = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 1025px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:430px)"
    });

    React.useEffect(() => {
            Aos.init({duration: 500,
                easing:'ease-in-out',
                offset:0} );
        }, []
    );


    const backgroundStyle = {
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${bg})`,
        // padding: isDesktop ? '0 16%' : '0 6%',
        backgroundSize: 'cover',
        width: '100%',
        height: '100vh',
        backgroundPosition: isDesktop ? 'center' : 'left',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
    };

    return (
        <MainContainer backgroundImage={bg}
                       justifyContent={'center'} alignItems={'center'}
                       height={!isDesktop?'100vh':'1080px'}>
            <div className={'picture'} style={backgroundStyle}>
                {/*<div style={{width:'100%',height:'45%',*/}
                {/*    background:'linear-gradient(180deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0))',*/}
                {/*    position:'fixed',bottom:0}}/>*/}
                <div style={{
                    maxWidth: '1920px', alignSelf: 'center',
                    margin: isDesktop ? '0% auto' : '0 6%',
                    justifyContent: 'center', width: '100%'
                }}>
                    <div className={'hero-main'} style={{padding: isDesktop ? '0 18%' : '0 5%'}}>
                        <div data-aos={'fade-up'}>
                            <div className={'hero_text'}
                                 style={{
                                     display: '-webkit-box',
                                     WebkitLineClamp: 2,
                                     WebkitBoxOrient: 'horizontal',
                                     fontSize: 'clamp(32px, 4.8vw, 64px)',
                                     maxWidth: '100%',
                                     minWidth: '260px',
                                     fontWeight: 'bold',
                                     overflow: 'visible',
                                     whiteSpace: 'pre-line',
                                     letterSpacing: ' 0.0em',
                                 }}>
                                {!isMobile ?
                                    '도전과 혁신으로\n4차산업 혁명의 가치를 실현합니다.'
                                    :
                                    '도전과 혁신으로 4차산업 혁명의 가치를\n실현합니다.'}

                            </div>
                        </div>
                        <div data-aos={'fade-up'}>
                            <div className={'hero-sub'}>
                                <div style={{
                                    marginTop: isDesktop ? '40px' : '20px',
                                    color: '#fff',
                                    fontSize: isDesktop ? '32px' : '16px',
                                    display: '-webkit-box',
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'horizontal',
                                    maxWidth: isDesktop ? '820px' : '312px',
                                    maxHeight: isDesktop ? '122px' : '96px',
                                    overflow: 'visible',
                                    whiteSpace: 'pre-line',
                                    lineHeight: 1.3,
                                    position: 'relative',
                                }}>
                                    {'국내 프레스 제조회사 70% 이상을 차지하며 직접적으로 제어 부분을 담당하고 있으며, 스마트 팩토리 구축을 위한 비즈니스 모델을 지속적으로 개발하여 시대의 흐름을 앞서가기 위해 최선을 다하고 있습니다.'}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </MainContainer>
);
};


export default AboutUsMainHeroSection;
