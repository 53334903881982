import React, {useEffect} from 'react';
import {useMediaQuery} from "react-responsive";
import {MainContainer} from "../../../styles/Styles";
import TimeLineComponent from "../../../components/timeline/TimeLineComponent";
import {historyData} from "../../../common/DataSet";
import Aos from "aos";
import MobileTimeLineComponent from "../../../components/timeline/MobileTimeLineComponent";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentPageScroll, scrollOverState, scrollPageBlock,prevContainerScrollHeight} from "../../../common/RecoilState";

const CompanyHistorySection = () => {

    const containerRef = React.useRef<any>(null)
    const [scrollValue, setScrollValue] = React.useState(0)
    const currentPage = useRecoilValue(currentPageScroll)
    const [, setPageScroll] = useRecoilState(scrollPageBlock)
    const [prevScroll, setPrevScroll] = useRecoilState(prevContainerScrollHeight)

    const isDesktop = useMediaQuery({
        query: "(min-width: 1024px)",
    });



    const text = `주식회사 제스텍 은\n오늘도 성장 하고 있습니다`;



    const colorChange = (text: string) => {
        return text.split(' ').map((word, index) => {
            const isBlueType = ['제스텍','성장'].includes(word);
            const textColor = isBlueType ? '#006DFF' :  '#fff';

            return (
                <span key={index} className={'text'} style={{ color: textColor, fontWeight: '700', whiteSpace:'pre-line',fontSize: 'clamp(24px, 3vw, 56px)'}}>
                    {word}{' '}
                </span>
            );
        });
    }


    return (
        <MainContainer ref={containerRef}
                       onMouseMove={() => setPrevScroll((prev: any) => ({
                           ...prev,
                           history: Math.round(containerRef?.current.scrollHeight)
                       }))}
                       onScroll={() => setScrollValue(Math.round(containerRef.current?.scrollTop))}
            // onMouseEnter={()=>setScrollOver(true)}
                       backgroundColor={'#000'}
                       padding={isDesktop ? '100px 16%' : '100px 6%'}
                       justifyContent={'center'} height={isDesktop ? '100%' : '1300px'} alignItems={'center'}>
            <div style={{
                maxWidth: '1232px', alignSelf: 'center',

                margin: isDesktop ? '0% auto' : '0 6%',
                justifyContent: 'center', width: '100%'
            }}>
                <div style={{display: 'flex', gap: '80px', flexDirection: 'column', height: '100%', width: '100%'}}>
                    <div className={'hero-main'}>
                        {colorChange(text)}
                    </div>
                    {isDesktop ?
                        historyData.map((item, index) =>
                            <React.Fragment key={index}>
                                <TimeLineComponent year={item.year} data={item.content}/>
                            </React.Fragment>
                        )
                        :

                        <MobileTimeLineComponent historyData={historyData}/>

                    }
                </div>
            </div>
        </MainContainer>
);
};

export default CompanyHistorySection;
