import React from 'react';
import styled from "styled-components";
import newsImg from "../../asset/images/ceo.jpg";

interface NewsroomSliderProps {
    title?: string
    sub?: string
    image?: string
    link?: string
}

const NewsroomSlideComp: React.FC<NewsroomSliderProps> = ({title, sub, image, link}) => {
    return (
        <Container onClick={() => window.open(link)}>
            <div className={'thumb-wrapper'}>
                <img src={image} alt="news"/>
            </div>
            <div className={'title'} >{title}</div>
        </Container>

    );
};

export default NewsroomSlideComp;

const Container = styled.div`

    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 400px;
    align-items: baseline;
    transition: all 0.5s ease;

    .thumb-wrapper {
        border-radius: 24px;
        width: 100%;
        max-width: 400px;
        box-sizing: border-box;
        //gap: 10px;
        //height: 256px;
      
        overflow: clip;


        img {
            width: 100%;
            height: 256px;
            object-fit: cover;
           
            transition: all 0.5s ease;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);

            }

            @media only screen and (max-width: 1460px) {
                width: 312px;
                height: 200px;
                border-radius: 24px;
            }
            @media only screen and (max-width: 280px) {
                width: 270px;
                height: 180px;
                border-radius: 24px;

            }
        }

    }

    .title {
        font-size: clamp(18px,1.5vw,24px);
        width: 400px;
        font-weight: 800;
        margin-top: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        transition: all 0.5s ease;

        &:hover {
            cursor: pointer;
            color: #006DFF;

        }
    }

    @media only screen and (max-width: 1460px) {
        width: 312px;

        .thumb-wrapper {
            width: 312px;

        }

    ;
        .title {
            font-size: 18px;
            width: 312px;
        }
    }
    @media only screen and (max-width: 280px) {
        width: 270px;;
        .thumb-wrapper {
            width: 270px;
        }

    ;
        .title {
            font-size: 18px;
            width: 80%;
        }
    }






`