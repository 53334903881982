import React from "react";
import {atom} from "recoil";
import {recoilPersist} from 'recoil-persist'

const {persistAtom} = recoilPersist()

export const scrollPageBlock = atom({
	key:'scroll_page_block',
	default: {up: false, down: false},
})

export const scrollOverState = atom({
	key:'scroll_over_state',
	default: false
})

export const currentPageScroll = atom({
	key:'current_page_scroll',
	default:0
})

export const prevContainerScrollHeight = atom({
	key:'prev_container_scroll_height',
	default: {inNumber: 0, history: 0, certificate: 0}
})

export const LanguageAtom = atom({
	key: 'language_atom',
	default: {lang: 'ko', text: '한국어', index: 1},
	effects_UNSTABLE: [persistAtom]
})
